import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Stack,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { doc, setDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { Question } from '../components/Question';
import OrganizationsDropdown from '../components/OrgDropdown';
import { useSnackbar } from '../components/SnackbarContext';
import useOrgFirestore from '../hooks/useOrgFirestore';
import Tour from 'reactour';
import { auth } from '../firebaseConfig';
import RoleCheck from '../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import restrictedChars from '../local_data/restricted-chars.json';
import * as Sentry from '@sentry/react';

function CreateForm() {
  const [selectedOrg, setSelectedOrg] = useState('');
  const { firestore, location } = useOrgFirestore(selectedOrg);
  const [formName, setFormName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourSeen, setTourSeen] = useState(localStorage.getItem('createFormTourSeen') === 'true');
  const [userRole, setUserRole] = useState('');
  const [orgDataLimits, setOrgDataLimits] = useState({ currentTotalData: 0, maximumTotalData: 0, currentWrites: 0, maximumWrites: 0 });
  const navigate = useNavigate();
  const dataTypes = ['Text', 'Number', 'Date', 'Dropdown', 'Multiple Choice', 'Image'];
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();
  const [isFormNameValid, setIsFormNameValid] = useState(true);
  const [loading, setLoading] = useState(false);
  // max image questions must not be greater than 1
  const maxImageQuestions = (questions.filter((q) => q.dataType === 'image').length > 1);

  useEffect(() => {
    setIsTourOpen(!tourSeen);
  }, [tourSeen]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('createFormTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const steps = [
    {
      selector: '[data-tour="formName"]',
      content: t('Forms.ReactTour.Desc.1'),
    },
    {
      selector: '[data-tour="addQuestion"]',
      content: t('Forms.ReactTour.Desc.2'),
    },
    {
      selector: '[data-tour="createForm"]',
      content: t('Forms.ReactTour.Desc.3'),
    },
    {
      selector: '',
      content: () => (
        <FormControlLabel
          control={<Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />}
          label={t("General.DoNotShow")}
        />
      ),
    },
  ];


  const validateTextField = (text) => {
    const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
    return !restrictedCharsRegex.test(text);
  };

  const fetchOrgDataLimits = async () => {
    if (firestore && selectedOrg) {
      const orgDocRef = doc(firestore, 'organizations', selectedOrg);
      const orgDoc = await getDoc(orgDocRef);
      if (orgDoc.exists()) {
        const { currentTotalData, maximumTotalData, currentWrites, maximumWrites } = orgDoc.data();
        setOrgDataLimits({ currentTotalData, maximumTotalData, currentWrites, maximumWrites });
      }
    }
  };

  useEffect(() => {
    fetchOrgDataLimits();
  }, [firestore, selectedOrg]);

  // Function to add a new question to the form.
  const addQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        text: '',
        dataType: 'text',
        mandatory: false,
        options: [],
        id: `question-${uuidv4()}`,
        isDeleted: false,
      },
    ]);
  };

  // Function to update an existing question.
  const updateQuestion = (index, questionData) => {
    try {
      if (!validateTextField(questionData.text)) {
        showSnackbar(t('Snackbar.RestrictredCharsMessage'), 'error');
        return;
      }

      if (questionData.options && questionData.options.some(option => !validateTextField(option.value))) {
        showSnackbar(t('Snackbar.RestrictredCharsMessage'), 'error');
        return;
      }

      // Max one image type question per form
      const imageQuestionsCount = questions.filter((q, idx) => q.dataType === 'image' && idx !== index).length;
      if (questionData.dataType === 'image' && imageQuestionsCount >= 1) {
        showSnackbar('Only one image question per form.', 'error'); // Inform the user about the limit
      }

      setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        newQuestions[index] = questionData;
        return newQuestions;
      });
    } catch (error) {
      Sentry.captureException(error);
    }

  };


  // Function to remove a question from the form.
  const removeQuestion = (index) => {
    setQuestions(prevQuestions => prevQuestions.filter((_, idx) => idx !== index));
  };

  const handleFormNameChange = (e) => {
    const { value } = e.target;
    if (value.length > 63) {
      showSnackbar(t('Snackbar.FormNameMax'), 'error');
    }
    if (!validateTextField(value)) {
      setIsFormNameValid(false);
      return;
    }
    setIsFormNameValid(true);
    setFormName(value.slice(0, 63));
  };

  const handleSubmit = async () => {
    try {

      if (!selectedOrg || !formName.trim()) {
        showSnackbar(t('Snackbar.SelectOrgFormName'), 'error');
        return;
      }

      if (!validateTextField(formName.trim())) {
        showSnackbar(t('Snackbar.RestrictredCharsMessage'), 'error');
        return;
      }

      if (orgDataLimits.currentTotalData > orgDataLimits.maximumTotalData) {
        showSnackbar(t('General.MaxTotalData'), 'error');
        return;
      }

      if (orgDataLimits.currentWrites > orgDataLimits.maximumWrites) {
        showSnackbar(t('General.MaxMonthly'), 'error');
        return;
      }

      const formsCollection = collection(firestore, `organizations/${selectedOrg}/forms`);
      const formId = uuidv4(); // Generate a UUID for the form ID
      const formIdRef = doc(formsCollection, formId); // Use the UUID as the document ID
      const formNameQuery = query(formsCollection, where("formName", "==", formName.trim())); // Query to check by formName attribute

      const formNameDocSnap = await getDocs(formNameQuery);

      // Check if any document matches the formName attribute
      if (!formNameDocSnap.empty) {
        showSnackbar(t('Snackbar.FormNameExists'), 'error');
        return;
      }

      // Modify questions to prepend 'aud_' to each question text
      const modifiedQuestions = questions.map(question => ({
        ...question,
        text: 'aud_' + question.text
      }));

      if(questions.length === 0){
        showSnackbar(t('Forms.Snackbar.NoQuestionError'), 'error');
        return;
      }

      // If no existing document with the same name, proceed to create a new form
      const newFormData = {
        formName: formName.trim(),
        questions: modifiedQuestions,
        lastUpdated: new Date() ,
        numberOfSubmissions: 0,
        deleted: false
      };

      // Saving the new form data to the Firestore database using the generated UUID as document ID
      await setDoc(formIdRef, newFormData);
      showSnackbar(t('Snackbar.FormSaved'), 'success');
      navigate('/home');
    } catch (error) {
      showSnackbar(t('Snackbar.PleaseTryAgainLater'), 'error');
      Sentry.captureException(error);
    }
  };

  // Function to move a question up
  const moveQuestionUp = (index) => {
    if (index === 0) return;
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      [newQuestions[index - 1], newQuestions[index]] = [newQuestions[index], newQuestions[index - 1]];
      return newQuestions;
    });
  };

  // Function to move a question down
  const moveQuestionDown = (index) => {
    if (index === questions.length - 1) return;
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      [newQuestions[index + 1], newQuestions[index]] = [newQuestions[index], newQuestions[index + 1]];
      return newQuestions;
    });
  };

  const hasEmptyQuestionNames = () => {
    return questions.some(q => q.text.trim() === '');
  };

  return (
    <Container component="main" maxWidth="md" sx={{marginTop: 3}}>
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={selectedOrg}
        rolesAllowed={['Admin', 'Owner']}
        redirectPage='/home'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Card sx={{ boxShadow: 5 }}>
        <CardContent>
          <Typography variant="h4" style={{ marginBottom: '12px' }}>
            {t('Forms.CreateTitle')}
          </Typography>
          <OrganizationsDropdown
              selectedOrg={selectedOrg}
              setSelectedOrg={setSelectedOrg}
              setLoading={setLoading}      // Pass setLoading to OrganizationsDropdown
          />
          <TextField
            fullWidth
            margin="normal"
            data-tour="formName"
            label={t('Forms.Name')}
            value={formName}
            onChange={handleFormNameChange}
            error={!isFormNameValid}
            helperText={!isFormNameValid ? t('Snackbar.RestrictredCharsMessage') : ''}
            inputProps={{ maxLength: 63 }}
            sx={{ mb: 5 }}
          />
          {questions.map((question, index) => (
            <Question
              key={question.id}
              index={index}
              data={question}
              dataTypes={dataTypes}
              updateQuestion={updateQuestion}
              moveQuestionUp={() => moveQuestionUp(index)}
              moveQuestionDown={() => moveQuestionDown(index)}
              removeQuestion={() => removeQuestion(index)}
            />
          ))}
          <Stack spacing={2} direction="column" sx={{ mt: 2, alignItems: 'center' }}>
            <Button
              variant="outlined"
              data-tour="addQuestion"
              color="primary"
              onClick={addQuestion}
              sx={{ width: '50%', display: 'block', mx: 'auto' }}
            >
              {t('Forms.AddQuestion')}
            </Button>
            <Tooltip title={orgDataLimits.currentTotalData >= orgDataLimits.maximumTotalData ? t('General.MaxTotalData') : ''}>
              <span>
                <Button
                  variant="contained"
                  data-tour="createForm"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    !selectedOrg ||
                    !formName.trim() ||
                    hasEmptyQuestionNames() ||
                    orgDataLimits.currentTotalData >= orgDataLimits.maximumTotalData ||
                    orgDataLimits.currentWrites >= orgDataLimits.maximumWrites ||
                    maxImageQuestions
                  }
                  sx={{ width: '100%', display: 'block', mx: 'auto' }}
                >
                  {t('Forms.Create')}
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </Container>
  );
}

export default CreateForm;
