import React from 'react';
import { Container, Typography, Card, CardMedia, CardContent, List, ListItem, Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import createYourPlot from '../images/createYourPlot.png';
import plotDataEntryMobile1 from '../images/plotDataEntryMobile1.png';
import plotDataEntryMobile2 from '../images/plotDataEntryMobile2.png';
import viewPlotHeatmap from '../images/viewPlotHeatmap.png';
import viewPlotHeatmapImage from '../images/viewPlotHeatMapImage.png'
import viewPlotDashboard from '../images/viewPlotDashboard.png';
import createForm from '../images/createForm.png';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery, styled } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const CustomCarousel = styled(Carousel)`
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #283106; /* Makes the next arrow green */
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #283106; /* Makes the previous arrow green */
  }

  .carousel .control-dots {
    bottom: 10px;
  }

  .carousel .control-dots .dot {
    background-color: #283106;
  }

  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }

  .carousel .slide img {
    max-height: 50vh;
    object-fit: contain;
  }
`;

function HomeShowcase() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showcaseRef, showcaseInView] = useInView({ triggerOnce: true, threshold: 0.2 });

  const showcaseSpring = useSpring({
    opacity: showcaseInView ? 1 : 0,
    transform: showcaseInView ? 'translateY(0px)' : 'translateY(50px)',
    config: { duration: 1000 }
  });

  const features = [
    {
      title: 'Home.Showcase.CreatePlot.Title',
      imageUrl: [createYourPlot],
      details: [
        'Home.Showcase.CreatePlot.Desc.0',
        'Home.Showcase.CreatePlot.Desc.1',
        'Home.Showcase.CreatePlot.Desc.2',
        'Home.Showcase.CreatePlot.Desc.3'
      ]
    },
    {
      title: 'Home.Showcase.PlotDataEntry.Title',
      imageUrl: [plotDataEntryMobile1, plotDataEntryMobile2],
      details: [
        'Home.Showcase.PlotDataEntry.Desc.0',
        'Home.Showcase.PlotDataEntry.Desc.1',
        'Home.Showcase.PlotDataEntry.Desc.2',
        'Home.Showcase.PlotDataEntry.Desc.3'
      ]
    },
    {
      title: 'Home.Showcase.ViewPlotHeatMap.Title',
      imageUrl: [viewPlotHeatmap],
      details: [
        'Home.Showcase.ViewPlotHeatMap.Desc.0',
        'Home.Showcase.ViewPlotHeatMap.Desc.1',
        'Home.Showcase.ViewPlotHeatMap.Desc.2',
        'Home.Showcase.ViewPlotHeatMap.Desc.3',
      ]
    },
    {
      title: 'Home.Showcase.ViewPlotHeatMapImage.Title',
      imageUrl: [viewPlotHeatmapImage],
      details: [
        'Home.Showcase.ViewPlotHeatMapImage.Desc.0',
        'Home.Showcase.ViewPlotHeatMapImage.Desc.1',
        'Home.Showcase.ViewPlotHeatMapImage.Desc.2',
        'Home.Showcase.ViewPlotHeatMapImage.Desc.3'
      ]
    },
    {
      title: 'Home.Showcase.ViewPlotDashboard.Title',
      imageUrl: [viewPlotDashboard],
      details: [
        'Home.Showcase.ViewPlotDashboard.Desc.0',
        'Home.Showcase.ViewPlotDashboard.Desc.1',
        'Home.Showcase.ViewPlotDashboard.Desc.2',
        'Home.Showcase.ViewPlotDashboard.Desc.3'
      ]
    },
    {
      title: 'Home.Showcase.CreateForm.Title',
      imageUrl: [createForm],
      details: [
        'Home.Showcase.CreateForm.Desc.0',
        'Home.Showcase.CreateForm.Desc.1',
        'Home.Showcase.CreateForm.Desc.2',
        'Home.Showcase.CreateForm.Desc.3'
      ]
    },
  ];

  return (
    <Container disableGutters={true} ref={showcaseRef} maxWidth={false} sx={{ minHeight: '70vh', marginTop: '10px' }}>
      <animated.div style={showcaseSpring}>
        <Typography variant="h3" align="center" gutterBottom sx={{ color: '#283106' }}>
          {t('Home.Showcase.MainTitle')}
        </Typography>
        <CustomCarousel showThumbs={false} useKeyboardArrows={true} showArrows={true} infiniteLoop={true}>
          {features.map((feature, index) => (  // Correctly use feature instead of features
            <Grid container key={index} sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start' }}>
              <Card sx={{ color: '#98d1a8', height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
                <CardContent sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
                  <Grid item xs={12} md={6} sx={{ paddingLeft: 3, display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'flex-start', justifyContent: 'flex-start' }}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#283106', textAlign: 'center', paddingTop: '24px' }}>
                      {t(feature.title)}
                    </Typography>
                    <List sx={{ textAlign: 'left', paddingLeft: 0 }}>
                      {feature.details.map((detail, detailIndex) => (
                        <ListItem key={detailIndex}>
                          <Typography variant="h6" component="div" sx={{ color: '#283106' }}>
                            • {t(detail)}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {feature.imageUrl.length > 1 ? (  // Use feature.imageUrl.length for multiple images
                      <Grid container spacing={1} sx={{padding: 2}}>
                        {feature.imageUrl.map((imageUrl, imgIndex) => (
                          <Grid item xs={6} key={imgIndex}>  {/* Adjust the grid size */}
                            <CardMedia
                              component="img"
                              alt={t(feature.title)}
                              image={imageUrl}
                              sx={{ objectFit: 'contain', width: '100%' }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <CardMedia
                        component="img"
                        alt={t(feature.title)}
                        image={feature.imageUrl[0]}  // Use the single image
                        sx={{ objectFit: 'contain', width: '100%' }}
                      />
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </CustomCarousel>
      </animated.div>
    </Container>
  );
}

export default HomeShowcase;
