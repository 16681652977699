import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, GlobalStyles } from '@mui/material';
import { SnackbarProvider } from './components/SnackbarContext';
import theme from './Theme';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const globalStyles = (
  <GlobalStyles
    styles={{
      '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
      'html, body': { width: '100%', height: '100%', margin: 0, padding: 0 },
      body: { fontFamily: '"Trebuchet MS", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif' },
      '#root': { width: '100%', height: '100%' },
    }}
  />
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={theme}>
    {globalStyles}
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </LocalizationProvider>
    </I18nextProvider>
  </ThemeProvider>
);
