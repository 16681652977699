import React, { useState, useRef, useEffect } from 'react';
import {
  TextField, Box, Button, Paper, Tooltip,
} from '@mui/material';
import { FixedSizeList as List, FixedSizeGrid as Grid } from 'react-window';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../components/SnackbarContext';
import { styled } from '@mui/system';

const StyledGrid = styled(Grid)({
  overflow: 'auto', // Enables scrolling
  scrollbarWidth: 'thin', // Thin scrollbar for Firefox
  '&::-webkit-scrollbar': {
    width: '4px', // Thinner scrollbar width
    height: '4px', // Thinner scrollbar height for horizontal scroll
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent', // Transparent scrollbar track
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888', // Scrollbar thumb color
    borderRadius: '10px', // Rounded scrollbar thumb
    border: '1px solid transparent', // Thinner border for overlay effect
    backgroundClip: 'padding-box', // Ensures scrollbar thumb color is visible
  },
});

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const ColumnLabel = ({ index, style, data }) => {
  const { labels, onChange, zoom, additionalColumn } = data;
  let labelIndex = index;
  
  if (additionalColumn && index >= additionalColumn) {
    labelIndex += 1;
  }

  return (
    <TextField
      style={{ ...style, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      value={labels[index] || ''}
      onChange={(e) => onChange(e, labelIndex)} 
      size="small"
      sx={{
        '& .MuiInputBase-input': {
          fontSize: `${zoom * 0.7}rem`,
        },
        '& .MuiOutlinedInput-root': {
          height: `${zoom * 36}px`,
        },
        '& .MuiInputLabel-root': {
          fontSize: `${zoom * 0.7}rem`,
        },
      }}
      variant="outlined"
    />
  );
};

const Cell = ({ columnIndex, rowIndex, style, data }) => {
  const { clickedCells, setClickedCells, walkwayPosition, zoom } = data;

  const cellKey = `${rowIndex}-${columnIndex}`;
  const isClicked = clickedCells[cellKey]; // Check if this cell has been clicked

  const handleClick = () => {
    // Update clickedCells in the parent (CreatePlot)
    setClickedCells(prev => ({
      ...prev,
      [cellKey]: !isClicked // Toggle clicked state
    }));
  };

  if (columnIndex === walkwayPosition - 1 && walkwayPosition > 0) {
    return (
      <Box style={style} sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: zoom * 35, height: zoom * 38 }}>
        <KeyboardDoubleArrowUpIcon />
      </Box>
    );
  }

  return (
    <Paper
      onClick={handleClick}
      style={style}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isClicked ? 'red' : 'white',
        border: '1px solid black',
        width: zoom * 35,
        height: zoom * 38,
        cursor: 'pointer',
      }}
    />
  );
};

const RowLabel = ({ index, style, data }) => {
  const { labels, onChange, zoom } = data;

  return (
    <TextField
      style={{ ...style, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      value={labels[index] || ''}
      onChange={(e) => onChange(e, index)}
      size="small"
      sx={{
        '& .MuiInputBase-input': {
          fontSize: `${zoom * 0.7}rem`,
        },
        '& .MuiOutlinedInput-root': {
          height: `${zoom * 36}px`,
        },
        '& .MuiInputLabel-root': {
          fontSize: `${zoom * 0.7}rem`,
        },
      }}
      variant="outlined"
    />
  );
};

function RenderCreatePlot({
  rows,
  columns,
  walkwayPosition,
  leftSideLabels,
  rightSideLabels,
  columnLabels,
  onLeftLabelChange,
  onRightLabelChange,
  onColumnLabelChange,
  setLeftSideLabels,
  setRightSideLabels,
  setColumnLabels,
  clickedCells,
  setClickedCells,
}) {
  const gridRef = useRef();
  const leftListRef = useRef();
  const rightListRef = useRef();
  const columnListRef = useRef();
  const [zoom, setZoom] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);
  const columnCount = walkwayPosition > 0 ? columns + 1 : columns;
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const initializeLabels = (labels, setLabels, count) => {
      const initialLabels = {};
      for (let i = 0; i < count; i++) {
        initialLabels[i] = labels[i] || '';
      }
      setLabels(initialLabels);
    };
  
    initializeLabels(leftSideLabels, setLeftSideLabels, rows);
    initializeLabels(rightSideLabels, setRightSideLabels, rows);
    initializeLabels(columnLabels, setColumnLabels, columnCount);
  }, [rows, columnCount, leftSideLabels, rightSideLabels, columnLabels, setLeftSideLabels, setRightSideLabels, setColumnLabels]);
  

  const autoFillLabels = (labels, setLabels) => {
    const labelIndexes = Object.keys(labels).map(Number).sort((a, b) => a - b);
    if (labelIndexes.length < 2) return;

    const startLabelIndex = labelIndexes[0];
    const endLabelIndex = labelIndexes[labelIndexes.length - 1];
    if (isNaN(labels[startLabelIndex]) || isNaN(labels[endLabelIndex])) {
      showSnackbar(t('Plots.Snackbar.AutoFillError'), 'error');
      return;
    }
    const startLabelValue = parseFloat(labels[startLabelIndex]);
    const endLabelValue = parseFloat(labels[endLabelIndex]);
    if (isNaN(startLabelValue) || isNaN(endLabelValue)) {
      showSnackbar(t('Plots.Snackbar.AutoFillError'), 'error');
      return;
    }

    const step = (endLabelValue - startLabelValue) / (endLabelIndex - startLabelIndex);

    let newLabels = { ...labels };
    for (let i = startLabelIndex + 1; i < endLabelIndex; i++) {
      newLabels[i] = (startLabelValue + step * (i - startLabelIndex)).toFixed(0).toString();
    }

    setLabels(newLabels);
  };

  const handleScrollSync = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
  /*
    if (!scrollUpdateWasRequested) {
      setIsScrolling(true);
      if (scrollDirection === 'horizontal') {

        if (columnListRef.current){
          columnListRef.current.scrollTo(scrollOffset);
        }
      } else if (scrollDirection === 'vertical') {

        if (leftListRef.current) {
          leftListRef.current.scrollTo(scrollOffset);
        }
        if (rightListRef.current) {
          rightListRef.current.scrollTo(scrollOffset);
        }
      }
      setIsScrolling(false);
    }
    */

  };

  const onScroll = ({ scrollLeft, scrollTop, scrollUpdateWasRequested }) => {
    if (!isScrolling) {
      setIsScrolling(true);

      if (leftListRef.current) {
        leftListRef.current.scrollTo(scrollTop);
      }
      if (rightListRef.current) {
        rightListRef.current.scrollTo(scrollTop);
      }
      if (columnListRef.current) {
        columnListRef.current.scrollTo(scrollLeft);
      }
      setIsScrolling(false);
    }
  };

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const cellWidth = 55;
  const cellHeight = 38;

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tooltip title={t('Plots.AutoFillDesc')}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
            onClick={() => autoFillLabels(leftSideLabels, setLeftSideLabels)}
          >
            {t('Plots.AutoFillLeft')}
          </Button>
        </Tooltip>

        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Button onClick={() => handleZoomChange(0.5)}>50%</Button>
          <Button onClick={() => handleZoomChange(1)}>100%</Button>
          <Button onClick={() => handleZoomChange(1.5)}>150%</Button>
        </Box>

        <Tooltip title={t('Plots.AutoFillDesc')}>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={() => autoFillLabels(rightSideLabels, setRightSideLabels)}
          >
            {t('Plots.AutoFillRight')}
          </Button>
        </Tooltip>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        <List
          height={zoom * cellHeight}
          width={width*0.68}
          itemCount={columnCount}
          itemSize={zoom * cellWidth}
          itemData={{
            labels: columnLabels,
            onChange: onColumnLabelChange,
            zoom,
          }}
          onScroll={({ scrollOffset }) => handleScrollSync(scrollOffset)}
          style={{ marginLeft: '65px', overflow: 'hidden', paddingRight: '20px' }}
          layout="horizontal"
          ref={columnListRef}
        >
          {ColumnLabel}
        </List>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <List
            height={height*0.75}
            width={59}
            itemCount={rows}
            itemSize={zoom * cellHeight}
            ref={leftListRef}
            itemData={{
              labels: leftSideLabels,
              onChange: onLeftLabelChange,
              zoom,
            }}
            onScroll={({ scrollOffset }) => handleScrollSync(scrollOffset)}
            style={{ marginRight: '5px', overflow: 'hidden', paddingBottom: '20px'  }}
          >
            {RowLabel}
          </List>
          <Box
              sx={{
                width: width * 0.685,
                height: height * 0.76,
                overflow: 'hidden', // Hide scrollbars on the outer container
                border: '2px solid #3C3C3C',
                position: 'relative',
              }}
          >
            <StyledGrid
                columnCount={columnCount}
                columnWidth={zoom * cellWidth}
                height={height * 0.75}
                rowCount={rows}
                rowHeight={zoom * cellHeight}
                width={width * 0.68}
                itemData={{ columnCount, walkwayPosition, zoom, clickedCells, setClickedCells }}
                ref={gridRef}
                onScroll={onScroll}
                style={{ paddingRight: '0' }}
            >
              {Cell}
            </StyledGrid>
          </Box>
          <List
            height={height*0.75}
            width={59}
            itemCount={rows}
            itemSize={zoom * cellHeight}
            ref={rightListRef}
            itemData={{
              labels: rightSideLabels,
              onChange: onRightLabelChange,
              zoom,
            }}
            onScroll={({ scrollOffset }) => handleScrollSync(scrollOffset)}
            style={{ marginLeft: '5px', overflow: 'hidden', paddingBottom: '20px'  }}
          >
            {RowLabel}
          </List>
        </Box>
      </Box>
    </Box>
  );
}

export default RenderCreatePlot;
