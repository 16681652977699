import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { BarChart, PieChart, LineChart } from '@mui/x-charts';
import {
  TextField,
  MenuItem,
  Grid,
  Paper, Button, Typography, List, ListItem, ListItemIcon, ListItemText, Box, Container,
} from '@mui/material';
import { getFirestoreLocation } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../components/SnackbarContext';
import * as Sentry from '@sentry/react'

function PestDiseaseDashBoard({ orgId, plotId, formId, location }) {
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [pestChartData, setPestChartData] = useState({ series: [], xAxis: [], pieData: [] });
  const [diseaseChartData, setDiseaseChartData] = useState({ series: [], xAxis: [], pieData: [] });
  const [availableYears, setAvailableYears] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedWeek, setSelectedWeek] = useState('');
  const [currentDashboard, setCurrentDashboard] = useState('Pest');
  const isPestDashboard = currentDashboard === 'Pest';
  const chartData = isPestDashboard ? pestChartData : diseaseChartData;
  const noDataMessage = isPestDashboard ? "No pest data available for the selected filters." : "No disease data available for the selected filters.";
  const [selectedPestOrDisease, setSelectedPestOrDisease] = useState('');
  const [lineChartData, setLineChartData] = useState([]);
  const [weeksSubmissions, setWeeksSubmissions] = useState([]);
  const [pestOptions, setPestOptions] = useState([]);
  const [diseaseOptions, setDiseaseOptions] = useState([]);
  const [pestColors, setPestColors] = useState({ default: '#CCCCCC' });
  const [diseaseColors, setDiseaseColors] = useState({ default: '#CCCCCC' });
  const [formData, setFormData] = useState(null);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  
  // Fetch Form Data
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const formDocRef = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
        const formDocSnapshot = await getDoc(formDocRef);

        if (formDocSnapshot.exists()) {
          const data = formDocSnapshot.data();
          setFormData(data);
          setPestColors(data.pestColours || { default: '#CCCCCC' });
          setDiseaseColors(data.diseaseColours || { default: '#CCCCCC' });
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
        }
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
      }
    };
    fetchFormData();
  }, [firestore, orgId, plotId, formId]);
  
  // Get the available years and week for the dropdowns
  useEffect(() => {
    try {
      if (formData && formData.submissionHistory) {
        const submissionHistory = formData.submissionHistory;
        const years = Object.keys(submissionHistory).sort((a, b) => b - a);
        setAvailableYears(years);
        if (years.length > 0) {
          const initialYear = years[0];
          setSelectedYear(initialYear);
          const weeks = Object.keys(submissionHistory[initialYear] || {}).sort((a, b) => parseInt(a) - parseInt(b));
          setAvailableWeeks(weeks);
          if (weeks.length > 0) {
            const initialWeek = weeks[0];
            setSelectedWeek(initialWeek);
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [formData]);

  // if no data or year and weeks go null
  useEffect(() => {
    try {
      if (formData) {
        fetchPestDiseaseOptions(selectedYear, formData, currentDashboard).then((options) => {
          if (options.length > 0) {
            setSelectedPestOrDisease(options[0].id);
          } else {
            setSelectedPestOrDisease(''); // Ensure there's a fallback if no options are found
          }
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [formData, currentDashboard, selectedYear]);  

  useEffect(() => {
    if (formData) {
      fetchBarPieChartSubmissions(selectedYear, selectedWeek, formData);
    }
  }, [selectedYear, selectedWeek, formData, currentDashboard]);

  useEffect(() => {
    try {
      if (formData && selectedPestOrDisease && selectedYear !== 'all') {
        fetchLineChartSubmissions(formData);
      } else {
        setLineChartData([]);
        setWeeksSubmissions([]);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [formData, selectedPestOrDisease, currentDashboard, selectedYear]);

  const handleYearChange = async (event) => {
    try {
      const newYear = event.target.value;
      setSelectedYear(newYear);
      setSelectedWeek('all');
      const weeks = Object.keys(formData.submissionHistory[newYear] || {}).sort((a, b) => parseInt(a) - parseInt(b));
      setAvailableWeeks(weeks);
      if (weeks.length > 0) {
        setSelectedWeek(weeks[0]);
      }
      await fetchPestDiseaseOptions(newYear, formData, currentDashboard).then((options) => {
        if (options.length > 0) {
          setSelectedPestOrDisease(options[0].id);
        } else {
          setSelectedPestOrDisease('');
        }
      });
      await fetchBarPieChartSubmissions(newYear, 'all', formData);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleWeekChange = (event) => {
    try {
      const newWeek = event.target.value;
      setSelectedWeek(newWeek);
      fetchBarPieChartSubmissions(selectedYear, newWeek, formData);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const toggleDashboard = () => {
    try {
      if (!formData) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        return;
      }
      setCurrentDashboard(current => {
        const newDashboard = current === 'Pest' ? 'Disease' : 'Pest';
        const options = newDashboard === 'Pest' ? pestOptions : diseaseOptions;
        if (newDashboard === 'Pest') {
          setPestColors(formData.pestColours || { default: '#CCCCCC' });
        } else {
          setDiseaseColors(formData.diseaseColours || { default: '#CCCCCC' });
        }
        setSelectedPestOrDisease(options.length > 0 ? options[0].id : '');
        return newDashboard;
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  async function fetchPestDiseaseOptions(year, formData, type) {
    try {
      const submissionHistory = formData.submissionHistory || {};
      const yearData = year !== 'all' ? submissionHistory[year] || {} : submissionHistory;
      let counts = {};

      Object.values(yearData).forEach(weekData => {
        let data = type === 'Pest' ? weekData.pestCounters : weekData.diseaseCounters;
        Object.keys(data || {}).forEach((type) => {
          counts[type] = true;
        });
      });

      const getLabelAndColorById = (questionId, id) => {
        const question = formData.questions.find(q => q.id === questionId);
        if (!question) return { text: id, color: '#CCCCCC' };
        const option = question.options.find(o => o.id === id);
        return option ? { text: option.text, color: option.color } : { text: id, color: '#CCCCCC' };
      };

      const options = Object.keys(counts).map(id => {
        const { text, color } = getLabelAndColorById(type === 'Pest' ? 'question-pest-type' : 'question-disease-type', id);
        return { id, name: text, color };
      }).sort((a, b) => a.name.localeCompare(b.name));

      if (type === 'Pest') {
        setPestOptions(options);
        return options;
      } else {
        setDiseaseOptions(options);
        return options;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  
  async function fetchBarPieChartSubmissions(year, week, formData) {
    try {
      if (!formData || !formData.submissionHistory) {
        showSnackbar(t('Plots.Snackbar.NoData'), 'warning');
        return;
      }
      const submissionHistory = formData.submissionHistory;
      const years = Object.keys(submissionHistory);

      let weeks = year !== 'all' ? Object.keys(submissionHistory[year] || {}) : [];
      setAvailableYears(years.sort((a, b) => b - a));
      setAvailableWeeks(weeks.sort((a, b) => parseInt(a) - parseInt(b)));

      if (years.length > 0 && year === 'all') {
        setSelectedYear(years[0]);
        weeks = Object.keys(submissionHistory[years[0]] || {});
        setAvailableWeeks(weeks);
        if (weeks.length > 0 && week === 'all') {
          setSelectedWeek(weeks[0]);
        }
      }

      let pestCounts = {};
      let diseaseCounts = {};
      aggregateCountsBasedOnFilters(year, week, submissionHistory, pestCounts, diseaseCounts);
      processChartData(pestCounts, diseaseCounts, formData);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function fetchLineChartSubmissions(formData) {
    try {
      if (!selectedPestOrDisease || selectedYear === 'all') return;

      const submissionHistory = formData.submissionHistory;
      const selectedYearData = submissionHistory[selectedYear] || {};

      let weeksSubmissionsData = {};
      let weeksArray = Object.keys(selectedYearData).sort((a, b) => parseInt(a) - parseInt(b));

      weeksArray.forEach((week) => {
        const weekData = selectedYearData[week];
        const pestOrDiseaseData = isPestDashboard ? weekData.pestCounters : weekData.diseaseCounters;
        if (pestOrDiseaseData && pestOrDiseaseData[selectedPestOrDisease]) {
          weeksSubmissionsData[week] = {
            Low: pestOrDiseaseData[selectedPestOrDisease]['Low'] || 0,
            Medium: pestOrDiseaseData[selectedPestOrDisease]['Medium'] || 0,
            High: pestOrDiseaseData[selectedPestOrDisease]['High'] || 0,
          };
        } else {
          weeksSubmissionsData[week] = { Low: 0, Medium: 0, High: 0 };
        }
      });

      // Process the counts for the line chart
      const levels = ['Low', 'Medium', 'High'];
      let lineChartData = levels.map(level => ({
        id: level,
        label: level,
        data: weeksArray.map(week => weeksSubmissionsData[week][level]),
        stack: 'total',
        area: true,
      }));

      setLineChartData(lineChartData);
      setWeeksSubmissions(weeksArray);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  function aggregateCountsBasedOnFilters(year, week, submissionHistory, pestCounts, diseaseCounts) {
    try {
      Object.entries(submissionHistory).forEach(([yr, weeksData]) => {
        if (year === 'all' || year === yr) {
          Object.entries(weeksData).forEach(([wk, weekData]) => {
            if (week === 'all' || week === wk) {
              if (weekData.pestCounters) {
                Object.entries(weekData.pestCounters).forEach(([type, counts]) => {
                  if (!pestCounts[type]) pestCounts[type] = { Low: 0, Medium: 0, High: 0 };
                  Object.entries(counts).forEach(([level, count]) => {
                    pestCounts[type][level] += count;
                  });
                });
              }
              if (weekData.diseaseCounters) {
                Object.entries(weekData.diseaseCounters).forEach(([type, counts]) => {
                  if (!diseaseCounts[type]) diseaseCounts[type] = { Low: 0, Medium: 0, High: 0 };
                  Object.entries(counts).forEach(([level, count]) => {
                    diseaseCounts[type][level] += count;
                  });
                });
              }
            }
          });
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  function processChartData(pestCounts, diseaseCounts, formData) {
    try {
      const counts = isPestDashboard ? pestCounts : diseaseCounts;
      const itemTypes = Object.keys(counts);

      if (itemTypes.length === 0) {
        const noData = { series: [], xAxis: [], pieData: [] };
        if (isPestDashboard) {
          setPestChartData(noData);
        } else {
          setDiseaseChartData(noData);
        }
        return;
      }

      let series = ['Low', 'Medium', 'High'].map(level => ({
        label: level,
        data: itemTypes.map(itemType => counts[itemType][level] || 0),
      }));

      const getLabelAndColorById = (questionId, id) => {
        const question = formData.questions.find(q => q.id === questionId);
        const option = question.options.find(o => o.id === id);
        return option ? { text: option.text, color: option.color } : { text: id, color: '#CCCCCC' };
      };

      const innerPieData = itemTypes.map(itemType => {
        const { text, color } = getLabelAndColorById(isPestDashboard ? 'question-pest-type' : 'question-disease-type', itemType);
        return {
          label: text,
          value: ['Low', 'Medium', 'High'].reduce((total, level) => total + (counts[itemType][level] || 0), 0),
          color: color || '#CCCCCC',
        };
      });

      const outerPieData = itemTypes.flatMap(itemType =>
          ['Low', 'Medium', 'High'].map(level => {
            const { text, color } = getLabelAndColorById(isPestDashboard ? 'question-pest-type' : 'question-disease-type', itemType);
            const intensity = level === 'High' ? 1 : level === 'Medium' ? 0.7 : 0.4;
            const adjustedColor = adjustColorOpacity(color, intensity);
            return {
              label: `${text} ${level}`,
              value: counts[itemType][level] || 0,
              color: adjustedColor,
            };
          })
      );

      const xAxisData = [{ data: itemTypes.map(itemType => getLabelAndColorById(isPestDashboard ? 'question-pest-type' : 'question-disease-type', itemType).text), scaleType: 'band' }];
      const chartData = {
        series,
        xAxis: xAxisData,
        pieData: [innerPieData, outerPieData],
      };

      if (isPestDashboard) {
        setPestChartData(chartData);
      } else {
        setDiseaseChartData(chartData);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  const adjustColorOpacity = (color, intensity) => {
    // Convert hex color to RGB
    try {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const opacity = (intensity / 1.2);

      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const CustomPieChartLegend = ({ data }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      <Typography variant="subtitle1">{t('Plots.Legend')}</Typography>
      <List dense>
        {data.filter(entry => entry.value > 0).map((entry, index) => (  // Filter out entries with zero values
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
              <Box sx={{ width: 14, height: 14, backgroundColor: entry.color, borderRadius: '50%' }} />
            </ListItemIcon>
            <ListItemText primary={entry.label} sx={{ margin: 0 }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const isDataAvailable = availableYears.length > 0 && availableWeeks.length > 0 && formData;

  return (
    <Container maxWidth={false} disableGutters={true} sx={{padding:'0', margin: '0', overflow: 'auto'}}>
      <Button onClick={toggleDashboard} variant="contained" style={{ marginBottom: 20 }}>
        {t('Plots.SwitchTo')}
        {isPestDashboard ? ' Disease ' : ' Pest '}
        {t('Plots.Dashboard')}
      </Button>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <TextField
            select
            label={t('Plots.Year')}
            value={availableYears.includes(selectedYear) ? selectedYear : ''}
            onChange={handleYearChange}
            variant="outlined"
            style={{ marginRight: 8 }}
            disabled={!isDataAvailable}
          >
            <MenuItem value="all">{t('Plots.AllYears')}</MenuItem>
            {availableYears.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            select
            label={t('Plots.Week')}
            value={availableWeeks.includes(selectedWeek) ? selectedWeek : ''}
            onChange={handleWeekChange}
            variant="outlined"
            disabled={!isDataAvailable}
          >
            <MenuItem value="all">{t('Plots.AllWeeks')}</MenuItem>
            {availableWeeks.map(week => (
              <MenuItem key={week} value={week}>{t('Plots.Week')} {week}</MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Charts Section */}
      <Grid container justifyContent="space-around" alignItems="center" spacing={2} style={{ marginTop: 20 }}>
        {/* Bar Chart Section */}
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: 1, height: '100%', width: '100%' }}>
            {chartData.series.length > 0 ? (
              <BarChart
                height={300}
                series={chartData.series}
                xAxis={chartData.xAxis}
                yAxis={[{ label: 'Number of Observations' }]}
              />
            ) : (
              <p>{noDataMessage}</p>
            )}
          </Paper>
        </Grid>

        {/* Pie Chart Section */}
        <Grid item xs={12} md={4}>
          <Paper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
            {chartData.pieData.length > 0 ? (
              <>
                <PieChart
                  series={[
                    {
                      innerRadius: 0,
                      outerRadius: 80,
                      data: chartData.pieData[0].map(entry => ({
                        ...entry,
                        color: entry.color || '#CCCCCC',
                      })),
                    },
                    {
                      innerRadius: 100,
                      outerRadius: 120,
                      data: chartData.pieData[1],
                    },
                  ]}
                  width={400}
                  height={300}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
                <CustomPieChartLegend data={chartData.pieData[0]} />
              </>
            ) : (
              <p>{noDataMessage}</p>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Line Chart Pest/Disease Dropdown Section */}
      <Grid container justifyContent="center" style={{ marginTop: 20 }}>
        <Grid item xs={12} style={{ width: '100vw' }}>
          <TextField
            select
            label={t('Plots.SelectPestDisease')}
            value={isPestDashboard ? pestOptions.map(opt => opt.id).includes(selectedPestOrDisease) ? selectedPestOrDisease : '' : diseaseOptions.map(opt => opt.id).includes(selectedPestOrDisease) ? selectedPestOrDisease : ''}
            onChange={(e) => setSelectedPestOrDisease(e.target.value)}
            variant="outlined"
            style={{ width: '20%' }}
            disabled={!isDataAvailable}
          >
            {(isPestDashboard ? pestOptions : diseaseOptions).map((option) => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Line Chart Section */}
        <Grid item xs={12} style={{ width: '100vw' }}>
          <Paper style={{ padding: 2 }}>
            {lineChartData.length > 0 && weeksSubmissions.length > 0 ? (
              <LineChart
                series={lineChartData}
                xAxis={[
                  {
                    id: 'weeks',
                    data: weeksSubmissions.map(week => `Week ${week}`),
                    scaleType: 'band',
                    label: 'Week Number'
                  },
                ]}
                yAxis={[
                  {
                    id: 'y',
                    min: 0,
                    label: 'Number of Observations'
                  },
                ]}
                height={300}
              />
            ) : (
              <p>{noDataMessage}</p>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PestDiseaseDashBoard;
