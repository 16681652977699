import React from 'react';
import {
  Container, Typography, List, ListItem, Box, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import createYourPlotLabels from '../../images/createYourPlotLabels.png';
import createScoutingEntries from '../../images/createScoutingEntries.png';
import myPlotsGeneralTree from '../../images/MyPlots_General_Tree.png';
import myPlotsExampleTree from '../../images/MyPlots_Example_Tree.png';
import viewPlotHeatMap from '../../images/viewPlotHeatMap.png';
import viewPlotDashBoard from '../../images/viewPlotDashboard.png';

function SupportPlots() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          Plots User Guide
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">How My Plots Work</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
             A general overview of the My Plots section
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={myPlotsGeneralTree} alt="My Plots General" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='You will need to create a plot of the farm or greenhouse you want to collect data on' />
            </ListItem>
            <ListItem>
              <ListItemText primary='After creating the plot you can create Scouting Entires, these are forms attached to the plot that allow you to enter data.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Data is stored seperately under each Scouting Entry.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='You can have one or multiple scouting entries per plot, it depends how you want to organize things.' />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={myPlotsExampleTree} alt="My Plots Example" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Creating a Plot</Typography>
        </AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <iframe 
            width="560"
            height="315"
            src="https://www.youtube.com/embed/iRVhzwygF78?si=lJACTFYqm7XWkpq3"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </Box>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            This section is focused on creating simplified versions of your farm/greenhouse plot to collect pest and disease information. Follow these steps to manage your plots:
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary='Start by selecting "Add Plot", which will take you to the "Create Plots" page.' />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={createYourPlotLabels} alt="Create Your Plot Labels" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            {[
              '(1) Select the organization we want this plot to be apart of.',
              '(2) Give our plot a name, such as "Bay 1", "Zone 5", "Field 2" or whatever best suits our needs.',
              '(3)(4) Add the number of rows and columns our plot has. "Rows" is the number of gutter rows or field rows our plot has, where "Columns" is the number of sections each row is split into.',
              '(5) If we would like to add a walkway to your plot, we can do so by typing the column you want it in.',
              '(6)(7) (8) We can label our rows and columns for easier map navigation when entering or viewing data.',
              'Once finished, select "Create Plot" which will take us to the "My Plot" page.',
              'Create at least one "Scouting Entries" form associated with this plot to start collecting data.', 
            ].map((text, index) => (
              <ListItem key={index}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Creating Scouting Entries</Typography>
        </AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <iframe 
            width="560"
            height="315"
            src="https://www.youtube.com/embed/u5zh_xkkw4E?si=fbZmORqZqloy5DRm"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </Box>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Create Scouting Entries to start collecting data on the plots
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={createScoutingEntries} alt="Create Scouting Entries" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='(1)(2) Select "Create Scouting Entries" and we will be presented with a page with some pre-selected options. We can then type in the pests and diseases we would like to collect data on then scroll to the bottom and select "Create Form".' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Now we can enter data for that plot using the form by selecting "Enter Data".' />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Viewing Plot Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Once you submit data in the scouting entries form you can view your data
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={viewPlotHeatMap} alt="View Plot Heat Map" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='We can view your data in a heat map, where you are able to change the colour of each pest or disease.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='The more transparent the colour the lower the pest or disease level is on that cell.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='We are also able to click on a cell to view all the specific entries, and delete one if it was entered incorrectly.' />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={viewPlotDashBoard} alt="View Plot Dashboard" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='Above is the dashboard which gives you a simplified breakdown of our data in a speicifc year or week.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='The Y-axis shows you how many entries were made for each pest/disease during the specific time frame.' />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default SupportPlots;
