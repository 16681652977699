import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, TextField, Button, Box, Paper, Checkbox, FormControlLabel,
} from '@mui/material';
import { doc, setDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import OrganizationsDropdown from '../components/OrgDropdown';
import { useSnackbar } from '../components/SnackbarContext';
import Tour from 'reactour';
import { auth } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import RenderCreatePlot from '../components/RenderCreatePlot';
import useOrgFirestore from '../hooks/useOrgFirestore';
import RoleCheck from '../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import restrictedChars from '../local_data/restricted-chars.json';
import * as Sentry from '@sentry/react';

function CreatePlot() {
  const [selectedOrg, setSelectedOrg] = useState('');
  const { firestore, location } = useOrgFirestore(selectedOrg);
  const navigate = useNavigate();
  const [plotName, setPlotName] = useState('');
  const [rows, setRows] = useState('');
  const [columns, setColumns] = useState('');
  const [walkwayPosition, setWalkwayPosition] = useState('');
  const [leftSideLabels, setLeftSideLabels] = useState({});
  const [rightSideLabels, setRightSideLabels] = useState({});
  const [columnLabels, setColumnLabels] = useState({});
  const { showSnackbar } = useSnackbar();
  const [userRole, setUserRole] = useState('');
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [tourSeen, setTourSeen] = useState(localStorage.getItem('createPlotTourSeen') === 'true');
  const [orgDataLimits, setOrgDataLimits] = useState({ currentTotalData: 0, maximumTotalData: 0, currentWrites: 0, maximumWrites: 0 });
  const [plotNameError, setPlotNameError] = useState(false);
  const [rowsError, setRowsError] = useState(false);
  const [columnsError, setColumnsError] = useState(false);
  const [walkwayPositionError, setWalkwayPositionError] = useState(false);
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();
  const [clickedCells, setClickedCells] = useState({}); // State to track clicked cells
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsTourOpen(!tourSeen);
  }, [tourSeen]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('createPlotTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const steps = [
    {
      selector: '[data-tour="name"]',
      content: t('Plots.ReactTour.Desc.1'),
    },
    {
      selector: '[data-tour="rows"]',
      content: t('Plots.ReactTour.Desc.2'),
    },
    {
      selector: '[data-tour="columns"]',
      content: t('Plots.ReactTour.Desc.3'),
    },
    {
      selector: '[data-tour="walkway"]',
      content: t('Plots.ReactTour.Desc.4'),
    },
    {
      selector: '[data-tour="submit"]',
      content: t('Plots.ReactTour.Desc.5'),
    },
    {
      selector: '',
      content: () => (
          <FormControlLabel
              control={<Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />}
              label={t("General.DoNotShow")}
          />
      ),
    },
  ];

  const fetchOrgDataLimits = async () => {
    if (firestore && selectedOrg) {
      const orgDocRef = doc(firestore, 'organizations', selectedOrg);
      const orgDoc = await getDoc(orgDocRef);
      if (orgDoc.exists()) {
        const { currentTotalData, maximumTotalData, currentWrites, maximumWrites } = orgDoc.data();
        setOrgDataLimits({ currentTotalData, maximumTotalData, currentWrites, maximumWrites });
      }
    }
  };

  useEffect(() => {
    fetchOrgDataLimits();
  }, [firestore, selectedOrg]);

  const handleColumnLabelChange = (e, columnIndex) => {
    const newLabel = e.target.value.slice(0, 4);  // Limit to 4 characters
    setColumnLabels(prevLabels => ({ ...prevLabels, [columnIndex]: newLabel }));
  };

  const handleLeftLabelChange = (e, rowIndex) => {
    const newLabel = e.target.value;
    setLeftSideLabels((prevLabels) => ({ ...prevLabels, [rowIndex]: newLabel }));
  };

  const handleRightLabelChange = (e, rowIndex) => {
    const newLabel = e.target.value;
    setRightSideLabels((prevLabels) => ({ ...prevLabels, [rowIndex]: newLabel }));
  };

  const handleRowsChange = (e) => {
    const newValue = e.target.value === '' ? '' : parseInt(e.target.value) || 0;
    if (newValue < 0) {
      setRowsError(t('Snackbar.RowsLessThan0'));
      setRows(0);
    } else if (newValue > 1000) {
      setRowsError(t('Snackbar.RowsGreaterThan1000'));
      setRows(1000);
    } else {
      setRowsError('');
      setRows(newValue);
    }
  };

  const handleColumnsChange = (e) => {
    const newValue = e.target.value === '' ? '' : parseInt(e.target.value) || 0;
    if (newValue < 0) {
      setColumnsError(t('Snackbar.ColumnsLessThan0'));
      setColumns(0);
    } else if (newValue > 1000) {
      setColumnsError(t('Snackbar.ColumnsGreaterThan1000'));
      setColumns(1000);
    } else {
      setColumnsError('');
      setColumns(newValue);
    }
  };

  const handleWalkwayPositionChange = (e) => {
    const newWalkwayPosition = e.target.value === '' ? '' : parseInt(e.target.value, 10);
    
    if (newWalkwayPosition === '') {
      setWalkwayPosition('');
      setWalkwayPositionError('');
    } else if (newWalkwayPosition < 0) {
      setWalkwayPositionError(t('Snackbar.WalkwayPositionMin'));
      setWalkwayPosition(0);
    } else if (newWalkwayPosition > columns + 1) {
      setWalkwayPositionError(t('Snackbar.WalkwayPositionMax'));
    } else {
      setWalkwayPositionError('');
      setWalkwayPosition(newWalkwayPosition);
    }
  };  

  const validatePlotName = (text) => {
    const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
    return !restrictedCharsRegex.test(text);
  };

  const handlePlotNameChange = (e) => {
    const newName = e.target.value;
    if (newName.length > 63) {
      setPlotNameError(true);
    } else if (!validatePlotName(newName)) {
      setPlotNameError(true);
    } else {
      setPlotNameError(false);
    }
    setPlotName(newName);
  };

  const handleSavePlot = async () => {
    try {
      const trimmedPlotName = plotName.trim();
      if (!selectedOrg || !firestore) {
        showSnackbar(t('Snackbar.SelectOrganization'), 'error');
        return;
      }
      if (trimmedPlotName.length > 63) {
        setPlotNameError(true);
        return;
      }
      if (!validatePlotName(trimmedPlotName)) {
        setPlotNameError(true);
        return;
      }

      if (orgDataLimits.currentTotalData > orgDataLimits.maximumTotalData) {
        showSnackbar(t('General.MaxTotalData'), 'error');
        return;
      }

      if (orgDataLimits.currentWrites > orgDataLimits.maximumWrites) {
        showSnackbar(t('General.MaxMonthly'), 'error');
        return;
      }
      const finalWalkwayPosition = walkwayPosition === '' || isNaN(walkwayPosition) ? 0 : walkwayPosition;

      const plotsCollection = collection(firestore, 'organizations', selectedOrg, 'plots');
      const plotNameQuery = query(plotsCollection, where("plotName", "==", trimmedPlotName));

      const plotNameSnapshot = await getDocs(plotNameQuery);

      if (!plotNameSnapshot.empty) {
        setPlotNameError(true);
        return;
      }

      // Convert removed cells into an array of objects like { rowIndex, columnIndex }
      const removedCells = Object.entries(clickedCells)
        .filter(([key, value]) => value)  // Only include cells that are clicked (marked red)
        .map(([key]) => {
          const [rowIndex, columnIndex] = key.split('-').map(Number); // Extract rowIndex and columnIndex
          return { rowIndex, columnIndex }; // Return as an object
        });
  
      const plotId = uuidv4();  // Generate a UUID for the plot ID
      const plotData = {
        organization: selectedOrg,
        plotName: trimmedPlotName,
        rows,
        columns,
        walkwayPosition: finalWalkwayPosition, 
        leftSideLabels: Object.values(leftSideLabels),
        rightSideLabels: Object.values(rightSideLabels),
        columnLabels: Object.values(columnLabels),
        removedCells, // Save the removed cells as objects
        deleted: false
      };

      const plotDocRef = doc(plotsCollection, plotId);
      await setDoc(plotDocRef, plotData);
      showSnackbar(t('Plots.CreatedSuccessfully'), 'success');
      navigate('/home?activePage=plots');
    } catch (error) {
      showSnackbar('Error saving plot, please try again.', 'error');
      Sentry.captureException(error);
    }
  };
  

  const isCreateButtonDisabled = !plotName || rows <= 0 || columns <= 0 || plotNameError;

  return (
    <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: { xs: 'column', md: 'row' } }}>
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={selectedOrg}
        rolesAllowed={['Admin', 'Owner']}
        redirectPage='/home'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Paper
        sx={{
          width: { xs: '100%', md: '15vw' },
          height: 'auto',
          flexShrink: 0,
          border: '2px solid #3C3C3C',
          padding: 1,
          overflow: 'auto',
          marginTop: '16px',
          marginLeft: '16px',
          minWidth: '200px',
        }}
      >
        <Typography variant="h5" gutterBottom>{t('Plots.Create')}</Typography>

        <OrganizationsDropdown selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} setLoading={setLoading} />

        <TextField
          data-tour="name"
          label={t('Plots.Name')}
          variant="outlined"
          margin="normal"
          value={plotName}
          onChange={handlePlotNameChange}
          inputProps={{ maxLength: 63 }}
          helperText={plotNameError ? 'Invalid Chars' : ''}
          error={plotNameError}
          sx={{
            marginBottom: 2,
          }}
        />

        <Box sx={{
          display: 'flex', gap: 2, mb: 0, flexDirection: 'column',
        }}
        >
          <TextField
            data-tour="rows"
            label={t('Plots.NumberOfRows')}
            type="number"
            variant="outlined"
            value={rows}
            onChange={handleRowsChange}
            helperText={rowsError}
            error={Boolean(rowsError)}
            sx={{
              width: '90%',
            }}
          />

          <TextField
            data-tour="columns"
            label={t('Plots.NumberOfColumns')}
            type="number"
            variant="outlined"
            value={columns}
            onChange={handleColumnsChange}
            helperText={columnsError}
            error={Boolean(columnsError)}
            sx={{
              width: '90%',
            }}
          />

          <TextField
            data-tour="walkway"
            label={t('Plots.WalkwayPosition')}
            type="number"
            variant="outlined"
            value={walkwayPosition}
            onChange={handleWalkwayPositionChange}
            helperText={walkwayPositionError}
            error={Boolean(walkwayPositionError)}
            sx={{
              width: '90%',
            }}
          />
        </Box>

        <Button
          data-tour="submit"
          variant="contained"
          color="primary"
          onClick={handleSavePlot}
          sx={{ mt: 2, mb: 2 }}
          disabled={isCreateButtonDisabled}
        >
          {t('Plots.Create')}
        </Button>
      <Typography>
        {t('Plots.RemoveCellsMessage')}
      </Typography>
      </Paper>
        
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
        }}
      >
        <RenderCreatePlot
          rows={rows}
          columns={columns}
          walkwayPosition={parseInt(walkwayPosition, 10)}
          leftSideLabels={leftSideLabels}
          rightSideLabels={rightSideLabels}
          columnLabels={columnLabels}
          onLeftLabelChange={handleLeftLabelChange}
          onRightLabelChange={handleRightLabelChange}
          onColumnLabelChange={handleColumnLabelChange}
          setLeftSideLabels={setLeftSideLabels}
          setRightSideLabels={setRightSideLabels}
          setColumnLabels={setColumnLabels}
          clickedCells={clickedCells} // Pass clickedCells to the RenderCreatePlot component
          setClickedCells={setClickedCells} // Pass the setter for clickedCells to track cell state
        />
      </Box>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </Box>
  );
}

export default CreatePlot;
