import React from 'react';
import { Box, Paper } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const GridCell = ({ columnIndex, rowIndex, style, data }) => {
  const {
    pestData,
    diseaseData,
    pestVisibility,
    diseaseVisibility,
    cameraVisibility,
    pestTypeColors,
    diseaseTypeColors,
    adjustColorOpacity,
    walkwayPosition,
    rawPestData,
    rawDiseaseData,
    cameraData,
    onCellClick,
    removedCells,
  } = data;

  if (removedCells.some(cell => cell.rowIndex === rowIndex && cell.columnIndex === columnIndex)) {
    return null;  // Skip rendering this cell
  }

  let cellEntries = [];

  const collectEntries = (entryData, visibility, colorMap, colIndex) => {
    Object.entries(entryData).forEach(([typeId, locations]) => {
      if (visibility[typeId]?.visible && locations[rowIndex] && locations[rowIndex][colIndex]) {
        const colorInfo = colorMap[typeId];
        if (colorInfo) {
          cellEntries.push({
            intensity: locations[rowIndex][colIndex],
            color: colorInfo.color,
          });
        }
      }
    });
  };

  const isWalkwayPositionDefined = walkwayPosition && walkwayPosition > 0;
  const adjustedWalkwayPosition = isWalkwayPositionDefined ? walkwayPosition - 1 : null;

  if (isWalkwayPositionDefined && columnIndex === adjustedWalkwayPosition) {
    return (
      <Box
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
        }}
      >
        <KeyboardDoubleArrowUpIcon />
      </Box>
    );
  }

  const adjustedColumnIndex = isWalkwayPositionDefined && columnIndex > adjustedWalkwayPosition ? columnIndex - 1 : columnIndex;

  collectEntries(pestData, pestVisibility, pestTypeColors, adjustedColumnIndex);
  collectEntries(diseaseData, diseaseVisibility, diseaseTypeColors, adjustedColumnIndex);

  const segmentStyles = cellEntries.map(entry => ({
    backgroundColor: adjustColorOpacity(entry.color, entry.intensity),
    width: `${100 / cellEntries.length}%`,
    height: '100%',
  }));

  const handleClick = () => {
    const rawPestCellData = (rawPestData[rowIndex] && rawPestData[rowIndex][adjustedColumnIndex]) || [];
    const rawDiseaseCellData = (rawDiseaseData[rowIndex] && rawDiseaseData[rowIndex][adjustedColumnIndex]) || [];

    const cellData = {
      rawPestData: rawPestCellData,
      rawDiseaseData: rawDiseaseCellData,
    };
    onCellClick(cellData);
  };

  const shouldGlow = cameraVisibility && cameraData[rowIndex] && cameraData[rowIndex][adjustedColumnIndex];

  return (
      <Paper
          onClick={handleClick}
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'row',
            border: shouldGlow ? '3px solid black' : '1px solid #ccc', // Apply the bolded black border
            boxSizing: 'border-box',
          }}
      >
        {segmentStyles.length > 0 ? segmentStyles.map((segStyle, index) => (
            <div key={`${rowIndex}-${columnIndex}-${index}`} style={segStyle}></div>
        )) : <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}></div>}
      </Paper>
  );
};


export default GridCell;
