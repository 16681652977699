import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  useTheme,
} from '@mui/material';

function MyAlert({ isOpen, message, onClose }) {
  const theme = useTheme();

  const dialogStyle = {
    '& .MuiDialog-container': {
      pointerEvents: 'none', // Allow interactions with elements behind the dialog
    },
  };

  const contentStyle = {
    pointerEvents: 'auto', // Enable interactions with the content of the dialog
    borderRadius: '12px',
  };

  const buttonStyle = {
    margin: '10px 0',
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="my-alert-dialog"
      sx={dialogStyle}
    >
      <DialogContent sx={contentStyle}>
        <Typography variant="body1">{message}</Typography>
        <Button color="primary" onClick={onClose} variant="contained" sx={buttonStyle}>
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default MyAlert;
