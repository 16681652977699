import React from 'react';
import { Container, Typography } from '@mui/material';
import Footer from '../components/Footer'
function PrivacyPolicy() {
  return (
    <Container maxWidth={false} disableGutters>
      <Container maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h3" gutterBottom>
          Privacy Policy for AUDONIS
        </Typography>
        <Typography variant="body1" paragraph>
          Effective Date: 2024
        </Typography>
        <Typography paragraph>
          AUDONIS ("we," "us," or "our") operates the AUDONIS mobile application ("the app"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our app and the choices you have associated with that data.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Information Collection and Use
        </Typography>
        <Typography paragraph>
          We collect only the email addresses of our users. This data is solely used to facilitate user login and is essential for the functionality of our app.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Data Storage
        </Typography>
        <Typography paragraph>
          The email addresses collected are stored securely on Firebase, our trusted cloud storage service provided by Google.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Use of Data
        </Typography>
        <Typography paragraph>
          The data we collect is used exclusively for enabling users to log in to the app. We do not use your email addresses for marketing or any other purposes.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Data Retention
        </Typography>
        <Typography paragraph>
          We retain your email address for as long as your account remains active. Users can delete their account at any time, after which their data is promptly deleted from our systems.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Data Protection
        </Typography>
        <Typography paragraph>
          We are committed to ensuring that your data is secure. To prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. Children’s Privacy
        </Typography>
        <Typography paragraph>
          Our app does not specifically target or attract children under the age of 18. We do not knowingly collect personal identifying information from children under the age of 13.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. Your Rights
        </Typography>
        <Typography paragraph>
          You have the right to access, edit, or delete your personal information at any time. To delete your account, please go to My Profile and select Delete Account.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. International Users
        </Typography>
        <Typography paragraph>
          The app is available internationally. We take measures to ensure that your data is processed securely and in accordance with this privacy policy regardless of where you are located.
        </Typography>
        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at audonis@audonis.ca.
        </Typography>
      </Container>
      <Footer fixed={false} />
    </Container>
  );
}

export default PrivacyPolicy;
