function normalizeLocation(location) {
  if (location === 'northamerica' || location === 'south_america_east_brazil_sao_paulo') {
      return 'NA';
  } else if (location === 'europe') {
      return 'EU';
  } else {
      return 'NA';  // Default to NA if no valid location is provided
  }
}

async function fetchFromRedis(key, location = 'NA') {
  const normalizedLocation = normalizeLocation(location);
  const functionUrl = (normalizedLocation === 'NA')
      ? `https://us-central1-audonis-81f23.cloudfunctions.net/redisFetch`
      : `https://europe-central2-audonis-81f23.cloudfunctions.net/redisFetch`;

  try {
      const response = await fetch(`${functionUrl}?key=${key}&location=${normalizedLocation}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      return data.value;
  } catch (error) {
      throw error;
  }
}

async function saveToRedis(key, value, location = 'NA', ttl = 7776000) {
  const normalizedLocation = normalizeLocation(location);
  const functionUrl = (normalizedLocation === 'NA')
      ? `https://us-central1-audonis-81f23.cloudfunctions.net/redisPost`
      : `https://europe-central2-audonis-81f23.cloudfunctions.net/redisPost`;

  try {
      const response = await fetch(functionUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ key, value, location: normalizedLocation, ttl }),
      });

      if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  } catch (error) {
      throw error;
  }
}

async function appendToRedis(key, value, location = 'NA', ttl = 7776000) {
    const normalizedLocation = normalizeLocation(location);
    const functionUrl = (normalizedLocation === 'NA')
        ? `https://us-central1-audonis-81f23.cloudfunctions.net/redisAppend`
        : `https://europe-central2-audonis-81f23.cloudfunctions.net/redisAppend`;
  
    try {
        const response = await fetch(functionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ key, value, location: normalizedLocation, ttl }),
        });
  
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
    } catch (error) {
        throw error;
    }
  }

  async function appendBulkToRedis(key, values, location = 'NA', ttl = 7776000) {
    const normalizedLocation = normalizeLocation(location);
    const functionUrl = (normalizedLocation === 'NA')
        ? `https://us-central1-audonis-81f23.cloudfunctions.net/redisAppendBulk`
        : `https://europe-central2-audonis-81f23.cloudfunctions.net/redisAppendBulk`;

    try {
        const response = await fetch(functionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ key, values, location: normalizedLocation, ttl }),  // Pass multiple values
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
    } catch (error) {
        throw error;
    }
}
  async function fetchListFromRedis(key, location = 'NA') {
    const normalizedLocation = normalizeLocation(location);
    const functionUrl = (normalizedLocation === 'NA')
        ? `https://us-central1-audonis-81f23.cloudfunctions.net/redisFetchList`
        : `https://europe-central2-audonis-81f23.cloudfunctions.net/redisFetchList`;

    try {
        const response = await fetch(`${functionUrl}?key=${key}&location=${normalizedLocation}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        return data.value;  // Return the list of items
    } catch (error) {
        throw error;
    }
}

export { fetchFromRedis, saveToRedis, appendToRedis, appendBulkToRedis, fetchListFromRedis };
