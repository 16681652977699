import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Trebuchet MS", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif',
    h3: {
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.9rem',
    },
  },
  palette: {
    primary: {
      main: '#283106',
      light: '#636363',
      dark: '#2A2A2A',
      contrastText: '#98d1a8',
      border: '#b0b6bc'
    },
    secondary: {
      main: '#98d1a8',
      contrastText: '#283106',
    },
    tertiary: {
      main: '#4682b4',
      contrastText: '#2A2A2A'
    },
    quaternary: {
      main: '#FFD9A0'
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.9rem',
        }
      }
    }
  }
});

export default theme;
