import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import {
  TextField, Button, Container, Typography, Checkbox, FormControlLabel, Box,
  InputAdornment, IconButton, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../components/SnackbarContext';
import Footer from '../components/Footer';
import { getFirestoreLocation, auth } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';
import * as Sentry from '@sentry/react'

function SignUp() {
  const { firestore, storageBucket } = getFirestoreLocation('(default)');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [dialog, setDialog] = useState({ open: false, title: '', message: '' });
  const [emailError, setEmailError] = useState(false); // State to track email error

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const isPasswordValid = () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
    return passwordRegex.test(password);
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignUp = async () => {
    if (!isEmailValid(email)) {
      setEmailError(true);
      setDialog({ open: true, title: 'Error', message: t('SignUp.InvalidEmail') });
      return;
    }
  
    setEmailError(false); // Reset email error state if valid
  
    if (password !== confirmPassword) {
      setDialog({ open: true, title: 'Error', message: t('SignUp.NoMatch') });
      return;
    }

    if (!isPasswordValid()) {
      setDialog({ open: true, title: 'Error', message: t('SignUp.InvalidPassword') });
      return;
    }

    if (!agreeToTerms) {
      setDialog({ open: true, title: 'Error', message: t('SignUp.AgreeTerms') });
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  
      const user = userCredential.user;
  
      await sendEmailVerification(user);
  
      const currentDate = new Date();
      const creationDate = currentDate.toISOString().split('T')[0];
  
      const usersCollection = collection(firestore, 'users');
      const userDocRef = doc(usersCollection, user.uid);
  
      await setDoc(userDocRef, {
        creationDate,
        email,
        ownsOrganization: false,
        inCheckout: false,
        ownedOrgId: "",
        currentPlan: "None",
        subscriptionId: ""
      });
  
      showSnackbar(t('Snackbar.VerificationEmail'), 'success');
      await signOut(auth);
  
      navigate('/login');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setDialog({ open: true, title: 'Error', message: t('SignUp.EmailInUse') });
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
      }
    }
  };
  

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, title: '', message: '' });
  };

  const isSignUpDisabled = !email || !password || !confirmPassword || !agreeToTerms;

  return (
      <Box sx={{ width: '100%' }}>
        <Container component="main" maxWidth="xs">
          <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
            {t('Auth.SignUpButton')}
          </Typography>
          <div className="sign-up-form">
            <TextField
                margin="normal"
                fullWidth
                label={t('Auth.EmailLabel')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={emailError ? t('SignUp.InvalidEmail') : ''}
            />
            <TextField
                margin="normal"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t('Auth.PasswordLabel')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                  ),
                }}
            />
            <TextField
                margin="normal"
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label={t('Auth.ConfirmPasswordLabel')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                  ),
                }}
            />
            <FormControlLabel
                control={
                  <Checkbox
                      checked={agreeToTerms}
                      onChange={(e) => setAgreeToTerms(e.target.checked)}
                      color="primary"
                  />
                }
                label={(
                    <Typography variant="body2">
                      {t('General.AgreeTo')}
                      <Link to="#" onClick={handleOpenTermsModal}>
                        {t('General.TermsAndConditions')}
                      </Link>
                    </Typography>
                )}
            />
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSignUp}
                disabled={isSignUpDisabled}
                sx={{ mt: 3, mb: 2, fontSize: '1.1rem' }}
            >
              {t('Auth.SignUpButton')}
            </Button>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', paddingBottom: '300px' }}>
              {t('Auth.SignUpAgreement')}{' '}
              <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                {t('Auth.TermsAndConditions')}
              </Link>{' '}
              {t('Auth.And')}{' '}
              <Link href="/privacy-policy" target="_blank" rel="noopener">
                {t('Auth.PrivacyPolicy')}
              </Link>.
            </Typography>
          </div>
          <TermsAndConditionsModal open={openTermsModal} onClose={handleCloseTermsModal} />
          <Dialog open={dialog.open} onClose={handleCloseDialog}>
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialog.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
        <Footer fixed={true} />
      </Box>
  );
}

export default SignUp;
