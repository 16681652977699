import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop, Fade, Modal } from '@mui/material';

export default function AlertDialog({ open, onClose, onAgree, isLoading, title, dialogue, tickBoxContent }) {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleAgreeClick = () => {
    if (!isLoading) {
      onAgree(); // Call onAgree function passed from parent
    }
  };

  return (
      <>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogue}
            </DialogContentText>
            <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
                label={tickBoxContent}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Disagree</Button>
            <Button onClick={handleAgreeClick} disabled={!checked || isLoading} autoFocus>
              {isLoading ? 'Agreeing...' : 'Agree'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Full-screen loading overlay */}
        {isLoading && (
            <Modal
                open={isLoading}
                onClose={() => {}} // Prevent modal from closing
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                  style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } // Adjust opacity here
                }}
            >
              <Fade in={isLoading}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '100vh',
                  minWidth: '100vw'
                }}>
                  <CircularProgress />
                </div>
              </Fade>
            </Modal>
        )}
      </>
  );
}