import React, { useState, useEffect } from 'react';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Typography, Container, Box, Divider,
} from '@mui/material';
import Footer from '../components/Footer';
import { useSnackbar } from '../components/SnackbarContext';
import { auth } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';

function Account() {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const { showSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        try {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    setCurrentUser(user);
                } else {
                    navigate('/');
                }
            });

            return () => unsubscribe();
        } catch (error) {
            Sentry.captureException(error);
        }
    }, [navigate]);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            showSnackbar(t('Snackbar.ErrorSignOut'), 'error');
        }
    };

    return (
        <Container maxWidth={false} disableGutters={true} sx={{ padding: 0, margin: 0, width: '100%' }}>
            <Container maxWidth="lg" sx={{ marginTop: 3 }}>
            <Typography variant="h3" gutterBottom>
                {t('Navbar.Account')}
            </Typography>
            <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />


            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" component={Link} to={`/my-profile/${currentUser?.uid}`} sx={{ width: '500px' }}>
                    {t('Account.ViewProfile')}
                </Button>
            </Box>

            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" component={Link} to={`/my-notifications`} sx={{ width: '500px' }}>
                    {t('Account.ViewNotifications')}
                </Button>
            </Box>

            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" component={Link} to="/my-organizations" sx={{ width: '500px' }}>
                    {t('Account.ManageOrganisations')}
                </Button>
            </Box>

            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" onClick={handleSignOut} sx={{ width: '300px' }}>
                    {t('Auth.SignOutButton')}
                </Button>
            </Box>
            </Container>
            <Footer fixed={true} />
        </Container>
    );
}

export default Account;
