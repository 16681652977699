import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react'

const DeleteDialogBox = ({ open, onClose, onConfirm, itemName, newDialog }) => {
  const [confirmationText, setConfirmationText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      setConfirmationText('');
      setError(false);
    }
  }, [open]);

  const handleConfirm = async () => {
    if (confirmationText === 'DELETE') {
      setLoading(true);
      setError(false);
      try {
        await onConfirm();
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('General.DeleteDialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {newDialog ? newDialog : `${t('General.DeleteDialog1')} ${itemName} ${t('General.DeleteDialog2')}`}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Type DELETE to confirm"
          fullWidth
          value={confirmationText}
          sx={{mt:2}}
          onChange={(e) => setConfirmationText(e.target.value)}
          disabled={loading}
          error={error}
          helperText={error && t('General.DeleteError')}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly' }}>
        <Button variant="contained" onClick={handleConfirm} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : t('General.Confirm')}
        </Button>
        <Button variant="contained" onClick={onClose} color="primary" disabled={loading}>
          {t('General.Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialogBox;
