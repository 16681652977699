import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress, Divider, TablePagination, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, query, onSnapshot, orderBy, doc, deleteDoc } from 'firebase/firestore';
import { getFirestoreLocation, auth } from '../firebaseConfig'; // Adjust the import path as necessary
import { useSnackbar } from '../components/SnackbarContext';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react'

function MyNotifications() {
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [defFirestore, setDefFirestore] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNotifications = async () => {
      const user = auth.currentUser;
      const {firestore: dFirestore, storageBucket: sb} = await getFirestoreLocation('default');
      setDefFirestore(dFirestore);
      if (user) {
        const notificationsRef = collection(dFirestore, `users/${user.uid}/notifications`);
        const q = query(notificationsRef, orderBy('date', 'desc'));
        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          const notificationsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setNotifications(notificationsData);
          setLoading(false);
        });
        return () => unsubscribeSnapshot();
      } else {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleDeleteNotification = async (notificationId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const notificationDoc = doc(defFirestore, `users/${user.uid}/notifications`, notificationId);
        await deleteDoc(notificationDoc);
        showSnackbar(t('Snackbar.NotificationDeleted'), 'success');
        // Remove the deleted notification from the state
        setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== notificationId));
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'success');
      Sentry.captureException(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayDate = (timestamp) => {
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
  };

  return (
      <Container sx={{ mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          {t('Navbar.MyNotifications')}
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
        {loading ? (
            <CircularProgress />
        ) : (
            <Paper>
              {notifications.length > 0 ? (
                  <>
                    {notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification) => (
                        <div key={notification.id} style={{ padding: '16px', borderBottom: '1px solid #ccc', position: 'relative' }}>
                          <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteNotification(notification.id)}
                              style={{ position: 'absolute', top: '8px', right: '8px' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Typography variant="h6">{notification.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {displayDate(notification.date)}
                          </Typography>
                          <Typography variant="body1">{notification.message}</Typography>
                        </div>
                    ))}
                    <TablePagination
                        component="div"
                        count={notifications.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage={t('Rows per page')}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
                    />
                  </>
              ) : (
                  <Typography variant="body2" color="textSecondary" style={{ padding: '16px' }}>
                    {t('Navbar.NoNotifications')}
                  </Typography>
              )}
            </Paper>
        )}
      </Container>
  );
}

export default MyNotifications;