import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Card, CardContent, Box } from '@mui/material';
import {doc, onSnapshot, deleteDoc, getDoc, getDocs, collection} from 'firebase/firestore';
import { sendPasswordResetEmail, deleteUser } from 'firebase/auth';
import { useSnackbar } from '../components/SnackbarContext';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import DeleteDialogBox from '../components/DeleteDialogBox';
import axios from "axios";
import useOrgFirestore from '../hooks/useOrgFirestore';
import { useNavigate } from 'react-router-dom';

function MyProfile() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { firestore, storageBucket } = getFirestoreLocation('(default)');
  const { t } = useTranslation();
  const [orgID, setOrgID] = useState('');
  const [ownsOrg, setOwnsOrg] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = auth.currentUser;
    const currentUserUID = currentUser ? currentUser.uid : null;

    if (currentUserUID) {
      const userRef = doc(firestore, `users/${currentUserUID}`);

      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setUsername(data.email);
          setEmail(data.email); // Set the email state for password reset
          setOwnsOrg(data.ownsOrganization);
          setOrgID(data.ownedOrgId);
          setSubscriptionID(data.subscriptionId);
        } else {
          console.log('No data available');
        }
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [firestore]);

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      showSnackbar(t('MyProfile.ChangePasswordMessage'), 'success');
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  const handleDeleteAccount = async () => {
    const currentUser = auth.currentUser;
    const userRef = doc(firestore, `users/${currentUser.uid}`);

    if (currentUser) {
      try {
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setUsername(data.email);
          setEmail(data.email); // Set the email state for password reset
          setOwnsOrg(data.ownsOrganization);
          setOrgID(data.ownedOrgId);
          setSubscriptionID(data.subscriptionId);
        } else {
          console.log("No such document!");
        }

        console.log("deleting");

        if(ownsOrg){
          console.log("deleting their org");
          if (!subscriptionID) {
            return;
          }

          console.log(subscriptionID);
          const response = await axios.post(import.meta.env.VITE_FUNCTION_LINK_CANCEL_SUBSCRIPTION, {
            subscriptionId: subscriptionID,
            effectiveFrom: 'immediately', mode: import.meta.env.VITE_WEB_MODE
          });
        }

        // Get all orgs the user is part of
        const orgsRef = collection(firestore, `users/${currentUser.uid}/organizations`);
        const orgsSnapshot = await getDocs(orgsRef);
        const orgsIds = orgsSnapshot.docs.map(doc => doc.id);

        // Delete user from those orgs
        for (const orgId of orgsIds) {

          const orgRef = doc(firestore, `organizations/${orgId}`);

          const orgSnap = await getDoc(orgRef);

          const location = orgSnap.data().location;

          const {firestore: curLocFirestore, storageBucket: sb} = await getFirestoreLocation(location);

          // Implement your logic to remove the user from each organization
          console.log(`Removing user from org: ${orgId}`);

          // Delete the document organizations/orgId/users/auth.currentUser.uid
          await deleteDoc(doc(curLocFirestore, `organizations/${orgId}/users/${currentUser.uid}`));

          // Delete the document users/auth.currentUser.uid/organizations/orgId
          await deleteDoc(doc(firestore, `users/${currentUser.uid}/organizations/${orgId}`));
        }

        //Delete user notifications
        const notificationsRef = collection(firestore, `users/${currentUser.uid}/notifications`);
        const notificationsSnapshot = await getDocs(notificationsRef);

        for (const doc of notificationsSnapshot.docs) {
          await deleteDoc(doc.ref);
        }

        //Delete user document
        await deleteDoc(doc(firestore, `users/${currentUser.uid}`))

        //Delete user from firestore auth
        await deleteUser(currentUser);

        //Log them out
        navigate('/');

        showSnackbar(t('MyProfile.DeleteAccountMessage'), 'success');
      } catch (error) {
        Sentry.captureException(error);
        alert(error.message);
      }
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Container component="main" maxWidth="sm" sx={{ mt: 4, mb: 4, p: 4, borderRadius: 2, boxShadow: 3 }}>
        <Card>
          <CardContent>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              {t('MyProfile.Title')}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '1.2rem', mb: 1 }}>
                {t('Auth.EmailLabel')}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.2rem', color: 'text.secondary' }}>
                {username || '...'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ fontSize: '1.2rem', mb: 1 }}>
                {t('Auth.PasswordLabel')}
              </Typography>
              <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2, mt: 1 }}
                  onClick={handleForgotPassword}
              >
                {t('MyProfile.ChangePasswordButton')}
              </Button>
            </Box>
            <Box sx={{ ml: 2, mt: 3 }}>
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
              >
                {t('MyProfile.DeleteAccountButton')}
              </Button>
            </Box>
          </CardContent>
        </Card>
        <DeleteDialogBox
            open={open}
            onClose={handleClose}
            onConfirm={handleDeleteAccount}
            itemName={''}
            newDialog={`${t('MyProfile.DeleteAccountMessage1')} ${username} ${t('MyProfile.DeleteAccountMessage2')}`}
        />
      </Container>
  );
}

export default MyProfile;