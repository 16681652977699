import React from 'react';
import { Container, Typography } from '@mui/material';
import Footer from '../components/Footer'

function AccessibilityStatement() {
  return (
    <Container maxWidth={false} disableGutters>
    <Container maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="h3" gutterBottom>
        AUDONIS Accessibility Statement
      </Typography>
      <Typography variant="body1" paragraph>
        At AUDONIS, we are committed to making sure that our app and website are accessible and easy to use for everyone. If you encounter any difficulties while using our content or notice any part of our app or website that seems inaccessible to people with disabilities, we want to hear from you.
      </Typography>
      <Typography paragraph>
        To report accessibility issues or offer feedback on how we can improve our accessibility features, please reach out to us via the Contact Us form on our website or email us directly at support@audonis.ca. Kindly include "Accessibility" in the subject line and provide a detailed description of the issue or your suggestions for improvement.
      </Typography>
      <Typography paragraph>
        We value your feedback and will consider it as we strive to enhance our accessibility policies and meet the needs of all our users. While we do not have control over third-party vendors, we encourage them to provide content that is accessible and user-friendly.
      </Typography>
      </Container>
      <Footer fixed={true} />
    </Container>
    
  );
}

export default AccessibilityStatement;
