import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Typography,
  Card, CardContent, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { auth } from '../firebaseConfig';
import Footer from '../components/Footer';
import MyAlert from '../components/MyAlert';
import { useSnackbar } from '../components/SnackbarContext';
import MyConfirm from '../components/MyConfirm';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react'

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [handleConfirm, setHandleConfirm] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');
  const { t } = useTranslation();

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const showErrorDialog = (message) => {
    setErrorDialogMessage(message);
    setErrorDialogOpen(true);
  };

  const showConfirmation = (message, funcO) => {
    setHandleConfirm(() => funcO);
    setConfirmMessage(message);
    setConfirmationOpen(true);
  };

  const closeAlert = () => setAlertOpen(false);

  const closeErrorDialog = () => setErrorDialogOpen(false);

  const closeConfirmation = () => setConfirmationOpen(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user && user.emailVerified) {
        navigate('/home');
      } else {
        showConfirmation(
          t('Snackbar.VerificationEmail2'),
          () => handleResendVerification()
        );
        await signOut(auth);
      }
    } catch (error) {
      showErrorDialog(t('Auth.IncorrectEmailPass'));
    }
  };

  const handleResendVerification = async () => {
    try {
      // Log the user back in
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      
      // Send verification email
      await sendEmailVerification(user);
      showSnackbar(t('Snackbar.VerificationEmail3'), 'success');
    } catch (error) {
      showAlert(error.message);
      Sentry.captureException(error);
    }
    closeConfirmation();
    await signOut(auth);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box sx={{width:'100%'}}>
      <Container component="main" maxWidth="xs">
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Typography component="h1" variant="h5">
            {t('Auth.LoginButton')}
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label={t('Auth.EmailLabel')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <TextField
            margin="normal"
            fullWidth
            type="password"
            label={t('Auth.PasswordLabel')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleLogin}
            disabled={!email || !password}
            sx={{ mt: 3, mb: 2, fontSize: '1.1rem' }}
          >
            {t('Auth.LoginButton')}
          </Button>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Link to="/forgot-password">
              <Typography variant="body2" color="textSecondary">
                {t('Auth.ForgotPasswordButton')}
              </Typography>
            </Link>
            <Link to="/signup">
              <Typography variant="body2" color="textSecondary">
                {t('Auth.SignUpButton')}
              </Typography>
            </Link>
          </Box>
        </CardContent>
      </Card>
      <MyAlert isOpen={alertOpen} onClose={closeAlert} message={alertMessage} />
      <MyConfirm
        isOpen={confirmationOpen}
        message={confirmMessage}
        onConfirm={handleConfirm}
        onCancel={closeConfirmation}
      />
      <Dialog
        open={errorDialogOpen}
        onClose={closeErrorDialog}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
    <Footer fixed={true} />
  </Box>
  );
}

export default Login;
