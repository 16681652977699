import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Paper, Checkbox, FormGroup, FormControlLabel, Tooltip,
} from '@mui/material';
import {
  doc, getDoc, getDocs, updateDoc, collection, addDoc, increment, Timestamp,
} from 'firebase/firestore';
import { useSnackbar } from '../components/SnackbarContext';
import { getFirestoreLocation, auth } from '../firebaseConfig';
import { sendNotification } from '../utils/SendNotification';
import RoleCheck from '../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

function FormDataEntry() {
  const { orgId, formId, location } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const { firestore: defFirestore, storageBucket: defStorageBucket } = getFirestoreLocation('default');
  const [formDetails, setFormDetails] = useState(null);
  const [answers, setAnswers] = useState({});
  const [userRole, setUserRole] = useState('');
  const [orgName, setOrgName] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const [orgDataLimits, setOrgDataLimits] = useState({ currentTotalData: 0, maximumTotalData: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFormDetails = async () => {
      try {
        const formRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
        const docSnapshot = await getDoc(formRef);

        if (docSnapshot.exists()) {
          const formData = docSnapshot.data();
          const filteredQuestions = formData.questions.filter(question => !question.isDeleted);
          setFormDetails({ ...formData, questions: filteredQuestions });
        } else {
          showSnackbar(t('Snackbar.FormExistError'), 'error');
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchFormDetails();
  }, [orgId, formId, firestore]);

  useEffect(() => {
    const fetchOrgDataLimits = async () => {
      if (firestore && orgId) {
        const orgDocRef = doc(firestore, 'organizations', orgId);
        const orgDoc = await getDoc(orgDocRef);
        if (orgDoc.exists()) {
          const { currentTotalData, maximumTotalData, name } = orgDoc.data();
          setOrgName(name);
          setOrgDataLimits({ currentTotalData, maximumTotalData });
        }
      }
    };

    fetchOrgDataLimits();
  }, [firestore, orgId]);

  useEffect(() => {
    if (formDetails?.questions) {
      const initialAnswers = formDetails.questions.reduce((acc, question) => {
        acc[question.id] = question.dataType === 'multiple choice' ? [] : '';
        return acc;
      }, {});
      setAnswers(initialAnswers);
    }
  }, [formDetails]);

  const validateInput = (question, value) => {
    let error = '';
    if (!question.mandatory && !value.trim()) return error;
    if (question.mandatory && question.dataType === 'multiple choice') {
      if (!value || value.length === 0) {
        error = t('Forms.SelectMinimum');
      }
    }
    if (question.dataType === 'text' || question.dataType === 'number') {
      if (value.length > 255) error = t('Forms.MaxCharacters');
      if (!value.trim()) error = t('Forms.TextRequired');
      if (question.dataType === 'number' && !/^\d+(\.\d+)?$/.test(value)) error = t('Forms.ValidNumber');
    }
    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let allMandatoryAnswered = true;

    formDetails.questions.forEach((question) => {
      const answer = answers[question.id];
      if (question.mandatory && ((Array.isArray(answer) && answer.length === 0) || !answer)) {
        allMandatoryAnswered = false;
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          [question.id]: 'This field is mandatory.',
        }));
      }
    });

    if (!allMandatoryAnswered) {
      showSnackbar(t('Forms.MandatoryMessage'), 'error');
      return;
    }

    const hasErrors = Object.values(validationErrors).some((error) => error !== '');
    if (hasErrors) {
      showSnackbar(t('Forms.ErrorMessage'), 'error');
      return;
    }

    const submission = {
      submittedAt: Timestamp.now(),
    };

    formDetails.questions.forEach((question) => {
      const answer = answers[question.id];
      if (Array.isArray(answer)) {
        submission[question.id] = [...answer];
      } else {
        submission[question.id] = answer;
      }
    });

    const submissionsCollection = collection(firestore, 'organizations', orgId, 'forms', formId, 'submissions');
  
    try {
      const submissionTime = Timestamp.now(); // Capture the exact time used in Firestore
      const formRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
      const formDocSnap = await getDoc(formRef);
      const formData = formDocSnap.data();

      if (formData.deleted === null || formData.deleted === true) {
        showSnackbar(t('General.DocumentDoesNotExist'), 'error');
        return;
      }

      await updateDoc(formRef, {
        lastUpdated: submissionTime,
        numberOfSubmissions: increment(1),
      });

      const orgDocRef = doc(firestore, 'organizations', orgId);
      const orgDocSnap = await getDoc(orgDocRef);
      const orgData = orgDocSnap.data();

      if (orgData.currentTotalData >= orgData.maximumTotalData) {
        showSnackbar(t('General.MaxTotalData'), 'error');
        return;
      }

      if (orgData.currentWrites >= orgData.maximumWrites) {
        showSnackbar(t('General.MaxMonthly'), 'error');
        return;
      }

      await updateDoc(orgDocRef, {
        currentTotalData: increment(1),
        currentWrites: increment(1),
      });
  
      const sendNotificationsToAdminsAndOwners = async (message) => {
        // Get users collection inside the organization
        const usersRef = collection(firestore, `organizations/${orgId}/users`);
        const usersSnapshot = await getDocs(usersRef);
        const adminOwnerUsers = [];

        // Filter users with Admin or Owner role
        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          if (userData.role === 'Admin' || userData.role === 'Owner') {
            adminOwnerUsers.push(userDoc.id);
          }
        });

        // Send notification to each Admin/Owner
        for (const userId of adminOwnerUsers) {
          await sendNotification(userId, 'Usage Notice', message, defFirestore);
        }
      };
  
      if ((orgData.currentTotalData + 1) / orgData.maximumTotalData >= 0.5 && orgData.currentTotalData / orgData.maximumTotalData < 0.5) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 50% of its maximum total data.`);
      }
      if ((orgData.currentTotalData + 1) / orgData.maximumTotalData >= 0.9 && orgData.currentTotalData / orgData.maximumTotalData < 0.9) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 90% of its maximum total data.`);
      }
      if ((orgData.currentWrites + 1) / orgData.maximumWrites >= 0.5 && orgData.currentWrites / orgData.maximumWrites < 0.5) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 50% of its submissions for this month.`);
      }
      if ((orgData.currentWrites + 1) / orgData.maximumWrites >= 0.9 && orgData.currentWrites / orgData.maximumWrites < 0.9) {
        await sendNotificationsToAdminsAndOwners(`Organization ${orgName} has hit 90% of its submissions for this month.`);
      }
  
      await addDoc(submissionsCollection, submission);

      showSnackbar(t('Snackbar.DataSubmitted'), 'success');
  
      // Reset the form after submission
      setAnswers(formDetails.questions.reduce((acc, question) => {
        acc[question.id] = question.dataType === 'multiple choice' ? [] : '';
        return acc;
      }, {}));
  
    } catch (error) {
      showSnackbar('Error submitting form.', 'error');
      Sentry.captureException(error);
    }
  };

  const handleCheckboxChange = (questionId, optionValue, checked) => {
    setAnswers(prevAnswers => {
      const currentAnswers = prevAnswers[questionId] || [];
      if (checked) {
        return { ...prevAnswers, [questionId]: [...currentAnswers, optionValue] };
      } else {
        return { ...prevAnswers, [questionId]: currentAnswers.filter(value => value !== optionValue) };
      }
    });
  };

  const handleInputChange = (questionId, value) => {
    const question = formDetails.questions.find((q) => q.id === questionId);
    const error = validateInput(question, value);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [questionId]: error,
    }));
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  if (!formDetails || !formDetails.questions) {
    return <div>Loading...</div>;
  }

  const getInputComponent = (question) => {
    let inputLabel = '';
    switch (question.dataType) {
      case 'text':
        inputLabel = t('Forms.TextLabel');
        break;
      case 'number':
        inputLabel = t('Forms.NumberLabel');
        break;
      case 'date':
        inputLabel = t('Forms.DateLabel');
        break;
      case 'dropdown':
        inputLabel = t('Forms.DropDownLabel');
        break;
      default:
        inputLabel = question.text.slice(4);
    }
  
    switch (question.dataType) {
      case 'text':
      case 'number':
      case 'date':
        return (
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {question.text.slice(4)}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label={inputLabel}
              type={question.dataType === 'date' ? 'date' : 'text'}
              required={question.mandatory}
              value={answers[question.id] ?? ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              error={!!validationErrors[question.id]}
              helperText={validationErrors[question.id]}
              inputProps={{ maxLength: 255 }}
              InputLabelProps={question.dataType === 'date' ? { shrink: true } : {}}
            />
          </div>
        );
      case 'dropdown':
        return (
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {question.text.slice(4)}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{inputLabel}</InputLabel>
              <Select
                label={inputLabel}
                required={question.mandatory}
                value={answers[question.id] ?? ''}
                onChange={(e) => handleInputChange(question.id, e.target.value)}
              >
                {question.options.map(option => (
                  <MenuItem key={option.id} value={option.value}>{option.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      case 'multiple choice':
        return (
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {question.text.slice(4)}
            </Typography>
            <FormGroup>
              {question.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      checked={answers[question.id]?.includes(option.value) || false}
                      onChange={(e) => handleCheckboxChange(question.id, option.value, e.target.checked)}
                    />
                  }
                  label={option.value}
                />
              ))}
            </FormGroup>
          </div>
        );
      default:
        return null;
    }
  };  

  return (
    <Container maxWidth="md">
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={orgId}
        rolesAllowed={['Admin', 'Owner', 'Contributor']}
        redirectPage='/account'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Typography variant="h4" gutterBottom style={{marginTop: '20px'}}>
        {formDetails.formName}
      </Typography>
      <form onSubmit={handleSubmit}>
        {formDetails.questions.map((question) => (
          <Paper key={question.id} style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', padding: '10px' }}>
            {getInputComponent(question)}
          </Paper>
        ))}
        <Tooltip title={orgDataLimits.currentTotalData >= orgDataLimits.maximumTotalData ? 'Data limit exceeded' : ''}>
          <span>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={orgDataLimits.currentTotalData >= orgDataLimits.maximumTotalData}
            >
              {t('General.Submit')}
            </Button>
          </span>
        </Tooltip>
      </form>
    </Container>
  );
}

export default FormDataEntry;
