import React from 'react';
import {
    Box, Button, Typography, Checkbox, FormControlLabel, Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function PestAndDiseaseLegend({
                                  pestTypeColors,
                                  diseaseTypeColors,
                                  onTypeChange,
                                  pestVisibility,
                                  diseaseVisibility,
                                  onSelectAllPests,
                                  onClearAllPests,
                                  onSelectAllDiseases,
                                  onClearAllDiseases,
                                  onPestCheckboxChange,
                                  onDiseaseCheckboxChange,
                                  onCameraCheckboxChange,
                                  handleTypeSelect,
                                  cameraVisibility, // Add cameraVisibility as a prop
                                  setCameraVisibility, // Add setCameraVisibility as a prop
                              }) {
    const { t } = useTranslation();

    const renderLegendItems = (typeColors, visibility, isPest) => {
        return Object.entries(typeColors)
            .filter(([id, { text }]) => text !== 'None')
            .map(([id, { text, color }]) => (
                <Paper
                    key={id}
                    sx={{
                        display: 'flex', alignItems: 'center', marginY: 1, padding: 1,
                    }}
                >
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={visibility[id]?.visible || false}
                                onChange={() => isPest ? onPestCheckboxChange(id) : onDiseaseCheckboxChange(id)}
                            />
                        )}
                        label={text}
                    />
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: 24,
                            height: 24,
                            borderRadius: '10%',
                            marginLeft: 2,
                            cursor: 'pointer',
                        }}
                        onClick={(event) => handleTypeSelect(event, { id, text, color }, isPest)}
                    />
                </Paper>
            ));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Camera Visibility Toggle */}
            <Box sx={{ marginBottom: 4 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={cameraVisibility}
                            onChange={(event) => setCameraVisibility(event.target.checked)}
                        />
                    }
                    label={t('Plots.ShowCameraData')}
                />
            </Box>

            {/* Pests Section */}
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6">{t('Plots.Pests')}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                    <Button variant="contained" onClick={onSelectAllPests} size="small" sx={{ marginRight: 1, fontSize: '0.7rem' }}>
                        {t('Plots.SelectAll')}
                    </Button>
                    <Button variant="contained" onClick={onClearAllPests} size="small" sx={{ fontSize: '0.7rem' }}>
                        {t('Plots.ClearAll')}
                    </Button>
                </Box>
                {renderLegendItems(pestTypeColors, pestVisibility, true)}
            </Box>

            {/* Diseases Section */}
            <Box>
                <Typography variant="h6">{t('Plots.Diseases')}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                    <Button variant="contained" onClick={onSelectAllDiseases} size="small" sx={{ marginRight: 1, fontSize: '0.7rem' }}>
                        {t('Plots.SelectAll')}
                    </Button>
                    <Button variant="contained" onClick={onClearAllDiseases} size="small" sx={{ fontSize: '0.7rem' }}>
                        {t('Plots.ClearAll')}
                    </Button>
                </Box>
                {renderLegendItems(diseaseTypeColors, diseaseVisibility, false)}
            </Box>
        </Box>
    );
}

export default PestAndDiseaseLegend;