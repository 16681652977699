import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  useTheme,
} from '@mui/material';

function MyConfirm({
  isOpen, message, onConfirm, onCancel,
}) {
  const theme = useTheme();

  const dialogStyle = {
    '& .MuiDialog-container': {
      pointerEvents: 'none', // Allow interactions with elements behind the dialog
    },
  };

  const contentStyle = {
    pointerEvents: 'auto', // Enable interactions with the content of the dialog
    borderRadius: '8px', // Add border-radius for slightly curved corners
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  };

  const buttonStyle = {
    flexGrow: 1,
    margin: '0 10px',
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onCancel()}
      aria-labelledby="confirmation-modal"
      sx={dialogStyle}
    >
      <DialogContent sx={contentStyle}>
        <Typography variant="body1">{message}</Typography>
        <div style={buttonContainerStyle}>
          <Button
            color="primary"
            onClick={onCancel}
            variant="contained"
            sx={buttonStyle}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onConfirm}
            variant="contained"
            sx={buttonStyle}
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MyConfirm;
