import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Button, Typography, Stack, TextField, Card, CardContent,
} from '@mui/material';
import {
  doc, getDoc, updateDoc, collection, getDocs,
} from 'firebase/firestore';
import { getFirestoreLocation } from '../firebaseConfig';
import { useSnackbar } from '../components/SnackbarContext';
import { Question } from '../components/Question';
import { auth } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import RoleCheck from '../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import restrictedCharsData from '../local_data/restricted-chars.json';
import * as Sentry from '@sentry/react';

function EditForm() {
  const { orgId, formId, location } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [formQuestions, setFormQuestions] = useState([]);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [formName, setFormName] = useState('');
  const [originalFormName, setOriginalFormName] = useState('');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();
  const [isFormNameValid, setIsFormNameValid] = useState(true);
  const dataTypes = ['Text', 'Number', 'Date', 'Dropdown', 'Multiple Choice', 'Image'];
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();
  // max image questions must not be greater than 1
  const maxImageQuestions = (formQuestions.filter((q) => q.dataType === 'image').length > 1);

  const restrictedChars = new RegExp(`[${restrictedCharsData.restricted_chars.join('')}]`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formRef = doc(firestore, `organizations/${orgId}/forms`, formId);
        const snapshot = await getDoc(formRef);
        if (snapshot.exists()) {
          const questions = snapshot.data().questions || [];
          setFormQuestions(
            questions.filter(question => !question.isDeleted).map(question => ({
              ...question,
              text: question.text.startsWith('aud_') ? question.text.slice(4) : question.text,
            }))
          );
          setDeletedQuestions(
            questions.filter(question => question.isDeleted).map(question => ({
              ...question,
              text: question.text.startsWith('aud_') ? question.text.slice(4) : question.text,
            }))
          );
          setFormName(snapshot.data().formName || '');
          setOriginalFormName(snapshot.data().formName || '');
        }
      } catch (error) {
        showSnackbar('Error fetching form data', 'error');
        Sentry.captureException(error);
      }
    };

    fetchData();
  }, [orgId, formId, firestore, showSnackbar]);

  const validateText = (text) => {
    return !restrictedChars.test(text);
  };

  const handleFormNameChange = (event) => {
    const newName = event.target.value.slice(0, 63);
    if (!validateText(newName)) {
      setIsFormNameValid(false);
    } else {
      setIsFormNameValid(true);
    }
    setFormName(newName);
  };

  const checkForDuplicateFormName = async (newFormName) => {
    try {
      const formsRef = collection(firestore, `organizations/${orgId}/forms`);
      const querySnapshot = await getDocs(formsRef);
      const formNames = querySnapshot.docs
          .filter(doc => doc.id !== formId && doc.data().formName !== originalFormName)
          .map(doc => ({
            id: doc.id,
            name: doc.data().formName,
          }));

      const isDuplicate = formNames.some(entry => entry.name === newFormName || entry.id === newFormName);
      return !isDuplicate;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const updateQuestion = (index, updatedQuestionData) => {
    // Max one image type question per form
    const imageQuestionsCount = formQuestions.filter((q, idx) => q.dataType === 'image' && idx !== index).length;
    if (updatedQuestionData.dataType === 'image' && imageQuestionsCount >= 1) {
      showSnackbar('Only one image question per form.', 'error'); // Inform the user about the limit
    }

    setFormQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = { ...updatedQuestions[index], ...updatedQuestionData };
      return updatedQuestions;
    });
  };

  const addQuestion = () => {
    const newQuestion = {
      text: '',
      dataType: 'text',
      mandatory: false,
      id: `question-${uuidv4()}`,
      fromVision: false,
      isDeleted: false,
    };
    setFormQuestions(prevQuestions => [...prevQuestions, newQuestion]);
  };

  const removeQuestion = (index) => {
    setFormQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      const [removedQuestion] = updatedQuestions.splice(index, 1);
      removedQuestion.isDeleted = true;
      setDeletedQuestions(prevDeleted => [...prevDeleted, removedQuestion]);
      return updatedQuestions;
    });
  };

  const moveQuestionUp = (index) => {
    if (index === 0) return;
    setFormQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      [newQuestions[index - 1], newQuestions[index]] = [newQuestions[index], newQuestions[index - 1]];
      return newQuestions;
    });
  };

  const moveQuestionDown = (index) => {
    if (index === formQuestions.length - 1) return;
    setFormQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      [newQuestions[index + 1], newQuestions[index]] = [newQuestions[index], newQuestions[index + 1]];
      return newQuestions;
    });
  };

  const hasEmptyQuestionNames = () => {
    return formQuestions.some(q => q.text.trim() === '');
  };

  const saveChanges = async () => {
    try {
      const isValidFormName = await checkForDuplicateFormName(formName);
      if (!isValidFormName) {
        setIsFormNameValid(false);
        showSnackbar(t('Snackbar.FormNameExists'), 'error');
        return;
      } else {
        setIsFormNameValid(true);
      }

      const formRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
      const updatedFormQuestions = [
        ...formQuestions.map(question => ({
          ...question,
          text: 'aud_' + question.text,
        })),
        ...deletedQuestions.map(question => ({
          ...question,
          isDeleted: true,
          text: 'aud_' + question.text,
        })),
      ];

      await updateDoc(formRef, {
        questions: updatedFormQuestions,
        formName: formName,
      });
      showSnackbar(t('Snackbar.ChangesSaved'), 'success');
      navigate(`/home`);
    } catch (error) {
      showSnackbar('Failed to update form.', 'error');
      Sentry.captureException(error);
    }
  };

  return (
    <Container component="main" maxWidth="md" sx={{ marginTop: 3 }}>
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={orgId}
        rolesAllowed={['Admin', 'Owner']}
        redirectPage='/home'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Card sx={{ boxShadow: 5 }}>
        <CardContent>
          <Typography variant="h4">
            {t('Forms.EditTitle')}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label={t('Forms.Name')}
            value={formName}
            onChange={handleFormNameChange}
            error={!isFormNameValid}
            inputProps={{ maxLength: 63 }}
            helperText={!isFormNameValid ? t('Snackbar.RestrictredCharsMessage') : ''}
            sx={{ mb: 5 }}
          />
          {Array.isArray(formQuestions) && formQuestions.map((question, index) => (
            <Question
              key={question.id} // Ensure the key is unique and stable
              index={index}
              data={question}
              dataTypes={dataTypes}
              updateQuestion={updateQuestion}
              removeQuestion={() => removeQuestion(index)}
              moveQuestionUp={() => moveQuestionUp(index)}
              moveQuestionDown={() => moveQuestionDown(index)}
            />
          ))}
          <Stack spacing={2} direction="column" sx={{ mt: 2, alignItems: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={addQuestion}
              sx={{ width: '50%', display: 'block', mx: 'auto' }}
            >
              {t('Forms.AddQuestion')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveChanges}
              disabled={
                !formName.trim() ||
                hasEmptyQuestionNames() ||
                maxImageQuestions
              }
              sx={{ width: '50%', display: 'block', mx: 'auto' }}
            >
              {t('General.SaveChanges')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}

export default EditForm;
