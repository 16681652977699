import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Button, Box, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import MyForms from '../pages/MyForms';
import MyPlots from '../pages/MyPlots';
import { useTranslation } from 'react-i18next';

function HomePage() {
    const [activePage, setActivePage] = useState('forms');
    const location = useLocation();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = params.get('activePage');
        if (page) {
            setActivePage(page);
        }
    }, [location]);

    const handlePageChange = (page) => {
        setActivePage(page);
    };

    return (
        <Container maxWidth="lg" disableGutters sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} alignItems="center" mb={2} mt={2}>
                        <Typography variant="h3" gutterBottom align={isMobile ? 'center' : 'left'} sx={{ ml: isMobile ? 2 : 3 }}>
                            {t('Navbar.Home')}
                        </Typography>
                        <Divider
                            sx={{
                                width: isMobile ? '1px' : '80%',
                                height: isMobile ? '40px' : '1px',
                                mb: isMobile ? 0 : 2,
                                ml: isMobile ? 2 : 0,
                                borderWidth: 5,
                                borderColor: 'secondary.main',
                                borderRadius: 3
                            }}
                            orientation={isMobile ? 'vertical' : 'horizontal'}
                        />
                        <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} alignItems={isMobile ? 'center' : 'flex-start'} ml={isMobile ? 2 : 0}>
                            <Button
                                variant={activePage === 'forms' ? 'outlined' : 'contained'}
                                onClick={() => handlePageChange('forms')}
                                sx={{ mb: isMobile ? 0 : 2, mr: isMobile ? 1 : 0 }}
                                fullWidth={!isMobile}
                            >
                                {t('Navbar.MyForms')}
                            </Button>
                            <Button
                                variant={activePage === 'plots' ? 'outlined' : 'contained'}
                                onClick={() => handlePageChange('plots')}
                                fullWidth={!isMobile}
                            >
                                {t('Navbar.MyPlots')}
                            </Button>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={10}>
                    <Container maxWidth="lg" disableGutters>
                        {activePage === 'forms' && <MyForms />}
                        {activePage === 'plots' && <MyPlots />}
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default HomePage;
