import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  MenuItem,
  ListItemButton,
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  Divider,
} from '@mui/material';
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  limit
} from 'firebase/firestore';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link as RouterLink } from 'react-router-dom';
import audonisTitle from '../images/audonisTitle.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import * as Sentry from "@sentry/react";

function NavBar() {
  const { t, i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:1020px)');
  const [language, setLanguage] = useState(i18n.language);
  const [notifications, setNotifications] = useState([]);
  const [defFirestore, setDefFirestore] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const { firestore, storageBucket } = getFirestoreLocation('(default)');
      setDefFirestore(firestore);
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setIsLoggedIn(!!user);
      });
      return () => unsubscribe();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (isLoggedIn && auth.currentUser) {
        const user = auth.currentUser;

        const notificationsRef = collection(defFirestore, `users/${user.uid}/notifications`);
        const q = query(notificationsRef, orderBy('date', 'desc'), limit(6));
        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          const notificationsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotifications(notificationsData);
        });
        return () => unsubscribeSnapshot();
      } else {
        setNotifications([]);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [isLoggedIn]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    setLanguageMenuOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
    setLanguageMenuOpen(false);
  };

  const handleNotificationsMenuOpen = (event) => {
    setNotificationsMenuOpen(true);
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsMenuOpen(false);
    setNotificationsAnchorEl(null);
  };

  const handleNotificationClick = () => {
    navigate('/my-notifications');
    handleNotificationsMenuClose();
  };

  const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <List>
          {isLoggedIn && (
              <ListItemButton component={RouterLink} to="/home">
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary={t('Navbar.Home')} />
              </ListItemButton>
          )}
          <ListItemButton component={RouterLink} to="/products">
            <ListItemIcon><ShoppingBasketIcon /></ListItemIcon>
            <ListItemText primary={t('Navbar.Products')} />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/support">
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary={t('Navbar.Support')} />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/contact-us">
            <ListItemIcon><ContactPageIcon /></ListItemIcon>
            <ListItemText primary={t('Navbar.ContactUs')} />
          </ListItemButton>
          {isLoggedIn && (
              <>
                <ListItemButton component={RouterLink} to="/account">
                  <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                  <ListItemText primary={t('Navbar.Account')} />
                </ListItemButton>
              </>
          )}
          {!isLoggedIn && (
              <ListItemButton component={RouterLink} to="/login">
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                <ListItemText primary={t('Auth.LoginButton')} />
              </ListItemButton>
          )}
        </List>
      </Box>
  );

  return (
      <AppBar
          position="fixed"
          sx={{
            borderRadius: 2,
            maxWidth: '95vw',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            boxSizing: 'border-box',
            m: '0 auto',
            mt: 0.5,
          }}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <RouterLink to="/">
            <img
                src={audonisTitle}
                alt="audonis logo"
                style={{
                  width: 'auto', height: '40px', cursor: 'pointer', marginRight: '50px',
                }}
            />
          </RouterLink>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isMobile ? (
                <>
                  <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
            ) : (
                <>
                  {isLoggedIn && (
                      <Button color="inherit" sx={{ mx: 3 }} component={RouterLink} to="/home" startIcon={<HomeIcon />}>
                        {t('Navbar.Home')}
                      </Button>
                  )}
                  <Button color="inherit" component={RouterLink} to="/products" startIcon={<ShoppingBasketIcon />}>
                    {t('Navbar.Products')}
                  </Button>
                  <ClickAwayListener onClickAway={handleMenuClose}>
                    <Box
                        onMouseEnter={handleMenuOpen}
                        onMouseLeave={handleMenuClose}
                        sx={{ display: 'inline-block', mx: 3 }}
                    >
                      <Button
                          color="inherit"
                          startIcon={<InfoIcon />}
                          aria-haspopup="true"
                          component={RouterLink}
                          to="/support"
                      >
                        {t('Navbar.Support')}
                      </Button>
                      <Popper
                          open={open}
                          anchorEl={anchorEl}
                          role={undefined}
                          transition
                          disablePortal
                          placement="bottom-start"
                          modifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ]}
                      >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                              <Paper
                                  sx={{
                                    bgcolor: 'primary.main',
                                    color: 'primary.contrastText',
                                    borderColor: 'primary.border',
                                    border: '2px solid',
                                    borderRadius: 2,
                                  }}
                              >
                                <MenuList sx={{ borderRadius: 7 }}>
                                  {[
                                    { to: '/support', label: 'User Guides' },
                                  ].map((item, index) => (
                                      <MenuItem
                                          key={index}
                                          component={RouterLink}
                                          to={item.to}
                                          sx={{ color: 'primary.contrastText' }}
                                      >
                                        {item.label}
                                      </MenuItem>
                                  ))}
                                </MenuList>
                              </Paper>
                            </Grow>
                        )}
                      </Popper>
                    </Box>
                  </ClickAwayListener>
                  <Button color="inherit" component={RouterLink} to="/contact-us" startIcon={<ContactPageIcon />}>
                    {t('Navbar.ContactUs')}
                  </Button>
                  {!isLoggedIn && (
                      <>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              mx: 2,
                              borderWidth: '2px', // Adjust the thickness here
                              borderColor: 'primary.contrastText', // Adjust the color here
                            }}
                        />
                        <Button color="inherit" component={RouterLink} to="/login" startIcon={<AccountCircleIcon />}>
                          {t('Auth.LoginButton')}
                        </Button>
                      </>
                  )}
                  {isLoggedIn && (
                      <Button color="inherit" component={RouterLink} to="/account" sx={{ mx: 3 }} startIcon={<AccountCircleIcon />}>
                        {t('Navbar.Account')}
                      </Button>
                  )}
                </>
            )}

            {isLoggedIn && (
                <>
                  <IconButton
                      color="inherit"
                      aria-haspopup="true"
                      onClick={handleNotificationsMenuOpen}
                  >
                    <Badge badgeContent={notifications.length} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <Popper
                      open={notificationsMenuOpen}
                      anchorEl={notificationsAnchorEl}
                      role={undefined}
                      transition
                      disablePortal
                      placement="bottom-end"
                      modifiers={[
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                  >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
                            }}
                        >
                          <Paper
                              sx={{
                                bgcolor: 'primary.main',
                                color: 'primary.contrastText',
                                borderColor: 'primary.border',
                                border: '2px solid',
                                borderRadius: 2,
                              }}
                          >
                            <ClickAwayListener onClickAway={handleNotificationsMenuClose}>
                              <MenuList>
                                {notifications.length > 0 ? (
                                    notifications.reduce((acc, notification, index) => {
                                      acc.push(
                                          <MenuItem
                                              key={notification.id}
                                              onClick={() => handleNotificationClick(notification)}
                                          >
                                            {notification.title}
                                          </MenuItem>
                                      );
                                      if (index < notifications.length - 1) {
                                        acc.push(<Divider key={`divider-${notification.id}`} />);
                                      }
                                      return acc;
                                    }, [])
                                ) : (
                                    <MenuItem>{t('Navbar.NoNotifications')}</MenuItem>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                    )}
                  </Popper>
                </>
            )}

            <IconButton
                color="inherit"
                aria-haspopup="true"
                onClick={handleLanguageMenuOpen}
            >
              <LanguageIcon />
            </IconButton>
            <Popper
                open={languageMenuOpen}
                anchorEl={languageAnchorEl}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ]}
            >
              {({ TransitionProps, placement }) => (
                  <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                            placement === 'bottom-end' ? 'right top' : 'right bottom',
                      }}
                  >
                    <Paper
                        sx={{
                          bgcolor: 'primary.main',
                          color: 'primary.contrastText',
                          position: 'relative',
                          borderColor: 'primary.border',
                          border: '2px solid',
                          borderRadius: 2,
                        }}
                    >
                      <ClickAwayListener onClickAway={handleLanguageMenuClose}>
                        <MenuList>
                          {[
                            { code: 'en', label: 'English' },
                            { code: 'sp', label: 'Español' },
                            { code: 'fr', label: 'Français' },
                            { code: 'pt', label: 'Português' },
                            { code: 'ar', label: 'عربي' },
                            { code: 'hi', label: 'हिंदी' },
                          ].map((lang, index) => (
                              <MenuItem
                                  key={index}
                                  onClick={() => handleLanguageChange(lang.code)}
                                  sx={{ color: 'primary.contrastText' }}
                              >
                                {lang.label}
                              </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
              )}
            </Popper>
          </Box>
          <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
          >
            {drawer}
          </Drawer>
        </Toolbar>
      </AppBar>
  );

}

export default NavBar;
