import React from 'react';
import { Container, Typography } from '@mui/material';
import Footer from '../components/Footer'

function TermsAndConditions() {
  return (
    <Container maxWidth={false} disableGutters>
    <Container>
      <Typography variant="h3" gutterBottom style={{ marginTop: '20px', marginBottom: '20px' }}>
        Terms and Conditions of Use
      </Typography>
      <Typography variant="h6" gutterBottom>
        Acceptance of the Website Terms and Conditions of Use
      </Typography>
      <Typography variant="body1" paragraph>
        These website terms and conditions of use for AUDONIS.CA constitute a legal agreement and are entered into by and between you and Audonis Inc. ("Company," "we," "us," "our"). The following terms and conditions[, together with any documents and/or additional terms they expressly incorporate by reference] ([collectively, ] these "Terms and Conditions"), govern your access to and use of, including any content, functionality, and [services] offered on or through AUDONIS.CA (the "Website").
      </Typography>
      <Typography variant="body1" paragraph>
        BY USING THE WEBSITE OR BY CLICKING TO ACCEPT THE TERMS AND CONDITIONS, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND CONDITIONS INCORPORATED HEREIN BY REFERENCE].] [IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MUST NOT ACCESS OR USE THE WEBSITE.
      </Typography>
      <Typography variant="body1" paragraph>
        By using this Website, you represent and warrant that you are of the legal age of majority under applicable law to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Modifications to the Terms and Conditions and to the Website
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website. You agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.
      </Typography>
      <Typography variant="body1" paragraph>
        The information and material on this Website may be changed, withdrawn, or terminated at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Website is restricted to users or unavailable at any time or for any period.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Your Use of the Website and Account Set-Up and Security
      </Typography>
      <Typography variant="body1" paragraph>
        The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.
      </Typography>
      <Typography variant="body1" paragraph>
        The safety and security of your information also depends on you. Users are responsible for obtaining their own access to the Website. Users are required to ensure that all persons who access the Website through a user's internet connection are aware of these Terms and Conditions and comply with them. The Website, including content or areas of the Website, may require user registration. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete.
      </Typography>
      <Typography variant="body1" paragraph>
        Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
      </Typography>
      <Typography variant="body1" paragraph>
        Your provision of registration information and any submissions you make to the Website through any functionality such as applications, chat rooms, e-mail, message boards, personal, or interest group web pages, profiles, forums, bulletin boards, and other such functions (collectively, "Interactive Functions") constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy.
      </Typography>
      <Typography variant="body1" paragraph>
        Any username, password, or any other piece of information chosen by you, or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this Website or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.
      </Typography>
      <Typography variant="body1" paragraph>
        You are prohibited from attempting to circumvent and from violating the security of this Website, including, without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or breaching the security and/or authentication measures which are not authorized; (c) restricting, disrupting, or disabling service to users, hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting Website owner's ability to monitor the Website; (f) using any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (g) introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h) attacking the Website via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing, or crashing; and (i) otherwise attempting to interfere with the proper working of the Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Intellectual Property Rights and Ownership
      </Typography>
      <Typography variant="body1" paragraph>
        You understand and agree that the Website and its entire contents, features, and functionality, including, but not limited to, all information, software, code, data text, displays, graphics, photographs, images, video, audio, music, broadcast, design, presentation, website layout, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including, without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.
      </Typography>
      <Typography variant="body1" paragraph>
        The Company name, the Company logo, and all related names, logos, product and service names, designs, images, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images, and slogans mentioned, or which appear on this Website are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or other laws and could subject the infringer to legal action.
      </Typography>
      <Typography variant="body1" paragraph>
        You may only use the Website for your personal and non-commercial use. You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, in any form or medium whatsoever except:
        (a) your computer and browser may temporarily store or cache copies of materials being accessed and viewed;
        (b) a reasonable number of copies for personal use only may be printed keeping any proprietary notices thereon, which may only be used for non-commercial and lawful personal use and not for further reproduction, publication, or distribution of any kind on any medium whatsoever;[/and]
        (c) one single user copy may be downloaded with any proprietary notices intact, for your own personal, non-commercial use, conditional on your agreement to be bound by our end user licence agreement for such downloads.
      </Typography>
      <Typography variant="body1" paragraph>
        Users are not permitted to modify copies of any materials from this Website nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.
      </Typography>
      <Typography variant="body1" paragraph>
        If you print off, copy, or download any part of our Website in breach of these Terms and Conditions, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the Website or to any content on the Website, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may infringe or violate copyright, trademark, and other intellectual property or other proprietary laws.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Conditions of Use and User Submissions and Site Content Standards
      </Typography>
      <Typography variant="body1" paragraph>
        As a condition of your access and use of the Website, you agree that you may use the Website only for lawful purposes and in accordance with these Terms and Conditions.
      </Typography>
      <Typography variant="body1" paragraph>
        The following site content standards apply to any and all content, material, and information a user submits, posts, publishes, displays, or transmits (collectively, "submit") to the website or to other users or persons (collectively, "User Submissions") and any and all Interactive Functions. Any and all User Submissions must also comply with all applicable federal, provincial, local, and international laws, regulations, and terms of service.
      </Typography>
      <Typography variant="body1" paragraph>
        Without limiting the foregoing, you warrant and agree that your use of the Website and any User Submissions shall not:
        (a) In any manner violate any applicable federal, provincial, local, or international law or regulation including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and Conditions.
        (d) In any manner violate the terms of use of any third-party website that is linked to the Website, including but not limited to, any third-party social media website.
        (e) Include or contain any material that is exploitive, obscene, harmful, threatening, abusive, harassing, hateful, defamatory, sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age or other such legally prohibited ground or be otherwise objectionable, such determination to be made in Company's sole discretion.
        (f) Involve stalking, attempting to exploit or harm any individual (including minors) in any way by exposing them to inappropriate content or otherwise or ask for personal information as prohibited under applicable laws, regulations, or code.
        (g) Involve, provide, or contribute any false, inaccurate, or misleading information.
        (h) Include sending, knowingly receiving, uploading, downloading, using, or reusing any material that does not comply with the Conditions of Use and User Submissions and Site Content Standards.
        (i) Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).
        (j) Transmit, or procure the sending of, any advertisements or promotions [without our prior written consent], sales, or encourage any other commercial activities, including, without limitation, any "spam", "junk mail", "chain letter", contests, sweepstakes and other sales promotions, barter, or advertising or any other similar solicitation.
        (k) Encourage any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.
        (l) Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm any other person.
        (m) Promote any illegal activity, or advocate, promote, or assist any unlawful act.
        (n) Give the impression that they originate from or are endorsed by us or any other person or entity, if this is not the case.
      </Typography>
      <Typography variant="h6" gutterBottom>
        User Submissions: Grant of Licence
      </Typography>
      <Typography variant="body1" paragraph>
        The Website may contain Interactive Functions allowing User Submissions on or through the Website.
      </Typography>
      <Typography variant="body1" paragraph>
        None of the User Submissions you submit to the Website will be subject to any confidentiality by the Company. By providing any User Submission to the Website, you grant us and [our affiliates and service providers, and each of their and] our [respective] licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive licence to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You further waive any moral rights or other rights of authorship as a condition of submitting any User Submission.
      </Typography>
      <Typography variant="body1" paragraph>
        By submitting the User Submissions, you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the licence hereof to us and [our affiliates and service providers, and each of their and] our [respective] licensees, successors, and assigns to the User Submissions and comply with these Terms and Conditions.
      </Typography>
      <Typography variant="body1" paragraph>
        You represent and warrant that all User Submissions comply with applicable laws and regulations and the Conditions of Use and User Submissions and Site Content Standards set out in these Terms and Conditions.
      </Typography>
      <Typography variant="body1" paragraph>
        You understand and agree that you, not the Company [nor Company's parent, subsidiaries, affiliates nor their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, or successors], are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable, including to any third party, for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Site Monitoring and Enforcement, Suspension, and Termination
      </Typography>
      <Typography variant="body1" paragraph>
        Company has the right, without provision of notice to:
      </Typography>
        <ul>
          <li>Remove or refuse to post on the Website any User Submissions for any or no reason in our sole discretion.</li>
          <li>At all times, take such actions with respect to any User Submission deemed necessary or appropriate in our sole discretion, including, without limitation, for violating these Terms and Conditions.</li>
          <li>Take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Website. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website.</li>
          <li>Terminate or suspend your access to all or part of the Website for any or no reason, including, without limitation, any violation of these Terms and Conditions.</li>
        </ul>
      <Typography variant="body1" paragraph>
      </Typography>
      <Typography variant="body1" paragraph>
        YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
      </Typography>
      <Typography variant="body1" paragraph>
        We have no obligation, nor any responsibility to any party to monitor the Website or its use, and do not and cannot undertake to review material that you or other users submit to the Website. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party, subject to applicable laws.
      </Typography>
      <Typography variant="h6" gutterBottom>
        No Reliance
      </Typography>
      <Typography variant="body1" paragraph>
        The content on our Website is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain more specific or professional advice before taking, or refraining from, any action or inaction on the basis of the content on our site.
      </Typography>
      <Typography variant="body1" paragraph>
        Although we make reasonable efforts to update the information on our Website, we make no representations, warranties, or guarantees, whether express or implied, that the content on our Website is accurate, complete, or up to date. Your use of the Website is at your own risk and neither the Company has no responsibility or liability whatsoever for your use of this Website.
      </Typography>
      <Typography variant="body1" paragraph>
        This Website may include[s] content provided by third parties, including from other users and third-party licensors. All statements and/or opinions expressed in any such third-party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. The Company has no responsibility or liability whatsoever to you, or any third party, for the content or accuracy of any third-party materials.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        By submitting your personal information and using our Website, you consent to the collection, use, reproduction, hosting, transmission, and disclosure of any such user content submissions in compliance with our Privacy Policy, as we deem necessary for use of the Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Third-Party Websites
      </Typography>
      <Typography variant="body1" paragraph>
        For your convenience, this Website may provide links or pointers to third-party sites. We make no representations about any other websites that may be accessed from this Website. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third-party sites.
      </Typography>
      <Typography variant="body1" paragraph>
        Our Website must not be framed on any other site, nor may you create a link to any part of our Website other than the homepage. We reserve the right to withdraw linking permission without notice. The website in which you are linking must comply in all respects with the Conditions of Use and User Submissions and Site Content Standards. You agree to cooperate with us in causing any unauthorized framing or linking to immediately stop.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Geographic Restrictions
      </Typography>
      <Typography variant="body1" paragraph>
        The owner of the Website is based in Ontario in Canada. If you access the Website from outside Canada, you do so at your own risk and you are responsible for compliance with local laws of your jurisdiction.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </Typography>
      <Typography variant="body1" paragraph>
        NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
      </Typography>
      <Typography variant="body1" paragraph>
        WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF- SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Limitation on Liability
      </Typography>
      <Typography variant="body1" paragraph>
        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT, BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the Company, its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable legal fees) arising out of or relating to your breach of these Terms and Conditions or your use of the Website, including, but not limited to, your User Submissions, third-party sites, any use of the Website's content, services, and products other than as expressly authorized in these Terms and Conditions.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Refund
      </Typography>
      <Typography variant="body1" paragraph>
        If for any reason you decide to cancel your account within 30 days of your payment made directly
        to AUDONIS, you are eligible for a refund. Please submit your request to support@audonis.ca.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Governing Law and Choice of Forum
      </Typography>
      <Typography variant="body1" paragraph>
        The Website and these Terms and Conditions will be governed by and construed in accordance with the laws of Province of Ontario and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle, or rule (whether of the laws of the Province of Ontario or any other jurisdiction) and notwithstanding your domicile, residence, or physical location.
      </Typography>
      <Typography variant="body1" paragraph>
        Any action or proceeding arising out of or relating to this Website and under these Terms and Conditions will be instituted in the courts of the Province of Ontario and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Waiver
      </Typography>
      <Typography variant="body1" paragraph>
        No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from these Terms and Conditions operates, or may be construed, as a waiver thereof; and no single or partial exercise of any right, remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Severability
      </Typography>
      <Typography variant="body1" paragraph>
        If any term or provision of these Terms and Conditions is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other jurisdiction.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Entire Agreement
      </Typography>
      <Typography variant="body1" paragraph>
        The Terms and Conditions constitute the sole and entire agreement between you and Audonis Inc. regarding the Website and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding such subject matter.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Reporting and Contact
      </Typography>
      <Typography variant="body1" paragraph>
        This website is operated by Audonis Inc. 2677 Jamaica Crescent, Windsor, ON. Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to the Company at audonis@audonis.ca. All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to audonis@audonis.ca.
      </Typography>
      <Typography variant="body1" paragraph>
        2024
      </Typography>
    </Container>
    <Footer fixed={false} />
    </Container>
  );
}

export default TermsAndConditions;
