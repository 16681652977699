import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const CookieConsentBanner = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 2,
        maxWidth: 600,
        width: '90%',
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        borderRadius: 5,
        zIndex: 9999,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="body1">
        {t('Home.CookieConsentMessage')}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Button variant="contained" color="secondary" onClick={handleAccept}>
            {t('MyOrganisations.Accept')}
          </Button>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default CookieConsentBanner;
