import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Divider, MenuItem, Select, InputLabel, FormControl, Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from '../components/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Footer from '../components/Footer';
import * as Sentry from '@sentry/react';

function ContactUs() {
  const { showSnackbar } = useSnackbar();
  const [contact, setContact] = useState({
    name: '',
    email: '',
    comment: '',
    subject: 'General Questions',
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!executeRecaptcha) {
        showSnackbar(t('Snackbar.PleaseTryAgainLater'), 'error');
        setRecaptchaReady(false);
      } else {
        setRecaptchaReady(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [executeRecaptcha, showSnackbar, t]);

  const handleChange = (e) => {
    setContact({ ...contact, [e.target.id]: e.target.value });
  };

  const handleSubjectChange = (e) => {
    setContact({ ...contact, subject: e.target.value });
  };

  const handleSubmit = async (e) => {


    try {
      e.preventDefault();

      if (!contact.email || !contact.comment) {
        showSnackbar(t('Snackbar.MandatoryMessage'), 'error');
        return;
      }

      setLoading(true);

      if (!recaptchaReady) {
        showSnackbar(t('Snackbar.PleaseTryAgainLater'), 'error');
        setLoading(false);
        return;
      }


      const token = await executeRecaptcha('contact_us');
      const response = await fetch(import.meta.env.VITE_FUNCTION_LINK_SEND_EMAIL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...contact, token }),
      });

      if (response.ok) {
        showSnackbar(t('Snackbar.MessageSent'), 'success');
        setContact({
          name: '',
          email: '',
          comment: '',
          subject: 'General Questions',
        });
      } else {
        showSnackbar(t('Snackbar.PleaseTryAgainLater'), 'error');
      }
    } catch (error) {
      showSnackbar(t('Snackbar.PleaseTryAgainLater'), 'error');
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <Container maxWidth={false} disableGutters={true} sx={{ padding: 0, margin: 0, width: '100%' }}>
        <Container sx={{ mt: 3 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h3" gutterBottom>
              {t('Navbar.ContactUs')}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {t('ContactUs.Desc')}
            </Typography>
            <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
          </Box>

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box mb={3}>
              <TextField id="name" label={t('ContactUs.YourName')} variant="outlined" fullWidth value={contact.name} onChange={handleChange} disabled={loading} />
            </Box>
            <Box mb={3}>
              <TextField id="email" label={t('ContactUs.YourEmail')} variant="outlined" fullWidth value={contact.email} onChange={handleChange} disabled={loading} required />
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                <InputLabel id="subject-label" sx={{ top: '-10px' }}>Subject</InputLabel>
                <Select
                    labelId="subject-label"
                    id="subject"
                    value={contact.subject}
                    onChange={handleSubjectChange}
                    disabled={loading}
                >
                  <MenuItem value="General Questions">{t('ContactUs.GeneralQuestions')}</MenuItem>
                  <MenuItem value="Feature Request">{t('ContactUs.FeatureRequest')}</MenuItem>
                  <MenuItem value="Refund">{t('ContactUs.RefundRequest')}</MenuItem>
                  <MenuItem value="Enterprise Solution">{t('ContactUs.EnterpriseSolutions')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mb={3}>
              <TextField id="comment" label={t('ContactUs.YourMessage')} variant="outlined" fullWidth multiline rows={4} value={contact.comment} onChange={handleChange} disabled={loading} required />
            </Box>
            <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ mb: isMobile ? 50 : 35 }}>
              {loading ? <CircularProgress size={24} /> : t('ContactUs.SendMessage')}
            </Button>
          </form>
        </Container>
        <Footer fixed={true} />
      </Container>
  );
}

export default function ContactUsWrapper() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <>
        <style>
          {`
          .grecaptcha-badge {
            right: 2% !important;
            bottom: ${isMobile ? '250px' : '100px'} !important;
            left: auto !important;
            transition: transform 0.3s ease-in-out;
          }
        `}
        </style>
        <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
          <ContactUs />
        </GoogleReCaptchaProvider>
      </>
  );
}
