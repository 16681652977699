import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Typography, Container, Grid, Stack, useMediaQuery,
  Paper, Divider
} from '@mui/material';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import farmerCollectingData from '../images/farmerCollectingData.png';
import builtForFarmers from '../images/builtForFarmers.png';
import beEffortless from '../images/beEffortless.png';
import pricedAccessible from '../images/pricedAccessible.png';
import googlePlayBadge from '../images/googlePlayBadge.png';
import appleBadge from '../images/appleBadge.svg';
import theme from '../Theme';
import HomeShowcase from '../components/HomeShowcase';
import HomeGetStarted from '../components/HomeGetStarted';
import SubscriptionTiers from "../components/SubscriptionTiers";
import { ParallaxProvider } from 'react-scroll-parallax';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import HomeHighlight from '../components/HomeHighlight';
import CookieConsentBanner from '../components/CookieConsentBanner';

function Home() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hideDividers = useMediaQuery('(max-width:899px)');
  const mainTitleSpring = useSpring({ opacity: 1, transform: 'translateY(0px)', from: { opacity: 0, transform: 'translateY(-50px)' }, delay: 200, config: { duration: 500 } });
  const secondaryTitleSpring = useSpring({ opacity: 1, transform: 'translateY(0px)', from: { opacity: 0, transform: 'translateY(-50px)' }, delay: 400, config: { duration: 500 } });
  const buttonSpring = useSpring({ opacity: 1, transform: 'scale(1)', from: { opacity: 0, transform: 'scale(0.9)' }, delay: 600, config: { duration: 500 } });
  const cardSpring = useSpring({ opacity: 1, transform: 'scale(1)', from: { opacity: 0, transform: 'scale(0.95)' }, delay: 800, config: { duration: 500 } });

  // Animations for images
  const leftImageSpring = useSpring({ opacity: 1, transform: 'translateX(0px)', from: { opacity: 0, transform: 'translateX(-100px)' }, delay: 500, config: { duration: 1000 } });

  // Intersection observer hooks
  const [showcaseRef, showcaseInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [subscriptionTiersRef, subscriptionTiersInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [getStartedRef, getStartedInView] = useInView({ triggerOnce: true, threshold: 0.2 });

  const showcaseSpring = useSpring({ opacity: showcaseInView ? 1 : 0, transform: showcaseInView ? 'translateY(0px)' : 'translateY(50px)', config: { duration: 1000 } });
  const subscriptionTiersSpring = useSpring({ opacity: subscriptionTiersInView ? 1 : 0, transform: subscriptionTiersInView ? 'translateY(0px)' : 'translateY(50px)', config: { duration: 1000 } });
  const getStartedSpring = useSpring({ opacity: getStartedInView ? 1 : 0, transform: getStartedInView ? 'translateY(0px)' : 'translateY(50px)', config: { duration: 1000 } });

  return (
    <Container maxWidth={false} disableGutters={true} sx={{padding:'0', margin: '0'}}>
      <ParallaxProvider>
      <Grid container sx={{padding:'0', margin: '0', paddingTop: 5, justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row'}}>
          {/* Left Image */}
          <Grid item xs={12} md={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: hideDividers ? '0' : '20px' }}>
            <animated.div style={leftImageSpring}>
              <img src={farmerCollectingData} alt="farmer Collecting Data" style={{ maxWidth: hideDividers ? '80vw' : '40vw', height: 'auto', borderRadius: 10 }} />
            </animated.div>
          </Grid>
          {hideDividers ? null : (
            <Grid item xs={1} md={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: '71vh',
                  borderRightWidth: '3px', // Adjust thickness here
                  borderColor: 'primary.main',
                  borderRightStyle: 'solid', // Ensure the border style is solid
                }}
              />
            </Grid>
          )}
          {/* Signup on right side */}
          <Grid item xs={12} md={5} container sx={{ justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: isMobile ? '20px' : '0' }}>
            <animated.div style={mainTitleSpring}>
              <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }} variant="h2" color="textPrimary" paragraph>
                {t('Home.MainTitle1')}
              </Typography>
              <Typography sx={{ marginBottom: 2, fontWeight: 'bold' }} variant="h2" color="textPrimary" paragraph>
                {t('Home.MainTitle2')}
              </Typography>
            </animated.div>
            <animated.div style={secondaryTitleSpring}>
              <Typography variant="h6" color="textSecondary" paragraph>
                {t('Home.SecondaryTitle')}
              </Typography>
            </animated.div>

            <animated.div style={cardSpring}>
              <Paper
                elevation={3}
                style={{
                  width: hideDividers ? '80vw' : '25vw',
                  borderRadius: '10px',
                  padding: '20px',
                  height: 'auto',
                  flexDirection: 'column',
                  border: `2px solid ${theme.palette.primary.light}`,
                  marginBottom: '12px'
                }}
              >
                <Stack spacing={4} style={{ height: '100%', justifyContent: 'space-evenly' }}>
                  <Link to="/signup" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <animated.div style={{ ...buttonSpring, width: '95%' }}>
                      <Button color="primary" variant="contained" size="large" fullWidth style={{ padding: '16px', fontSize: '1.3rem', fontWeight: 'bold' }}>
                        {t('Auth.SignUpButton')}
                      </Button>
                    </animated.div>
                  </Link>
                  <Typography variant="h6" style={{ alignSelf: 'center', paddingTop: 5 }}>
                    {t('Home.SignUpMessage')}
                  </Typography>
                </Stack>
              </Paper>
            </animated.div>
          </Grid>
        </Grid>

        <HomeHighlight 
          builtForFarmers={builtForFarmers} 
          beEffortless={beEffortless} 
          pricedAccessible={pricedAccessible} 
        />

        <HomeShowcase />

        <Grid item xs={12} ref={subscriptionTiersRef}  sx={{ backgroundColor: 'primary.contrastText', paddingBottom: '16px' }}>
          <animated.div style={subscriptionTiersSpring}>
            <SubscriptionTiers buttonHandlers={null} currentPlan={"None"} countryCode={"US"} loggedIn={""} />
          </animated.div>
        </Grid>

        <Grid 
          item xs={12} 
          style={{
            backgroundColor: '#333',
            color: theme.palette.primary.contrastText,
            textAlign: 'center',
        }}
          ref={getStartedRef}
        >
          <animated.div style={getStartedSpring}>
              <HomeGetStarted />
          </animated.div>
        </Grid>
        <Grid 
          item 
          xs={12} 
          container 
          justifyContent="space-evenly"
          alignItems="center"
          sx = {{ height: '10vh',backgroundColor: 'primary.contrastText'}}
        >
          <a href="https://play.google.com/store/apps/details?id=ca.audonis" target="_blank" rel="noopener noreferrer">
            <img src={googlePlayBadge} alt="Get it on Google Play" style={{ height: '60px' }} />
          </a>
          <a href="https://apps.apple.com/ca/app/audonis/id6503368257" target="_blank" rel="noopener noreferrer">
            <img src={appleBadge} alt="Get it on Apple Store" style={{ height: '60px' }} />
          </a>
        </Grid>
      </ParallaxProvider>
      <Footer fixed={false} />
      <CookieConsentBanner />
    </Container>
  );
}

export default Home;
