import React, { useState, useEffect } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, Box, CircularProgress,
} from '@mui/material';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';

function OrganizationsDropdown({
  selectedOrg,
  setSelectedOrg,
  setLoading = () => {},
  loading = false,
  }) {
  const [organizations, setOrganizations] = useState([]);
  const [organizationNames, setOrganizationNames] = useState({});
  const { firestore, storageBucket } = getFirestoreLocation('(default)');
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);

    // Observer for changes in the user's sign-in state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid; // Get the current user's ID
        fetchOrganizations(userId);
      } else {
        setLoading(false);
        // Handle unauthenticated state, like redirecting to login page
      }
    });

    // Asynchronous function to fetch organizations
    const fetchOrganizations = async (userId) => {
      const orgs = [];
      const orgNames = {};
      const orgsRef = collection(firestore, `users/${userId}/organizations`);

      const querySnapshot = await getDocs(orgsRef);

      for (const orgDoc of querySnapshot.docs) {
        const orgData = orgDoc.data();
        if (orgData.role !== 'Invited') {
          const orgNamesRef = doc(firestore, `organizations/${orgDoc.id}`);
          const docSnap = await getDoc(orgNamesRef);

          if (docSnap.exists()) {
            const orgName = docSnap.data().name;
            orgs.push(orgDoc.id);
            orgNames[orgDoc.id] = orgName;
          } else {
            console.log(`No document found for ID: ${orgDoc.id}`);
          }
        }
      }

      setOrganizations(orgs);
      setOrganizationNames(orgNames);
      setLoading(false);

      // Set the first organization as selected if the array is not empty
      if (orgs.length > 0) {
        setSelectedOrg(orgs[0]);
      }
    };

    // Clean up the observer
    return () => unsubscribe();
  }, [setLoading]);

  // Conditional rendering based on the `loading` prop passed from the parent
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <FormControl sx={{ width: '25%', maxWidth: 300, minWidth: 200 }}>
        <InputLabel id="organization-dropdown-label">{t('Navbar.Organisations')}</InputLabel>
        <Select
          labelId="organization-dropdown-label"
          id="organizationDropdown"
          value={selectedOrg}
          label="Organization"
          onChange={(e) => setSelectedOrg(e.target.value)}
        >
          <MenuItem value="">
            <em>{t('General.SelectOrganisation')}</em>
          </MenuItem>
          {organizations.map((org) => (
            <MenuItem key={org} value={org}>
              {organizationNames[org]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default OrganizationsDropdown;
