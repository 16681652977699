import React from 'react';
import {
  Container, 
  Typography, 
  List, 
  ListItem, 
  Box, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import createFormLabels from '../../images/createFormLabels.png';
import homeForms from '../../images/homeFormSupport.png';
import editForms from '../../images/editFormSupport.png';
import formEntry from '../../images/formEntrySupport.png';

function SupportForms() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          Forms User Guide
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Creating a Form</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Here you are able to create forms to assist in managing your everyday activities whether it is trials, health and safety, or daily maintenance.
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary='Select "Add Form" which will take you to the "Create Forms Page".' />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={createFormLabels} alt="Create Form Labels" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            {['(1) Select the organization that this form will belong to from this dropdown.',
              '(2) Enter your form name here.',
              '(3) We can select from many different data types: text, number, date, multiple choice, and dropdown.',
              '(3) Text is any characters(numbers and letters) ex: apple, pepper35, 12343..',
              '(3) Number is a number, potentially with a decimal point ex: 104, 15.4, 0.1443',
              '(3) Date opens a calender and lets us choose a date.',
              '(3) Dropdown allows us to select one option from the dropdown.',
              '(3) Multiple choice allows us to select one or more options from the list.',
              '(4) Checking this box off will mean this question must be filled in to enter the data.',
              '(5) Click "Add Question" to make another question for the form.'
            ].map((text, index) => (
              <ListItem key={index}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Editing a Form</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            If you want to make changes to a form, go to the Home page and select the pencil icon on the desired form.
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={homeForms} alt="Home Forms" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='We can edit the form name, question names and the data types.' />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={editForms} alt="Edit Forms" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='We can change any information on any question, add new questions, or delete existing ones.' />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Using the Form</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Entering data on the form
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary='We can enter data on either the website or the mobile app.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Go to My Forms and select the form you want to begin entering data for.' />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={formEntry} alt="Form Entry" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary='Enter data then press submit when you are ready. If a question has an asterisk (*) then that means it is required, and must be filled in before we can click Submit.' />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Viewing Form Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            View your form data easily
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary='Go to My Forms and click view data on the form you want to view' />
            </ListItem>
            <ListItem>
              <ListItemText primary='We can hide and show columns, sort data, and filter how we like.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='If we want to view the data in excel we can export it as a csv.' />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default SupportForms;
