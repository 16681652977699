import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem, Box, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import signUp from '../../images/signupSupport.png';
import forgetPassword1 from '../../images/forgetPasswordSupport1.png';
import forgetPassword2 from '../../images/forgetPasswordSupport2.png';

function SupportGettingStarted() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          Getting Started User Guide
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Sign Up</Typography>
        </AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <iframe 
            width="560"
            height="315"
            src="https://www.youtube.com/embed/_rRhGfNUHQk?si=Nu1tPa_j80Lxp1TF"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </Box>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We wanted to make getting started quick and easy.
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2   } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={signUp} alt="Sign Up" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="To sign up all you need is your email and the password for that account" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Your password must be at least 8 characters, include one symbol, number, and capital letter." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Once you click 'Sign Up' a verification email will be sent, click that and you are in!" />
            </ListItem>
            <ListItem>
              <ListItemText primary="We recommend also downloading our mobile app which is available for android and ios." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Forget Password</Typography>
        </AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <iframe 
            width="560"
            height="315"
            src="https://www.youtube.com/embed/3UDpbLmCZeA?si=zyxJuZ5pAzE4s9xs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </Box>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            If you ever forget your password do no worry!
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2   } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={forgetPassword1} alt="Forget Password1" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="Go to the login page, and click forget password" />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={forgetPassword2} alt="Forget Password1" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="Type in the email you signed up with and click forget password." />
            </ListItem>
            <ListItem>
              <ListItemText primary="The email to reset your password should send within 5 minutes. Make sure to check your junk and spam inbox." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Now type in your new password and login again." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default SupportGettingStarted;
