import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Paper, Typography, Button, Tooltip,
} from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { FixedSizeGrid as Grid, FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const Cell = ({ columnIndex, rowIndex, style, data }) => {
  const { columns, walkwayPosition, selectedCells, toggleCellSelection, isDragging, zoom, removedCells } = data;
  
  // Check if the cell is in the removedCells array and skip rendering
  if (removedCells.some(cell => cell.rowIndex === rowIndex && cell.columnIndex === columnIndex)) {
    return null;  // Skip rendering this cell
  }

  const isSelected = selectedCells.some(cell => cell.row === rowIndex && cell.column === columnIndex);
  let cellStyle = isSelected ? { backgroundColor: 'skyblue' } : {};

  const handleMouseMove = () => {
    if (isDragging) toggleCellSelection(rowIndex, columnIndex, true, walkwayPosition);
  };

  if (columnIndex === walkwayPosition - 1 && walkwayPosition > 0) {
    return (
      <Box key={`walkway-${rowIndex}-${columnIndex}`} onMouseMove={handleMouseMove} sx={{ ...style, width: zoom * 35, height: zoom * 38, display: 'inline-flex', justifyContent: 'center', alignItems: 'center', ...cellStyle }}>
        <KeyboardDoubleArrowUpIcon />
      </Box>
    );
  }

  return (
    <Paper key={`cell-${rowIndex}-${columnIndex}`} data-cell-id={`${rowIndex}-${columnIndex}`} onMouseMove={handleMouseMove} sx={{ ...style, width: zoom * 35, height: zoom * 38, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', border: '1px solid black', ...cellStyle }} onClick={() => toggleCellSelection(rowIndex, columnIndex)} />
  );
};

function RenderPlotForDataEntry({
  rows,
  columns,
  walkwayPosition,
  leftSideLabels,
  rightSideLabels,
  columnLabels,
  selectedCells,
  setSelectedCells,
  removedCells,  // Add removedCells as a prop
}) {
  const gridRef = useRef();
  const leftListRef = useRef();
  const rightListRef = useRef();
  const columnListRef = useRef();
  const scrollRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedCells, setDraggedCells] = useState(new Set());
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();

  const handleScroll = ({ scrollLeft, scrollTop, scrollUpdateWasRequested }) => {
    if (leftListRef.current) {
      leftListRef.current.scrollTo(scrollTop);
    }
    if (rightListRef.current) {
      rightListRef.current.scrollTo(scrollTop);
    }
    if (columnListRef.current) {
      columnListRef.current.scrollTo(scrollLeft);
    }
  };

  const toggleCellSelection = (row, column, isDragMode = false, walkwayPosition = null) => {
    if (column === walkwayPosition - 1) return;

    const cellId = `${row}-${column}`;
    if (!isDragMode) {
      const isSelected = selectedCells.some(cell => cell.row === row && cell.column === column);
      if (isSelected) {
        setSelectedCells(selectedCells.filter(cell => cell.row !== row || cell.column !== column));
      } else {
        setSelectedCells([...selectedCells, { row, column }]);
      }
    } else {
      if (!draggedCells.has(cellId)) {
        draggedCells.add(cellId);
        setDraggedCells(new Set(draggedCells));

        const isSelected = selectedCells.some(cell => cell.row === row && cell.column === column);
        if (isSelected) {
          setSelectedCells(selectedCells.filter(cell => cell.row !== row || cell.column !== column));
        } else {
          setSelectedCells([...selectedCells, { row, column }]);
        }
      }
    }
  };

  const initiateDragSelection = (row, column) => {
    setIsDragging(true);
    toggleCellSelection(row, column, true);
  };

  const handleMouseDown = (e) => {
    const target = e.target.closest('[data-cell-id]');
    if (target) {
      const [row, column] = target.getAttribute('data-cell-id').split('-').map(Number);
      const mouseMoveHandler = () => {
        initiateDragSelection(row, column);
        document.removeEventListener('mousemove', mouseMoveHandler);
      };
      document.addEventListener('mousemove', mouseMoveHandler);

      const mouseUpHandler = () => {
        document.removeEventListener('mouseup', mouseUpHandler);
        document.removeEventListener('mousemove', mouseMoveHandler);
        setIsDragging(false);
        setDraggedCells(new Set());
      };
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDraggedCells(new Set());
  };

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const adjustedColumnCount = walkwayPosition > 0 ? columns + 1 : columns;
  const gridWidth = width * 0.68;
  const gridHeight = height * 0.75;

  return (
    <Box onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
      <Box sx={{
        mb: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Tooltip title="Zoom in and out">
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Button onClick={() => handleZoomChange(0.5)}>50%</Button>
            <Button onClick={() => handleZoomChange(1)}>100%</Button>
            <Button onClick={() => handleZoomChange(1.5)}>150%</Button>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '91px', overflow: 'hidden' }}>
          <List
            height={zoom * 38}
            width={gridWidth}
            itemCount={adjustedColumnCount}
            itemSize={zoom * 37}
            itemData={{
              labels: columnLabels,
              zoom,
            }}
            layout="horizontal"
            ref={columnListRef}
            style={{ marginLeft: '-1px', overflow: 'hidden', paddingRight: '20px' }}
          >
            {({ index, style }) => (
              <Typography 
                style={style} 
                sx={{
                  ...style,
                  fontSize: zoom * 12,
                  border: '1px solid black', // Added border style
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                }}
              >
                {columnLabels[index]}
              </Typography>
            )}
          </List>
        </Box>
        <Paper 
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginLeft: '10px' }}
          ref={scrollRef}
        >
          <List
            height={gridHeight}
            width={80}
            itemCount={rows}
            itemSize={zoom * 38}
            ref={leftListRef}
            itemData={{
              labels: leftSideLabels,
              zoom,
            }}
            style={{ overflow: 'hidden', paddingBottom: '20px'}}
          >
            {({ index, style }) => (
              <Typography 
                style={style}
                sx={{
                  ...style,
                  fontSize: zoom * 12,
                  border: '1px solid black', // Added border style
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                }}
              >
                {leftSideLabels[index]}
              </Typography>
            )}
          </List>
            <Grid
              columnCount={adjustedColumnCount}
              columnWidth={zoom * 37}
              height={gridHeight}
              rowCount={rows}
              rowHeight={zoom * 38}
              width={gridWidth}
              onScroll={handleScroll}
              style={{ outline: '2px solid black' }}
              itemData={{
                columns,
                walkwayPosition,
                selectedCells,
                toggleCellSelection: toggleCellSelection,
                isDragging,
                zoom,
                removedCells,  // Pass removedCells to Grid
              }}
            >
              {Cell}
            </Grid>
          <List
            height={gridHeight}
            width={80}
            itemCount={rows}
            itemSize={zoom * 38}
            ref={rightListRef}
            itemData={{
              labels: rightSideLabels,
              zoom,
            }}
            style={{ overflow: 'hidden', paddingBottom: '20px'}}
          >
            {({ index, style }) => (
              <Typography 
                style={style}
                sx={{
                  ...style,
                  fontSize: zoom * 12,
                  border: '1px solid black', // Added border style
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                }}
              >
                {rightSideLabels[index]}
              </Typography>
            )}
          </List>
        </Paper>
      </Box>
    </Box>
  );
}

export default RenderPlotForDataEntry;
