import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Grid, Card, CardContent, Typography, Container, Tooltip, FormControlLabel, Checkbox, Divider, useMediaQuery
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  doc, deleteDoc, collection, getDoc, getDocs, updateDoc, increment, query, where
} from 'firebase/firestore';
import { auth } from '../firebaseConfig';
import OrganizationsDropdown from '../components/OrgDropdown';
import useOrgFirestore from '../hooks/useOrgFirestore';
import Tour from 'reactour';
import RoleCheck from '../utils/roleCheck';
import DeleteDialogBox from '../components/DeleteDialogBox';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import Link from "@mui/material/Link";
import axios from "axios";

function MyForms() {
  const [selectedOrg, setSelectedOrg] = useState('');
  const { firestore, location } = useOrgFirestore(selectedOrg);
  const [forms, setForms] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourSeen, setTourSeen] = useState(localStorage.getItem('myFormsTourSeen') === 'true');
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState('');
  const [orgDataLimits, setOrgDataLimits] = useState({ currentTotalData: 0, maximumTotalData: 0 });
  const navigate = useNavigate();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ id: null, name: null });
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!loading) {
      setIsTourOpen(!tourSeen);
    }
  }, [loading, tourSeen]);

  const handleTourCheckboxChange = (event) => {
    localStorage.setItem('myFormsTourSeen', event.target.checked);
    setTourSeen(event.target.checked);
  };

  const steps = [
    {
      selector: '[data-tour="createForm"]',
      content: t('Forms.ReactTour.Desc.0'),
    },
    {
      selector: '',
      content: () => (
          <FormControlLabel
              control={<Checkbox checked={tourSeen} onChange={handleTourCheckboxChange} />}
              label={t("General.DoNotShow")}
          />
      ),
    },
  ];

  useEffect(() => {
    const fetchOrgDataLimits = async () => {
      if (selectedOrg && firestore) {
        const orgDocRef = doc(firestore, `organizations/${selectedOrg}`);
        const orgDoc = await getDoc(orgDocRef);
        if (orgDoc.exists()) {
          const { currentTotalData, maximumTotalData } = orgDoc.data();
          setOrgDataLimits({ currentTotalData, maximumTotalData });
        }
      }
    };

    fetchOrgDataLimits();
  }, [selectedOrg, firestore]);

  useEffect(() => {
    if (selectedOrg && firestore) {
      const fetchForms = async () => {
        const formsRef = collection(firestore, `organizations/${selectedOrg}/forms`);

        // Create a query that only gets documents where the "deleted" field is false
        const formsQuery = query(formsRef, where('deleted', '==', false));

        try {
          const querySnapshot = await getDocs(formsQuery);
          const fetchedForms = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().formName }));
          setForms(fetchedForms);
        } catch (error) {
          Sentry.captureException(error);
          setForms([]);
        }
      };

      fetchForms();
    } else {
      setForms([]);
    }
  }, [selectedOrg, firestore]);

  const handleAddFormClick = () => {
    navigate('/create-form/');
  };

  const handleFormClick = (formId) => {
    navigate(`/data-entry/${selectedOrg}/${location}/${formId}`);
  };

  const handleViewDataClick = (formId) => {
    navigate(`/view-data/${selectedOrg}/${location}/${formId}`);
  };

  const handleEditFormClick = (formId) => {
    navigate(`/edit-form/${selectedOrg}/${location}/${formId}`);
  };

  const handleOrgChange = async (org) => {
    setSelectedOrg(org);
  };

  const handleDeleteFormClick = (formId, formName) => {
    setSelectedItem({ id: formId, name: formName });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem({ id: null, name: null });
  };

  const handleDeleteForm = async () => {
    if (selectedItem.id) {
      try {
        const formId = selectedItem.id;




        // Get the numberOfSubmissions from the form document
        const formDocRef = doc(firestore, `organizations/${selectedOrg}/forms/${formId}`);

        const formDoc = await getDoc(formDocRef);
        await updateDoc(formDocRef, { deleted: true });
        const numberOfSubmissions = formDoc.exists() ? formDoc.data().numberOfSubmissions : 0;
        const data = {
          mode: import.meta.env.VITE_WEB_MODE,
          location: location,
          path: `organizations/${selectedOrg}/forms/${formId}`
        }

        const response = axios.post(import.meta.env.VITE_FUNCTION_LINK_DELETE_DOCUMENTS, data);

        // Update the organization document
        const orgDocRef = doc(firestore, `organizations/${selectedOrg}`);
        await updateDoc(orgDocRef, {
          currentTotalData: increment(-numberOfSubmissions)
        });

        setForms(forms.filter((form) => form.id !== selectedItem.id));
      } catch (error) {
        showSnackbar('Error deleting form and the submissions', 'error');
        Sentry.captureException(error);
      } finally {
        handleDialogClose();
      }
    }
  };

  const isFormAdditionAllowed = orgDataLimits.currentTotalData < orgDataLimits.maximumTotalData;

  return (
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <RoleCheck
            currentAuthUid={currentAuthUid}
            orgId={selectedOrg}
            rolesAllowed={['Admin', 'Owner', 'Contributor', 'Viewer']}
            redirectPage='/account'
            firestore={firestore}
            setUserRole={setUserRole}
        />
        <Typography variant="h3" gutterBottom>
          {t('Navbar.MyForms')}
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
        <Typography variant="h6" gutterBottom>
          {t('Forms.Desc')}
        </Typography>
        <OrganizationsDropdown selectedOrg={selectedOrg} setSelectedOrg={handleOrgChange} setLoading={setLoading} />
        <Tooltip title={isFormAdditionAllowed ? '' : 'Data limit exceeded'}>
        <span>
          <Button
              data-tour="createForm"
              variant="contained"
              onClick={handleAddFormClick}
              sx={{ my: 2 }}
              disabled={!isFormAdditionAllowed || !(userRole === 'Admin' || userRole === 'Owner')}
          >
            {t('Forms.Add')}
          </Button>
        </span>
        </Tooltip>

        {forms.length === 0 ? (
            selectedOrg ? (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  {t('Forms.Empty')}
                  <Link
                      href="/support/forms"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    {t('General.ClickHere')}
                  </Link>{' '}
                  {t('General.LearnToGetStarted')}
                </Typography>
            ) : (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  {t('General.NoOrg')}
                  {' '}<Link
                      href="/support/products"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    {t('General.ClickHere')}
                  </Link>{' '}
                  {t('General.LearnAboutOrg')}
                </Typography>
            )
        ) : (
            <Grid container spacing={3}>
              {forms.map((form) => (
                  <Grid item xs={12} sm={12} md={12} key={form.id}>
                    <Card elevation={5} sx={{ border: '3px solid', borderColor: 'primary.border' }}>
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid item xs={11}>
                            <Grid container direction="column" alignItems="center">
                              <Typography variant="h5" align="center" sx={{ fontSize: '1.4rem' }}>
                                {form.name}
                              </Typography>
                              <Grid container justifyContent="center" sx={{ mt: 1 }} direction={isMobile ? 'column' : 'row'}>
                                <Tooltip title={isFormAdditionAllowed ? '' : 'Data limit exceeded'}>
                          <span>
                            <Button
                                variant="outlined"
                                onClick={() => handleFormClick(form.id)}
                                sx={{
                                  mt: 1,
                                  color: 'primary.main',
                                  backgroundColor: 'secondary.main',
                                  mr: isMobile ? 0 : 1,
                                }}
                                disabled={!isFormAdditionAllowed || !(userRole === 'Admin' || userRole === 'Owner' || userRole === 'Contributor')}
                            >
                              {t('General.EnterData')}
                            </Button>
                          </span>
                                </Tooltip>
                                <Tooltip title={isFormAdditionAllowed ? '' : 'Data limit exceeded'}>
                          <span>
                            <Button
                                variant="outlined"
                                onClick={() => handleViewDataClick(form.id)}
                                sx={{ mt: 1, ml: isMobile ? 0 : 1, backgroundColor: 'secondary.main' }}
                                disabled={!isFormAdditionAllowed || !(userRole === 'Admin' || userRole === 'Owner' || userRole === 'Contributor')}
                            >
                              {t('General.ViewData')}
                            </Button>
                          </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                              item
                              xs={1}
                              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2, pr: 1, pt: isMobile ? 5 : 0 }}
                          >
                            <Tooltip title={t('Forms.Edit')}>
                      <span>
                        <Button
                            onClick={() => handleEditFormClick(form.id)}
                            disabled={!isFormAdditionAllowed || !(userRole === 'Admin' || userRole === 'Owner')}
                        >
                          <EditIcon />
                        </Button>
                      </span>
                            </Tooltip>
                            <Tooltip title={t('Forms.Delete')}>
                      <span>
                        <Button
                            onClick={() => handleDeleteFormClick(form.id, form.name)}
                            disabled={!(userRole === 'Admin' || userRole === 'Owner')}
                        >
                          <DeleteIcon />
                        </Button>
                        <DeleteDialogBox
                            open={dialogOpen}
                            onClose={handleDialogClose}
                            onConfirm={handleDeleteForm}
                            itemName={selectedItem.name}
                        />
                      </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
              ))}
            </Grid>
        )}

        <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
      </Container>
  );

}

export default MyForms;
