import React from 'react';
import {
  Container, Typography, List, ListItem, Box, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import profileSupport from "../../images/profileSupport.png";

function SupportAccount() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          Account User Guide
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Here we are able to manage general account information. We can get here by clicking "Account" on the navigation bar, then going to "View Profile Information"
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={profileSupport} alt="Profile Page" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="You can change your password here and view the email you signed up with" />
            </ListItem>
            <ListItem>
              <ListItemText primary="There is also the Delete Account option. This will cancel any subscription you have, remove all your data, and wipe your account from existance. This can not be undone." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default SupportAccount;
