import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  doc,
  collection,
  deleteDoc, getDoc, updateDoc, getDocs, serverTimestamp, increment, Timestamp,
} from 'firebase/firestore';
import {
  Container,
  Typography,
  Modal,
  Box,
  Button,
  TextField, Paper, FormControl, InputLabel, Select, MenuItem, Checkbox,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbar } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from '../components/SnackbarContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Loading from '../components/Loading';
import { getFirestoreLocation, app, auth } from '../firebaseConfig';
import { getStorage, ref, getDownloadURL, deleteObject  } from 'firebase/storage';
import { fetchFromRedis, saveToRedis } from '../utils/redisUtils';
import RoleCheck from '../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

function useData(orgId, formId, location, navigate) {
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [dataEntries, setDataEntries] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [formName, setFormName] = useState('');
  const [canUseCamera, setCanUseCamera] = useState(false);
  const [loading, setLoading] = useState(true);
  const { showSnackbar } = useSnackbar();
  const REDIS_KEY_PREFIX = 'form-data';
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const redisKey = `${REDIS_KEY_PREFIX}_${orgId}_${formId}`;
      const redisLastUpdatedKey = `${REDIS_KEY_PREFIX}_${orgId}_${formId}_lastUpdated`;

      try {
        // Fetch organization document to get totalData and maximumTotalData
        const orgDocRef = doc(firestore, 'organizations', orgId);
        const orgSnapshot = await getDoc(orgDocRef);
        const orgData = orgSnapshot.data();
        setCanUseCamera(orgData.canUseCamera);

        if (orgData.currentTotalData > orgData.maximumTotalData) {
          // Navigate to home and show snackbar
          navigate('/home');
          showSnackbar('Organization has more data than is allowed, delete forms to continue or upgrade organization.', 'error');
          return;
        }
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
        setLoading(false);
        return;
      }

      let useCache = false;

      try {
        const cachedData = await fetchFromRedis(redisKey, location);
        const cachedLastUpdated = await fetchFromRedis(redisLastUpdatedKey, location);

        if (cachedData) {
          const { questions, dataEntries } = cachedData;

          const formDocRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
          const formSnapshot = await getDoc(formDocRef);
          const formData = formSnapshot.data();
          const cachedLastUpdatedTimestamp = new Date(cachedLastUpdated).getTime();

          if (formData.lastUpdated.toMillis() <= cachedLastUpdatedTimestamp) {
            setQuestions(questions);
            setDataEntries(dataEntries);
            setFormName(formData.formName || 'AUDONIS_Form'); // Set formName here
            setLoading(false);
            useCache = true;
          }
        }
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
      }

      if (!useCache) {
        try {
          const formDocRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
          const formSnapshot = await getDoc(formDocRef);
          const formData = formSnapshot.data();

          setFormName(formData.formName || 'AUDONIS_Form'); // Set formName here
          setQuestions(formData.questions || []);

          const entriesCol = collection(firestore, `organizations/${orgId}/forms/${formId}/submissions`);
          const entriesSnapshot = await getDocs(entriesCol);
          const fetchedEntries = entriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDataEntries(fetchedEntries);

          await saveToRedis(redisKey, { questions: formData.questions || [], dataEntries: fetchedEntries, lastUpdated: formData.lastUpdated.toMillis() });
          setLoading(false);
        } catch (error) {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [orgId, formId, location, firestore, navigate]);

  const updateRedisCache = async (updatedEntries) => {
    const redisKey = `${REDIS_KEY_PREFIX}_${orgId}_${formId}`;
    try {
      const formDocRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
      const formSnapshot = await getDoc(formDocRef);
      const formData = formSnapshot.data();
      await saveToRedis(redisKey, { questions, dataEntries: updatedEntries, lastUpdated: formData.lastUpdated.toMillis() }, location);
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
    }
  };

  return { dataEntries, setDataEntries, questions, formName, loading, updateRedisCache, canUseCamera };
}

function ViewData() {
  const { orgId, formId, location } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { dataEntries, setDataEntries, questions, formName, loading, updateRedisCache, canUseCamera } = useData(orgId, formId, location, navigate); 
  const [rowModesModel, setRowModesModel] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [imageUrl, setImageUrl] = useState(null); 
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();

  const handleCancelClick = (id) => () => {
    setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.View } }));
  };

  const handleShowImage = (imagePath) => {
    const storage = getStorage();
    const imageRef = ref(storage, imagePath);

    getDownloadURL(imageRef)
      .then((url) => {
        setImageUrl(url);  // Store the image URL
        setModalOpen(true); // Open the modal
      })
      .catch((error) => {
        showSnackbar(t('Error fetching image'), 'error');
        Sentry.captureException(error);
      });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setImageUrl(null);
  };

  const handleSave = async (rowData) => {
    const { id, ...cleanRow } = rowData;
  
    Object.keys(cleanRow).forEach((key) => {
      if (cleanRow[key] === undefined || cleanRow[key] === '') {
        cleanRow[key] = null; // Firestore will accept null values
      }
    });

    try {
      if (cleanRow.submittedAt && typeof cleanRow.submittedAt === 'object' && cleanRow.submittedAt.seconds !== undefined) {
        cleanRow.submittedAt = new Timestamp(cleanRow.submittedAt.seconds, cleanRow.submittedAt.nanoseconds);
      }

      await updateDoc(doc(firestore, `organizations/${orgId}/forms/${formId}/submissions`, id), cleanRow);

      const formDocRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
      await updateDoc(formDocRef, { lastUpdated: serverTimestamp() });

      updateRedisCache(dataEntries);

      return rowData;
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      throw error;
    }
  };  

  const deleteImage = async (imagePath) => {
    try {
      const storage = getStorage(app, storageBucket);
      const imageRef = ref(storage, imagePath);

      await deleteObject(imageRef);
      showSnackbar(t('Plots.Snackbar.DeleteImageSuccess'),'success')
    } catch (error) {
      console.log(error)
      showSnackbar(t('Plots.Snackbar.DeleteImageError'),'error')
      Sentry.captureException(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Loop through the questions to find image-type questions
      questions.forEach(async (question) => {
        if (question.dataType === 'image') {
          // Construct the image path based on the storage structure
          const imagePath = `${storageBucket}/${orgId}/forms/${formId}/${id}/${question.id}.jpeg`;
          
          // Attempt to delete the image from Firebase Storage
          await deleteImage(imagePath);
        }
      });

      await deleteDoc(doc(firestore, `organizations/${orgId}/forms/${formId}/submissions/${id}`));

      const formDocRef = doc(firestore, `organizations/${orgId}/forms/${formId}`);
      await updateDoc(formDocRef, {
        lastUpdated: serverTimestamp(),
        numberOfSubmissions: increment(-1),
      });

      const orgDocRef = doc(firestore, 'organizations', orgId);
      await updateDoc(orgDocRef, {
        currentTotalData: increment(-1),
      });

      setDataEntries(dataEntries.filter(entry => entry.id !== id));

      updateRedisCache(dataEntries); // Update Redis cache after deletion

    } catch (error) {
      showSnackbar(t('Form.Snackbar.DeleteDataError'), 'error');
      Sentry.captureException(error);
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const processRowUpdate = async (newRow, oldRow) => {
    if (!newRow.id) {
      return oldRow;
    }

    try {
      const savedRow = await handleSave(newRow);
      setDataEntries(prevEntries => prevEntries.map(entry => (entry.id === newRow.id ? savedRow : entry)));

      updateRedisCache(dataEntries); // Update Redis cache after row update
      return savedRow;
    } catch (error) {
      showSnackbar(t('Forms.Snackbar.UpdateDataError'), 'error');
      Sentry.captureException(error);
      return oldRow;
    }
  };

  const getActions = ({ id }) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    const handleSaveClick = () => {
      setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.View } }));
    };

    return isInEditMode ? [
      <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick} />,
      <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(id)} />
    ] : [
      <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(id)} />,
      <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(id)} />
    ];
  };

  const columns = questions.map(question => {
    let columnConfig = {
      field: question.id,
      headerName: question.text.substring(4),  // Trimming the first 4 characters
      width: 150,
      editable: true,
    };

    switch (question.dataType) {
      case 'image':
        columnConfig = {
          ...columnConfig,
          editable: false, 
          renderCell: (params) => {
            const imagePath = `${storageBucket}/${orgId}/forms/${formId}/${params.row.id}/${question.id}.jpeg`;
            const imageSubmission = params.row[question.id];
            return (
              imagePath && imageSubmission !== null ? ( // Only show button if image has submission
                <Button 
                  variant="contained"
                  onClick={() => handleShowImage(imagePath)}
                  disabled={!canUseCamera} 
                >
                  View Image
                </Button>
              ) : null
            );
          },
        };
      break;
      case 'multiple choice':
        columnConfig = {
          ...columnConfig,
          width: 200,
          type: 'singleSelect',
          valueOptions: question.options.map(option => option.value), // Ensure options are set
          filterOperators: [
            {
              label: 'is any of',
              value: 'isAnyOf',
              getApplyFilterFn: (filterItem) => {
                if (!filterItem.value || filterItem.value.length === 0) {
                  return null;
                }
                return ({ value }) => {
                  if (!value || value.length === 0) {
                    return false;
                  }
                  return filterItem.value.some((filterValue) => value.includes(filterValue));
                };
              },
              InputComponent: (props) => (
                  <FormControl fullWidth>
                    <InputLabel>{question.text.substring(4)}</InputLabel>
                    <Select
                        multiple
                        value={props.item.value || []}
                        onChange={(event) => {
                          props.applyValue({ ...props.item, value: event.target.value });
                        }}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              width: 250,
                            },
                          },
                        }}
                    >
                      {question.options.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <Checkbox checked={(props.item.value || []).includes(option.value)} />
                            {option.value}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              ),
            },
          ],
          renderEditCell: (params) => (
              <FormControl fullWidth>
                <InputLabel>{question.text.substring(4)}</InputLabel>
                <Select
                    multiple
                    value={params.value || []}
                    onChange={(event) => {
                      params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
                    }}
                    renderValue={(selected) => (selected || []).join(', ')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                >
                  {question.options.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        <Checkbox checked={(params.value || []).includes(option.value)} />
                        {option.value}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
          ),
          sortComparator: (v1, v2) => {
            const val1 = (v1 || []).join(', ');
            const val2 = (v2 || []).join(', ');
            return val1.localeCompare(val2);
          },
        };

        break;
      case 'dropdown':
        columnConfig = {
          ...columnConfig,
          type: 'singleSelect',
          valueOptions: question.options.map(option => ({ value: option.value, label: option.value }))
        };
        break;
      case 'number':
        columnConfig = {
          ...columnConfig,
          type: 'number'
        };
        break;
      case 'date':
        columnConfig = {
          ...columnConfig,
          width: 170,
          editable: true,
          renderCell: (params) => {
            // Check if the date value is valid
            let dateValue = '';
            if (params.value && !isNaN(Date.parse(params.value))) {
              dateValue = format(parseISO(params.value), 'yyyy-MM-dd');
            }
            return (
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dateValue}
                disabled
              />
            );
          },
          renderEditCell: (params) => {
            let dateValue = '';
            if (params.value && !isNaN(Date.parse(params.value))) {
              dateValue = format(parseISO(params.value), 'yyyy-MM-dd');
            }
            return (
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dateValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue && !isNaN(Date.parse(newValue))) {
                    // Set the new date value if it's valid
                    params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, e);
                  } else {
                    // Leave the field blank if the input is invalid or empty
                    params.api.setEditCellValue({ id: params.id, field: params.field, value: '' }, e);
                  }
                }}
              />
            );
          },
        };
          break;        
      default:
        columnConfig = {
          ...columnConfig,
          type: 'text'
        };
        break;
    }
    return columnConfig;
  });

  columns.push({
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    width: 100,
    getActions: getActions,
  });

  if (loading) {
    return (
        <Loading message="Please wait, data is loading..."  size={50} />
    );
  }

  return (
      <Container>
        <RoleCheck
            currentAuthUid={currentAuthUid}
            orgId={orgId}
            rolesAllowed={['Admin', 'Owner', 'Contributor', 'Viewer']}
            redirectPage='/home'
            firestore={firestore}
            setUserRole={setUserRole}
        />
        <Typography variant="h4" gutterBottom sx={{ mt: 3}}>
          {t('General.ViewData')}
        </Typography>
        <Paper style={{ height: '40vw', width: '80vw' }}>
          <DataGrid
              rows={dataEntries}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              onRowModesModelChange={setRowModesModel}
              processRowUpdate={processRowUpdate}
              getRowId={(row) => row.id}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: {
                    fileName: formName,
                    utf8WithBom: true,
                  },
                },
              }}
              sx={{
                boxShadow: 2,
                border: 1,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
              }}
          />
        </Paper>
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 2,
              maxWidth: '90%',
              maxHeight: '90%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={imageUrl} alt="Submitted" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            <Button onClick={() => setModalOpen(false)} variant="contained" sx={{ mt: 1 }}>
              Close
            </Button>
          </Box>
        </Modal>
     </Container> 
  );
}

export default ViewData;
