import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Paper, Stack, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import womenCollectingData from '../images/WomenCollectingData.png';
import { useMediaQuery, useTheme } from '@mui/material';

function HomeGetStarted() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container alignItems="center" direction={isMobile ? 'column-reverse' : 'row'}>
      <Grid 
        item 
        xs={12} 
        md={6} 
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '50vh' 
        }}
      >
        <Typography variant="h4" align="center">
          {t('Home.GetStarted.Title')}
        </Typography>
        <Typography variant="h6" style={{ marginBottom: 50 }} align="center">
          {t('Home.GetStarted.Desc')}
        </Typography>
        <Paper
          elevation={3}
          style={{
            width: isMobile ? '80%' : '50%',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: `2px solid grey`,
          }}
        >
          <Stack spacing={2} style={{ width: '100%' }}>
            <Link to="/signup" style={{ textDecoration: 'none', width: '100%' }}>
              <Button color="primary" variant="contained" size="large" fullWidth style={{ padding: '16px', fontSize: '1.3rem', fontWeight: 'bold' }}>
                {t('Auth.SignUpButton')}
              </Button>
            </Link>
            <Typography variant="h6" align="center">
              {t('Home.SignUpMessage')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid 
        item 
        xs={12} 
        md={6} 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          minHeight: '50vh' 
        }}
      >
        <img src={womenCollectingData} alt="Women Collecting Data" style={{  width: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
}

export default HomeGetStarted;
