import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useSnackbar } from '../components/SnackbarContext';
import {
  TextField, Button, Box, Container, Grid, Typography,
} from '@mui/material';
import { getFirestoreLocation, auth } from '../firebaseConfig';
import { PlotQuestion } from '../components/PlotQuestion';
import RoleCheck from '../utils/roleCheck';
import { useTranslation } from 'react-i18next';
import restrictedChars from '../local_data/restricted-chars.json'; // Import restricted characters
import *  as Sentry from '@sentry/react'

function EditFormForPlot() {
  const { orgId, plotId, location, formId } = useParams();
  const { firestore, storageBucket } = getFirestoreLocation(location);
  const [formName, setFormName] = useState('');
  const [formNameError, setFormNameError] = useState(false); // Add form name error state
  const [userRole, setUserRole] = useState('');
  const [formQuestions, setFormQuestions] = useState([]);
  const navigate = useNavigate();
  const dataTypes = ['text', 'whole number', 'decimal', 'date', 'dropdown'];
  const { showSnackbar } = useSnackbar();
  const currentAuthUid = auth.currentUser ? auth.currentUser.uid : null;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formDoc = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);
        const docSnapshot = await getDoc(formDoc);

        if (docSnapshot.exists()) {
          const formData = docSnapshot.data();
          const questions = formData.questions || [];

          const adaptedQuestions = questions.map(question => ({
            ...question,
            visible: !question.text.includes('Level'), // Logic to hide 'Level' questions
          }));

          setFormQuestions(adaptedQuestions);
          setFormName(formData.formName); // Set the formName state to the retrieved form name
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchData();
  }, [orgId, plotId, formId]);

  // Updated to use question ID for updates
  const updateQuestion = (id, updatedQuestionData) => {
    const updatedQuestions = formQuestions.map(
      (question) => (question.id === id ? { ...question, ...updatedQuestionData } : question),
    );

    setFormQuestions(updatedQuestions);
  };

  const validateFormName = (text) => {
    const restrictedCharsRegex = new RegExp(`[${restrictedChars.restricted_chars.join('')}]`);
    return !restrictedCharsRegex.test(text);
  };

  const handleFormNameChange = (e) => {
    const newName = e.target.value;
    if (newName.length > 63) {
      setFormNameError(true); // Set as boolean
    } else if (!validateFormName(newName)) {
      setFormNameError(true); // Set as boolean
    } else {
      setFormNameError(false); // Set as boolean
    }
    setFormName(newName);
  };

  const saveChanges = async () => {
    const trimmedFormName = formName.trim();  // Trim the form name
    if (!trimmedFormName) {
      showSnackbar(t('Snackbar.FormNameEmpty'), 'error');
      return;
    }

    if (!validateFormName(trimmedFormName)) {
      showSnackbar(t('Snackbar.RestrictedCharsMessage'), 'error');
      return;
    }

    if (trimmedFormName !== formId) {
      const formsCollection = collection(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots');
      const nameCheckQuery = query(formsCollection, where("formName", "==", trimmedFormName));
      const idCheckQuery = query(formsCollection, where("__name__", "==", trimmedFormName));

      try {
        const [nameSnapshot, idSnapshot] = await Promise.all([
          getDocs(nameCheckQuery),
          getDocs(idCheckQuery)
        ]);

        const isNameDuplicate = nameSnapshot.docs.some(doc => doc.id !== formId);
        const isIdDuplicate = idSnapshot.docs.length > 0;

        if (isNameDuplicate || isIdDuplicate) {
          showSnackbar(t('Snackbar.ScoutingFormNameExists'), 'error');
          return;
        }
      } catch (error) {
        showSnackbar('Failed to check Scouting Form uniqueness. Please try again.', 'error');
        Sentry.captureException(error);
        return;
      }
    }

    // If checks are passed, proceed with updating the document
    try {
      const formDoc = doc(firestore, 'organizations', orgId, 'plots', plotId, 'formsForPlots', formId);

      // Create a new array with the questions in the desired order
      const orderedQuestions = [...formQuestions];

      await updateDoc(formDoc, { formName: trimmedFormName, questions: orderedQuestions });
      showSnackbar(t('Snackbar.FormUpdated'), 'success');
      navigate(`/explore-my-plot/${orgId}/${location}/${plotId}`);
    } catch (error) {
      showSnackbar('Failed to update form.', 'error');
      Sentry.captureException(error);
    }
  };

  const isSaveButtonDisabled = !formName || formNameError; // Add form name error to disable condition

  return (
    <Container maxWidth="md">
      <RoleCheck
        currentAuthUid={currentAuthUid}
        orgId={orgId}
        rolesAllowed={['Admin', 'Owner']}
        redirectPage='/home'
        firestore={firestore}
        setUserRole={setUserRole}
      />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">
          {t('Plots.EditScouting')}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <TextField
            value={formName}
            onChange={handleFormNameChange} // Change to handleFormNameChange
            margin="normal"
            variant="outlined"
            fullWidth={true}
            inputProps={{ maxLength: 63 }}
            helperText={formNameError ? 'Invalid Form Name' : ''} // Add helper text
            error={formNameError} // Add error state
          />
        </Box>

        <Grid container spacing={2}>
          {formQuestions.filter(q => q.visible).map((question) => (
            <Grid item xs={12} md={6} key={question.id}>
              <PlotQuestion
                index={question.id}
                data={question}
                dataTypes={dataTypes}
                updateQuestion={updateQuestion}
                editableText={false}
                isEditing={true}  // Added prop
              />
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={saveChanges}
          sx={{ mt: 2 }}
          disabled={isSaveButtonDisabled} // Disable button based on boolean error states
        >
          {t('General.SaveChanges')}
        </Button>
      </Box>
    </Container>
  );
}

export default EditFormForPlot;
