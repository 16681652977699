import React from 'react';
import {
  Container, Typography, Box, Button, Divider, Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer'
import gettingStartedSupport from '../images/gettingStartedSupport.png'
import accountSupport from '../images/accountSupport.png'
import productsSupport from '../images/productsSupport.png'
import organizationSupport from '../images/organizationSupport.png';
import myPlotsSupport from '../images/myPlotsSupport.png';
import myFormsSupport from '../images/myFormsSupport.png';

function Support() {
  const navigate = useNavigate();

  const buttonStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    p: 2,
    boxShadow: 3
  };

  return (
    <Container maxWidth={false} disableGutters={true} >
      <Container maxWidth="lg" sx={{ mt: 3, mb:3 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" gutterBottom>
            Support
          </Typography>
          <Typography variant="h5" gutterBottom>
            User Guides
          </Typography>
          <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => navigate('/support/getting-started')}
            >
              <Box
                component="img"
                src={gettingStartedSupport}
                alt="Getting Started Support"
                sx={{ width: '100%', height: 'auto', mb: 1 }}
              />
              <Typography variant="h6" component="div">Getting Started</Typography>
              <Typography variant="body2">Begin Here</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => navigate('/support/products')}
            >
              <Box
                component="img"
                src={productsSupport}
                alt="Product Support"
                sx={{ width: '100%', height: 'auto', mb: 1 }}
              />
              <Typography variant="h6" component="div">Products</Typography>
              <Typography variant="body2">Buying An Organisation</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => navigate('/support/organizations')}
            >
              <Box
                component="img"
                src={organizationSupport}
                alt="Organization Support"
                sx={{ width: '100%', height: 'auto', mb: 1 }}
              />
              <Typography variant="h6" component="div">Organisations</Typography>
              <Typography variant="body2">Roles & Management</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => navigate('/support/account')}
            >
              <Box
                component="img"
                src={accountSupport}
                alt="Account Support"
                sx={{ width: '100%', height: 'auto', mb: 1 }}
              />
              <Typography variant="h6" component="div">Account</Typography>
              <Typography variant="body2">Manage Settings</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => navigate('/support/plots')}
            >
              <Box
                component="img"
                src={myPlotsSupport}
                alt="My Plots Support"
                sx={{ width: '100%', height: 'auto', mb: 1 }}
              />
              <Typography variant="h6" component="div">My Plots</Typography>
              <Typography variant="body2">Manage & Track</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => navigate('/support/forms')}
            >
              <Box
                component="img"
                src={myFormsSupport}
                alt="My Forms Support"
                sx={{ width: '100%', height: 'auto', mb: 1 }}
              />
              <Typography variant="h6" component="div">My Forms</Typography>
              <Typography variant="body2">Create & Use</Typography>
            </Button>
          </Grid>

        </Grid>
        </Container>
      <Footer fixed={false} />
    </Container>
  );
}

export default Support;
