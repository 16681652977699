import React from 'react';
import { Box, Button, Typography, Container, Paper } from '@mui/material';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Box role="alert" textAlign="center">
          <Typography variant="h4" gutterBottom>
            Oops, something went wrong.
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            {error.message}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={resetErrorBoundary}
            sx={{ mt: 2 }}
          >
            Try again
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default ErrorFallback;
