import { collection, addDoc, query, orderBy, getDocs, deleteDoc, limit } from 'firebase/firestore';

export const sendNotification = async (userId, title, message, defFirestore) => {
  try {
    const userNotificationsRef = collection(defFirestore, `users/${userId}/notifications`);
    const q = query(userNotificationsRef, orderBy('date', 'asc'), limit(1));

    // Retrieve the oldest notification if there are more than 14
    const notificationsSnapshot = await getDocs(userNotificationsRef);
    if (notificationsSnapshot.size >= 15) {
      const oldestNotificationSnapshot = await getDocs(q);
      oldestNotificationSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }

    // Add the new notification
    await addDoc(userNotificationsRef, {
      title: title,
      message: message,
      date: new Date()
    });
  } catch (error) {
    console.error("Error sending notification: ", error);
  }
};