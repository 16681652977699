import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import {
  TextField, Button, Container, Typography, Card, CardContent,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { auth } from '../firebaseConfig';
import theme from '../Theme';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react'

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [dialog, setDialog] = useState({ open: false, title: '', message: '' });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setDialog({ open: true, title: 'Success', message: t('ForgotPassword.MessageSent') });
    } catch (error) {
      setDialog({ open: true, title: 'Error', message: error.message });
      Sentry.captureException(error);
    }
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, title: '', message: '' });
    if (dialog.title === 'Success') {
      navigate('/login');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Card sx={{marginTop: '24px'}}>
          <CardContent >
            <Typography component="h1" variant="h5">
              {t('ForgotPassword.Title')}
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleForgotPassword}
              sx={{ mt: 3, mb: 2 }}
            >
              {t('ForgotPassword.ResetPasswordButton')}
            </Button>
            <Link to="/login" component="button" variant="body2">
              {t('ForgotPassword.BackToLogin')}
            </Link>
          </CardContent>
        </Card>
        <Dialog open={dialog.open} onClose={handleCloseDialog}>
          <DialogTitle>{dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialog.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}

export default ForgotPassword;
