import React from 'react';
import {
  Container, Typography, List, ListItem, Box, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import organisationSupportDiagram from '../../images/organisationSupportDiagram.png';

function SupportOrganizations() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          Organization User Guide
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rmT3jX1u4Sg?si=gK577-8MUKi-guwD"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen>
        </iframe>
      </Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">What is an Organization?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            To start creating forms and plots to collect data and view the dashboard, you need to create or join an Organisation!
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary="Organizations are where you and your team can collect and view data together." />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={organisationSupportDiagram} alt="subscription" style={{ width: '50%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="We can change your organization name in the account tab, just click the gear icon" />
            </ListItem>
            <ListItem>
              <ListItemText primary="We can manage your organization members in the account tab, and select Manage Organisations" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Here we can open the organization,invite, promote, and or demote users" />
            </ListItem>
            <ListItem>
              <ListItemText primary="You can only own one organization, but you can join as many as you like." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Organization Roles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            You can set different roles for different users in the organization
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary="Owner, the one who purchaes the organization, they have access to everything" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Admin, is able to create, edit,a delete forms as well as promote and demote other users" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Contributor, is able to submit data and view data but cannot create,edit or delete forms or plots." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Viewer, is only able to view data." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

    </Container>
  );
}

export default SupportOrganizations;
