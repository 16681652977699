import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  Box, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import subscription from '../../images/subscriptionSupport.png';
import subscriptionLocation from '../../images/subscriptionLocationSupport.png';

function SupportProducts() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h3" gutterBottom>
          Products User Guide
        </Typography>
        <Divider sx={{ mb: 2, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Choosing an Organization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Different Subscription tiers offer different bonuses
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2 } }}>
            <ListItem>
              <ListItemText primary="Go to the products page and check out the different tiers." />
            </ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={subscription} alt="subscription" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="Free is meant to try out the app without paying anything upfront." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Basic is meant for the smaller farms," />
            </ListItem>
            <ListItem>
              <ListItemText primary="Advanced is meant for the medium farms." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Premium is meant for the larger farms." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Enterprise is for those farms whose submissions or users wil exceed the ones listed." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Purchasing an Organization</Typography>
        </AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <iframe 
            width="560"
            height="315"
            src="https://www.youtube.com/embed/POsIWopoudA?si=lBUhbGQOtaLjuUU8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </Box>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Before clicking start plan you need to fill out some information.
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={subscriptionLocation} alt="Subscription Location" style={{ width: '100%', maxWidth: '50vw', height: 'auto' }} />
            </Box>
            <ListItem>
              <ListItemText primary="Here we can choose where we want our data to be stored, the closer you are to the location the faster the speeds (this cannot be changed)." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Type the name you want for your organization, all users in the organization can see this." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">What is a Submission</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            How to understand submissions
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary="In My Forms every time you click submit that is one submission." />
            </ListItem>
            <ListItem>
              <ListItemText primary="In My Plots if we are submitting data for only a pest OR disease it's one submissions per cell you selected." />
            </ListItem>
            <ListItem>
              <ListItemText primary="If we have a pest AND disease selected that is two submissions for each cell selected." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 4 }} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Requesting a Refund</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            If you are unhappy with the product
          </Typography>
          <List sx={{ listStyleType: 'square', '& .MuiListItem-root': { display: 'list-item', ml: 2  } }}>
            <ListItem>
              <ListItemText primary="Head over to Contact Us and send as an email letting us know you want a refund." />
            </ListItem>
            <ListItem>
              <ListItemText primary="We will connect with you to process that quickly." />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

    </Container>
  );
}

export default SupportProducts;
