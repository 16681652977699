import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Stack, Divider, useMediaQuery } from '@mui/material';
import pricesDataProd from '../local_data/price-data-prod.json';
import pricesDataTest from '../local_data/price-data-test.json';
import currencyData from '../local_data/currency-data.json';
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as Sentry from '@sentry/react';

function SubscriptionTiers({ buttonHandlers, currentPlan, countryCode, loggedIn }) {
    const tiers = [
        { priceId: import.meta.env.VITE_PADDLE_PRICE_ID_FREE, title: 'Free', desc: 'To test out the product', price: '0', features: ['25 Users per org', '100 Monthly submissions', '600 Total submissions', 'Email support'], borderColor: '#dfe0dc' },
        { priceId: import.meta.env.VITE_PADDLE_PRICE_ID_BASIC, title: 'Basic', desc: 'For small farms', price: '20', features: ['25 Users per org', '2,000 Monthly submissions', '60,000 Total submissions', 'Email support'], borderColor: '#dfe0dc' },
        { priceId: import.meta.env.VITE_PADDLE_PRICE_ID_ADVANCED, title: 'Advanced', desc: 'For medium farms', price: '40', features: ['25 Users per org', '5,000 Monthly submissions', '150,000 Total submissions', 'Email support', 'Plot Images Support'], borderColor: '#dfe0dc' },
        { priceId: import.meta.env.VITE_PADDLE_PRICE_ID_PREMIUM, title: 'Premium', desc: 'For large farms', price: '80', features: ['25 Users per org', '20,000 Monthly submissions', '600,000 Total submissions', 'Email support', 'Plot Images Support'], borderColor: '#dfe0dc' },
        { priceId: '', title: 'Enterprise', desc: 'Custom needs', price: 'Custom', features: ['Custom Users per org', 'Custom Monthly submissions', 'Custom Total submissions', 'Email support', 'Plot Images Support'], borderColor: '#4f504c' },
    ];

    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:820px)');
    const [prices, setPrices] = useState({});

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                let localizedPrices;
                if(import.meta.env.VITE_WEB_MODE === 'TEST'){
                    localizedPrices = pricesDataTest[countryCode];
                } else if(import.meta.env.VITE_WEB_MODE === 'PROD'){
                    localizedPrices = pricesDataProd[countryCode];
                }

                setPrices(localizedPrices);
            } catch (error) {
                Sentry.captureException(error);
            }
        };

        fetchPrices();
    }, [countryCode]);

    const getPriceDisplay = (tier) => {
        if (tier.title === 'Enterprise' || tier.title === 'Starter') {
            return (
                <>
                    <Typography variant="h4" component="span">{tier.title === 'Enterprise' ? 'Custom' : 'Free'}</Typography>
                </>
            );
        }

        try {
            const priceInfo = prices[tier.priceId];
            const currencyInfo = currencyData.data.find(currency => currency.code === priceInfo.currency);
            const decimals = currencyInfo ? parseInt(currencyInfo.decimals) : 0;

            const adjustedPrice = parseFloat(priceInfo.price) / Math.pow(10, decimals);
            const formattedPrice = adjustedPrice.toFixed(decimals);

            return (
                <>
                    <Typography variant="h4" component="div">{formattedPrice}</Typography>
                    <Typography variant="body2" component="div">{priceInfo.currency}/month</Typography>
                </>
            );
        } catch (error) {
            return (
                <>
                    <Typography variant="h4" component="div">{tier.price}</Typography>
                    <Typography variant="body2" component="div">/month</Typography>
                </>
            );
        }
    };

    const renderTier = (tier, index) => (
        <Grid item xs={12} sm={6} md={4} lg={2.4} key={tier.title} style={{ flex: '1 1 auto', maxWidth: isMobile ? '100%' : '20%' }}>
            <Card
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: `5px solid ${currentPlan === tier.title ? '#b0b6bc' : tier.borderColor}`,
                    borderRadius: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }}
            >
                <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" align="center">{tier.title}</Typography>
                    <Divider orientation="horizontal" flexItem />
                    <Typography variant="subtitle1" align="center">{tier.desc}</Typography>
                    <Divider orientation="horizontal" flexItem />
                    <div
                        style={{ 
                            height: '12vh', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            flexDirection: 'column' 
                        }}
                    >
                        {prices ? getPriceDisplay(tier) : (
                            <>
                                <Typography variant="h5" component="div">${tier.price}</Typography>
                                <Typography variant="body2" component="div">/month</Typography>
                            </>
                        )}
                    </div>
                    <Divider orientation="horizontal" flexItem sx={{mb:2}}/>
                    <Stack
                        spacing={2}
                        divider={<Divider orientation="horizontal" flexItem />}
                        style={{ flexGrow: 1 }}
                    >
                        {tier.features.map((feature) => (
                            <Typography key={feature} align="center">
                                {feature.includes('submissions') || feature.includes('Users per org') ? (
                                    <>
                                        <Typography variant="h6" component="span" display="block" align="center">{feature.split(' ')[0]}</Typography>
                                        <Typography variant="body2" component="span" display="block" align="center">{feature.split(' ').slice(1).join(' ')}</Typography>
                                    </>
                                ) : (
                                    feature
                                )}
                            </Typography>
                        ))}
                    </Stack>
                </CardContent>
                <CardContent>
                    {loggedIn !== '' && (
                        currentPlan !== tier.title ? (
                            tier.title === 'Enterprise' ? (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        backgroundColor: theme.palette.primary.contrastText,
                                        color: theme.palette.primary.dark
                                    }}
                                    href="/contact-us"
                                >
                                    Contact Sales
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => buttonHandlers[index] && buttonHandlers[index](tier.title)}
                                >
                                    {currentPlan && currentPlan !== 'None' ? (
                                        `Change Plan`
                                    ) : (
                                        `Start Plan`
                                    )}
                                </Button>
                            )
                        ) : (
                            <Button
                                variant="contained"
                                fullWidth
                                disabled
                                style={{ backgroundColor: '#f0f0f0', color: '#a0a0a0' }}
                            >
                                Current Plan
                            </Button>
                        )
                    )}
                </CardContent>
            </Card>
        </Grid>
    );

    return (
        <Container disableGutters={true} sx={{paddingTop:4}}>
            {isMobile ? (
                <Carousel showThumbs={false} showStatus={false}>
                    {tiers.map((tier, index) => (
                        <div key={tier.title}>
                            {renderTier(tier, index)}
                        </div>
                    ))}
                </Carousel>
            ) : (
                <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                    {tiers.map((tier, index) => renderTier(tier, index))}
                </Grid>
            )}
        </Container>
    );
}

export default SubscriptionTiers;
