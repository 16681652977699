import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Grid, Container, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Facebook, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import audonisLogo from '../images/audonisLogo.png';
import { useTranslation } from 'react-i18next';

function Footer({ fixed }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <Box
          component="footer"
          sx={{
            bgcolor: 'primary.main',
            py: 3,
            width: '100%',
            position: fixed ? 'fixed' : 'static',
            bottom: fixed ? 0 : 'auto',
          }}
      >
        <Container maxWidth={false}>
          <Grid
              container
              justifyContent={isMobile ? 'center' : 'space-between'}
              alignItems="center"
              direction={isMobile ? 'column' : 'row'}
              spacing={2}
          >
            <Grid item xs={12} md="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box component="img" src={audonisLogo} alt="Audonis Logo" sx={{ height: 40, mr: 2 }} />
              <Typography variant="body2" color="primary.contrastText">
                © {new Date().getFullYear()} AUDONIS
              </Typography>
            </Grid>
            <Grid item xs={12} md="auto" sx={{ display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', textAlign: 'center' }}>
              <Link to="/terms-and-conditions" style={{ color: theme.palette.primary.contrastText, marginBottom: isMobile ? '8px' : 0 }}>{t('General.TermsAndConditions')}</Link>
              {!isMobile && (
                  <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        height: '1em',
                        borderLeft: '1px solid',
                        borderColor: 'primary.contrastText',
                        mx: 1,
                      }}
                  />
              )}
              <Link to="/privacy-policy" style={{ color: theme.palette.primary.contrastText, marginBottom: isMobile ? '8px' : 0 }}>{t('General.PrivacyPolicy')}</Link>
              {!isMobile && (
                  <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        height: '1em',
                        borderLeft: '1px solid',
                        borderColor: 'primary.contrastText',
                        mx: 1,
                      }}
                  />
              )}
              <Link to="/accessibility-statement" style={{ color: theme.palette.primary.contrastText, marginBottom: isMobile ? '8px' : 0 }}>{t('General.AccessibilityStatement')}</Link>
            </Grid>
            <Grid item xs={12} md="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pr: isMobile ? 0 : 2 }}>
              <IconButton component="a" href="https://www.instagram.com/audonis_official/" target="_blank" rel="noopener noreferrer" color="secondary">
                <Instagram />
              </IconButton>
              <IconButton component="a" href="https://www.facebook.com/profile.php?id=61564383522265&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" color="secondary">
                <Facebook />
              </IconButton>
              <IconButton component="a" href="https://www.linkedin.com/company/audonis/" target="_blank" rel="noopener noreferrer" color="secondary">
                <LinkedIn />
              </IconButton>
              <IconButton component="a" href="https://www.youtube.com/channel/UCxBsEkrm68tDE5BBWkYxxjQ" target="_blank" rel="noopener noreferrer" color="secondary">
                <YouTube />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
}

export default Footer;
