import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, TextField, IconButton, Menu, MenuItem, Container, Divider
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  doc,
  getDoc,
  deleteDoc,
  collection, getDocs, where, query, updateDoc,
} from 'firebase/firestore';
import { auth, getFirestoreLocation } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import useOrgFirestore from '../hooks/useOrgFirestore';
import AlertDialog from "../components/ConfirmDialogBox";
import axios from "axios";
import { useSnackbar } from '../components/SnackbarContext';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react'

function MyOrganizations() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [organizations, setOrganizations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [dialogMode, setDialogMode] = useState('');
  const [dialog, setDialog] = useState('');
  const [title, setTitle] = useState('');
  const [tickLabel, setTickLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [dialogOrgId, setDialogOrgId] = useState(null);
  const { t } = useTranslation();
  const {firestore, storageBucket} = getFirestoreLocation('default');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, set Firestore location based on user's data or preferences
        // Uncomment if you need to set a user-specific Firestore location
        // const { userSpecificFirestore } = getFirestoreLocation('default'); 
        // setFirestore(userSpecificFirestore);
        setIsLoading(false);
        // Ensure that both firestore and the authenticated user are available before loading organizations
        if (firestore) {
          LoadOrganizations(user);
        }
      } else {
        // User is not signed in, redirect to login
        navigate('/login');
      }
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, [navigate, firestore]);
  
  const LoadOrganizations = async (user) => {
    if (!user) {
      navigate('/login');
      return;
    }

    const userUid = user.uid;
    const userOrganizationsRef = collection(firestore, `users/${userUid}/organizations`);
    const userOrganizationsSnapshot = await getDocs(userOrganizationsRef);
    const allOrganizationsRef = collection(firestore, `organizations`);
    const allOrganizationsSnapshot = await getDocs(allOrganizationsRef);

    if (!userOrganizationsSnapshot.empty) {
      const organizationsData = userOrganizationsSnapshot.docs.map((doc) => {
        const organizationDoc = allOrganizationsSnapshot.docs.find(doc1 => doc1.id === doc.id);
        const name = organizationDoc.data().name;
        return {
          id: doc.id,
          role: doc.data().role,
          transferRequest: doc.data().transferRequest,
          name
        };
      });
      setOrganizations(organizationsData);
    } else {
    }
  };


  const monthlyWrites = {
    "Free": 100,
    "Bronze": 200,
    "Silver": 500,
    "Gold": 1000,
    "Diamond": 2000
  }

  const maxData = {
    "Free": 100,
    "Bronze": 200,
    "Silver": 500,
    "Gold": 1000,
    "Diamond": 2000
  }

  const canUseCamera = {
    "Free": false,
    "Bronze": false,
    "Silver": true,
    "Gold": true,
    "Diamond": true
  }

  const LeaveOrganization = async (orgId) => {
    const userUid = auth.currentUser.uid;
    const orgDocRef = doc(firestore, `organizations/${orgId}`);

    try {
      const orgSnapshot = await getDoc(orgDocRef);
      if (orgSnapshot.exists) {
        const location = orgSnapshot.data().location; // Access the 'location' attribute

        const { firestore: locFirestore, storageBucket: b} = getFirestoreLocation(location);
        const orgUserDocRef = doc(locFirestore, `organizations/${orgId}/users/${userUid}`);
        const userOrgDocRef = doc(firestore, `users/${userUid}/organizations/${orgId}`);

        // Delete the document in the organization's specific Firestore instance
        await deleteDoc(orgUserDocRef);

        // Delete the document in the main Firestore instance
        await deleteDoc(userOrgDocRef);

      } else {
        return null;
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      return null;
    }
    await LoadOrganizations(auth.currentUser);
  };

  const OpenOrganization = (orgId) => {
    navigate(`/organization-members/${orgId}`);
  };

  const AcceptOrgInvite = async (orgId) => {
    const userUid = auth.currentUser.uid;
    const orgDocRef = doc(firestore, `organizations/${orgId}`);
    let location;
    try {
      const orgSnapshot = await getDoc(orgDocRef);
      if (orgSnapshot.exists) {
        location = orgSnapshot.data().location;
      } else {
        return null;
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      return null;
    }
    const { firestore: locFirestore, storageBucket: b2} = getFirestoreLocation(location);
    const userDocRef = doc(firestore, `users/${userUid}/organizations/${orgId}`)
    const orgUserDocRef = doc(locFirestore, `organizations/${orgId}/users/${userUid}`);

    try {
      const userSnapshot = await getDoc(userDocRef);
      if (userSnapshot.exists() && userSnapshot.data().role === 'Invited') {
        // Update role to 'Member' for the user document
        await updateDoc(userDocRef, { role: 'Contributor' });
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
      }

      const orgUserSnapshot = await getDoc(orgUserDocRef);
      if (orgUserSnapshot.exists() && orgUserSnapshot.data().role === 'Invited') {
        // Update role to 'Member' for the organization user document
        await updateDoc(orgUserDocRef, { role: 'Contributor' });
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        return;
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      return;
    }

    await LoadOrganizations(auth.currentUser);
  }


  const DeclineOrgInvite = async (orgId) => {
    const userUid = auth.currentUser.uid;
    const orgDocRef = doc(firestore, `organizations/${orgId}`);

    try {
      const orgSnapshot = await getDoc(orgDocRef);
      if (orgSnapshot.exists) {
        const location = orgSnapshot.data().location; // Access the 'location' attribute

        const { firestore: locFirestore, storageBucket: b} = getFirestoreLocation(location);
        const orgUserDocRef = doc(locFirestore, `organizations/${orgId}/users/${userUid}`);
        const userOrgDocRef = doc(firestore, `users/${userUid}/organizations/${orgId}`);

        // Delete the document in the organization's specific Firestore instance
        await deleteDoc(orgUserDocRef);

        // Delete the document in the main Firestore instance
        await deleteDoc(userOrgDocRef);

      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        return null;
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      return null;
    }
    await LoadOrganizations(auth.currentUser);
  }

  const PromptLeaveOrganization = (orgId) => {
    setDialogMode('leave');
    setTitle(`Leave Organization`);
    setDialog(`Are you sure you wish to leave organization ${orgId}?`);
    setTickLabel(`Yes, I am sure`);
    setDialogOrgId(orgId);
    setOpen(true);
  };


  const PromptDeclineOrganization = (orgId) => {
    setDialogMode('decline');
    setTitle(`Decline Organization`);
    setDialog(`Are you sure you wish to decline organization invite to ${orgId}?`);
    setTickLabel(`Yes, I am sure`);
    setDialogOrgId(orgId);
    setOpen(true);
  };


  const handleClickSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenChangeNameDialog = (orgId) => {
    setCurrentOrgId(orgId); // Set the current organization ID
    setChangeNameDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseChangeNameDialog = () => {
    setChangeNameDialogOpen(false);
    setValidationMessage('');
  };

  const handleChangeOrgName = (event) => {
    setNewOrgName(event.target.value);
  };


  const HandleTransferRequest = async (orgId) => {
    setDialogMode('transfer');
    setTitle(`Transfer Organization`);
    setDialog(`Are you sure you wish to gain ownership of the new organization?`);
    setTickLabel(`Yes, I am sure`);
    setDialogOrgId(orgId);
    setOpen(true);
  }

  const TransferOrganization = async (orgId) => {
    const userUid = auth.currentUser.uid;
    const orgDocRef = doc(firestore, `organizations/${orgId}`);
    try {
      const orgSnapshot = await getDoc(orgDocRef);
      if (orgSnapshot.exists) {
        const location = orgSnapshot.data().location; // Access the 'location' attribute

        const { firestore: locFirestore, storageBucket: b} = getFirestoreLocation(location);

        const orgUserDocRef = doc(locFirestore, `organizations/${orgId}/users/${userUid}`);
        const defOrgRef = doc(firestore, `organizations/${orgId}`);
        const userOrgDocRef = doc(firestore, `users/${userUid}/organizations/${orgId}`);
        const userRef = doc(firestore, `users/${userUid}`);
        const locOrgRef = doc(locFirestore, `organizations/${orgId}`);

        // Get transfer request
        const orgUserDocSnapshot = await getDoc(orgUserDocRef);
        if (orgUserDocSnapshot.exists) {
          const transferRequest = orgUserDocSnapshot.data().transferRequest;
          if(!transferRequest){
            return;
          }
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          return null;
        }


        // Check if user has a subscriptionId
        // Get subid and planid
        let subscriptionId;
        let planName;
        let ownedOrgId;
        const userRefSnapshot = await getDoc(userRef);
        if (userRefSnapshot.exists) {
          const data = userRefSnapshot.data();
          subscriptionId = data.subscriptionId;
          planName = data.currentPlan;
          ownedOrgId = data.ownedOrgId;
          if(!subscriptionId || !planName || !ownedOrgId){
            return;
          }
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          return null;
        }

        const defaultOrgRef = doc(firestore, `organizations/${ownedOrgId}`);

        const defaultOrgRefSnapshot = await getDoc(defaultOrgRef);
        let otherLocation;
        if (defaultOrgRefSnapshot.exists){
          otherLocation = defaultOrgRefSnapshot.data().location;
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          return null;
        }

        const { firestore: otherLocFirestore, storageBucket: b3} = getFirestoreLocation(otherLocation);


        // Check if users org is empty
        let formsCount;
        const formsCollectionRef = collection(otherLocFirestore, `organizations/${ownedOrgId}/forms`);
        try {
          const formsSnapshot = await getDocs(formsCollectionRef);
          formsCount = formsSnapshot.size; // Get the number of documents in the collection
        } catch (error) {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
          return null;
        }

        if (formsCount > 0){
          return;
        }

        const plotsCollectionRef = collection(otherLocFirestore, `organizations/${ownedOrgId}/plots`);
        let plotCount;
        try {
          const plotsSnapshot = await getDocs(plotsCollectionRef);
          plotCount = plotsSnapshot.size; // Get the number of documents in the collection
        } catch (error) {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
          return null;
        }

        if(plotCount > 0){
          return;
        }

        const usersCollectionRef = collection(otherLocFirestore, `organizations/${ownedOrgId}/users`);
        let usersCount;
        try {
          const usersSnapshot = await getDocs(usersCollectionRef);
          usersCount = usersSnapshot.size; // Get the number of documents in the collection
        } catch (error) {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
          return null;
        }

        if(usersCount > 1){
          return;
        }

        const ownedOrgRef = doc(otherLocFirestore, `organizations/${ownedOrgId}`);
        const ownedOrgRefSnapshot = await getDoc(ownedOrgRef);
        let currentWrites;
        if (ownedOrgRefSnapshot.exists) {
          const data = ownedOrgRefSnapshot.data();
          currentWrites = data.currentWrites;
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          return null;
        }
        let currentOrgOwner;
        const locOrgRefSnapshot = await getDoc(locOrgRef);
        if (locOrgRefSnapshot.exists){
          currentOrgOwner = locOrgRefSnapshot.data().ownerId;
        } else {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          return null;
        }

        // Swap owner roles
        await updateDoc(orgUserDocRef, { role: 'Owner', transferRequest: null });
        await updateDoc(userOrgDocRef, { role: 'Owner', transferRequest: null });
        const ownerOrgUserDocRef = doc(locFirestore, `organizations/${orgId}/users/${currentOrgOwner}`);
        const ownerUserOrgDocRef = doc(firestore, `users/${currentOrgOwner}/organizations/${orgId}`);
        await updateDoc(ownerOrgUserDocRef, { role: 'Admin' });
        await updateDoc(ownerUserOrgDocRef, { role: 'Admin' });
        const curUserDocRef = doc(firestore, `users/${currentOrgOwner}`);
        await updateDoc(curUserDocRef, {ownedOrgId: '', currentPlan: 'None', ownsOrganization: false, subscriptionId: ''})

        // Change subscription id, plan id, and read/write limits

        const orgData = {
          ownerId: userUid,
          maximumTotalData: maxData[planName],
          maximumWrites: monthlyWrites[planName],
          orgType: planName,
          canUseCamera: canUseCamera[planName],
          currentWrites: currentWrites,
          subscriptionID: subscriptionId
        };
        await updateDoc(locOrgRef, orgData)
        await updateDoc(orgDocRef, {subscriptionID: subscriptionId})
        // Delete the old org
        const oldOrgOwnerDocRef = doc(otherLocFirestore, `organizations/${ownedOrgId}/users/${userUid}`);
        const oldUserOrgRef = doc(firestore, `users/${userUid}/organizations/${ownedOrgId}`)
        await deleteDoc(defaultOrgRef);
        await deleteDoc(ownedOrgRef);
        await deleteDoc(oldOrgOwnerDocRef);
        await deleteDoc(oldUserOrgRef);

        // Cancel the old owners payment plan
        const oldSubscriptionId = orgSnapshot.data().subscriptionID;
        const response = await axios.post(import.meta.env.VITE_FUNCTION_LINK_CANCEL_SUBSCRIPTION, {
          subscriptionId: oldSubscriptionId,
          effectiveFrom: 'immediately',
          mode: import.meta.env.VITE_WEB_MODE
        });
        return null;
      } else {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        return null;
      }
    } catch (error) {
      showSnackbar(t('Snackbar.TryAgain'), 'error');
      Sentry.captureException(error);
      return null;
    }
    await LoadOrganizations(auth.currentUser);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = async () => {
    setIsLoading(true);
    if(dialogMode === 'transfer'){
      await TransferOrganization(dialogOrgId);
    }
    else if (dialogMode === 'leave'){
      await LeaveOrganization(dialogOrgId);
    }
    else if (dialogMode === 'decline'){
      await DeclineOrgInvite(dialogOrgId);
    }

    setTimeout(() => {
      setOpen(false);
      setDialogMode('');
      setIsLoading(false);
      setDialogOrgId(null);
    }, 5000);

  };

  const handleSubmitChangeName = async () => {
    if (newOrgName.length < 1) {
      setValidationMessage('Please enter an organization name');
    } else if (newOrgName.length > 63) {
      setValidationMessage('Organization name must be less than 64 characters');
    } else {
      var location;
      if (currentOrgId) {
        try {
          const orgRef = doc(firestore, "organizations", currentOrgId);
          const orgDoc = await getDoc(orgRef);

          location = orgDoc.data().location; // Access the 'location' attribute
        } catch (error) {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
        }
      }
      const { firestore: locationFirestore, storageBucket: b3 } = getFirestoreLocation(location);
      var lastNameChangeValue = "a";
      try {
        const orgRef = doc(locationFirestore, "organizations", currentOrgId);
        const orgDoc = await getDoc(orgRef);
        lastNameChangeValue = orgDoc.data().lastNameChange; // Access the 'lastNameChange' attribute
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        Sentry.captureException(error);
      }
      var changeTooSoon = false;

      if (lastNameChangeValue === "Null") {
        changeTooSoon = false;
      } else {
        const currentDate = new Date();
        const pastDate = new Date(lastNameChangeValue);

        const timeDiff = currentDate - pastDate;

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (days < 30) {
          changeTooSoon = true;
        }
      }

      if (changeTooSoon) {
        // Assuming lastNameChangeValue is already defined and is a valid date string or Date object
        const lastNameChangeDate = new Date(lastNameChangeValue);

        // Add 30 days to the date
        lastNameChangeDate.setDate(lastNameChangeDate.getDate() + 30);

        // Format the date as a readable string (e.g., "March 15, 2024")
        // You might need to adjust the format depending on your locale and preference
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = lastNameChangeDate.toLocaleDateString('en-US', options);
        setValidationMessage(`${t('Snackbar.TryAgain')} ${formattedDate}`);
        return;
      }
      // Check for duplicate organization name in Firestore
      try {
        const orgDocRef = doc(firestore, 'organizations', currentOrgId);
        const locOrgDocRef = doc(locationFirestore, 'organizations', currentOrgId);

        updateDoc(orgDocRef, {
          name: newOrgName,
        }).then(() => {
          console.log('Document successfully updated!');
        }).catch((error) => {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
        });

        updateDoc(locOrgDocRef, {
          name: newOrgName,
          lastNameChange: new Date().toISOString()
        }).then(() => {
          console.log('Document successfully updated!');
        }).catch((error) => {
          showSnackbar(t('Snackbar.TryAgain'), 'error');
          Sentry.captureException(error);
        });

        setValidationMessage(t('MyOrganisations.OrgNameChangeSuccess'));
      } catch (error) {
        showSnackbar(t('Snackbar.TryAgain'), 'error');
        setValidationMessage(t('MyOrganisations.OrgNameChangeError'));
        Sentry.captureException(error);
      }


    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Typography variant="h3" gutterBottom>{t('MyOrganisations.Title')}</Typography>
      <Divider sx={{ mb: 3, borderWidth: 5, borderColor: 'secondary.main', borderRadius: 3 }} />
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="org-list">
          {organizations.length === 0 ? (
            <Typography variant="h6" color="textSecondary">
              {t('MyOrganisations.EmptyMessage')}
            </Typography>
          ) : (
            organizations.map((org) => (
              <Card key={org.id} variant="outlined">
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {org.name}
                  </Typography>
                  <Typography color="textSecondary">
                    {t('MyOrganisations.MyRole')} {org.role}
                  </Typography>
                </CardContent>
                <CardActions>
                  {(org.role !== 'Invited') && (
                    <Button onClick={() => OpenOrganization(org.id)} variant="outlined">{t('MyOrganisations.Open')}</Button>
                  )}

                  {(org.role !== 'Owner' && org.role !== 'Invited') && (
                    <Button onClick={() => PromptLeaveOrganization(org.id)} variant="outlined">{t('MyOrganisations.Leave')}</Button>
                  )}

                  {(org.role === 'Invited') && (
                    <Button onClick={() => AcceptOrgInvite(org.id)} variant="outlined">{t('MyOrganisations.Accept')}</Button>
                  )}
                  {(org.role === 'Invited') && (
                    <Button onClick={() => PromptDeclineOrganization(org.id)} variant="outlined">{t('MyOrganisations.Decline')}</Button>
                  )}
                  {(org.transferRequest) && (
                    <Button onClick={() => HandleTransferRequest(org.id)} variant="outlined">{t('MyOrganisations.HandleTransfer')}</Button>
                  )}
                  {(org.role === 'Owner' || org.role === 'Admin') && (
                    <>
                      <IconButton onClick={handleClickSettings}>
                        <SettingsIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        {(org.role === 'Owner' || org.role === 'Admin') && (
                          <MenuItem onClick={() => handleOpenChangeNameDialog(org.id)}>{t('MyOrganisations.ChangeOrgName')}</MenuItem>
                        )}
                      </Menu>
                    </>
                  )}
                </CardActions>
              </Card>
            ))
          )}
        </div>
      </Grid>
      </Grid>
      <Dialog open={changeNameDialogOpen} onClose={handleCloseChangeNameDialog}>
        <DialogTitle>{t('MyOrganisations.ChangeOrgName')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Organization Name"
            type="text"
            fullWidth
            variant="standard"
            value={newOrgName}
            onChange={handleChangeOrgName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitChangeName}>{t('General.Submit')}</Button>
          <Button onClick={handleCloseChangeNameDialog}>{t('General.Cancel')}</Button>
        </DialogActions>
        {validationMessage && (
        <DialogContentText style={{ padding: '0 24px', color: 'red' }}>
          {validationMessage}
        </DialogContentText>
        )}
      </Dialog>


      <AlertDialog open={open} onClose={handleClose} onAgree={handleAgree} dialogue={dialog} title={title} tickBoxContent={tickLabel} isLoading={isLoading}/>

    </Container>
  );
}

export default MyOrganizations;
