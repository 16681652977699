import React from 'react';
import { Grid, Card, CardContent, Box, Typography, Divider, Container } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';

const HomeHighlight = ({ builtForFarmers, beEffortless, pricedAccessible }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:899px)');
  const iconSpring = useSpring({ opacity: 1, transform: 'scale(1)', from: { opacity: 0, transform: 'scale(0.8)' }, delay: 1000, config: { duration: 1000 } });

  return (
    <Container maxWidth={false} disableGutters={true} sx={{ paddingTop: 0, margin: 0, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <animated.div style={iconSpring}>
        <Card elevation={0} sx={{ backgroundColor: '#F3F3F3', height: isMobile ? 'auto' : '80vh' }}>
          <CardContent sx={{ height: '100%' }}>
            <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-evenly" alignItems="center" sx={{ height: '100%' }}>
              <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', marginBottom: isMobile ? 3 : 0 }}>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={builtForFarmers} alt="Built for Farmers" style={{ maxWidth: '100%', height: 'auto', borderRadius: 10 }} />
                  <Box mt={2} sx={{ width: '100%', textAlign: 'left' }}>
                    <Typography variant="h5">{t('Home.Highlight.LeftTitle')}</Typography>
                  </Box>
                  <Box mt={1} sx={{ width: '100%', textAlign: 'left' }}>
                    <Typography variant="body1" color="textSecondary" paragraph>
                      {t('Home.Highlight.LeftDesc')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {!isMobile && <Divider orientation="vertical" flexItem />}
              <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', marginBottom: isMobile ? 3 : 0 }}>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={beEffortless} alt="Be Effortless" style={{ maxWidth: '100%', height: 'auto', borderRadius: 10 }} />
                  <Box mt={2} sx={{ width: '100%', textAlign: 'left' }}>
                    <Typography variant="h5">{t('Home.Highlight.MiddleTitle')}</Typography>
                  </Box>
                  <Box mt={1} sx={{ width: '100%', textAlign: 'left' }}>
                    <Typography variant="body1" color="textSecondary" paragraph>
                      {t('Home.Highlight.MiddleDesc')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {!isMobile && <Divider orientation="vertical" flexItem />}
              <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={pricedAccessible} alt="Priced Accessible" style={{ maxWidth: '100%', height: 'auto', borderRadius: 10 }} />
                  <Box mt={2} sx={{ width: '100%', textAlign: 'left' }}>
                    <Typography variant="h5">{t('Home.Highlight.RightTitle')}</Typography>
                  </Box>
                  <Box mt={1} sx={{ width: '100%', textAlign: 'left' }}>
                    <Typography variant="body1" color="textSecondary" paragraph>
                      {t('Home.Highlight.RightDesc')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </animated.div>
    </Container>
  );
};

export default HomeHighlight;
