import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

// Mapping Firestore locations to storage bucket URLs
const storageBucketMapTest = {
  default: 'gs://audonis-test.appspot.com',
  africa_south_johannesburg: 'gs://asia-audonis-test',
  asia_east_taiwan: 'gs://asia-audonis-test',
  asia_south_delhi_india: 'gs://asia-audonis-test',
  australia_sydney: 'gs://asia-audonis-test',
  europe: 'gs://europe-audonis-test',
  middle_east_israel_tel_aviv: 'gs://asia-audonis-test',
  northamerica: 'gs://northamerica-audonis-test',
  south_america_east_brazil_sao_paulo: 'gs://southamerica-east-audonis-test',
};

const storageBucketMapProd = {
  default: 'gs://audonis-81f23.appspot.com',
  africa_south_johannesburg: 'gs://asia-audonis-prod',
  asia_east_taiwan: 'gs://asia-audonis-prod',
  asia_south_delhi_india: 'gs://asia-audonis-prod',
  australia_sydney: 'gs://asia-audonis-prod',
  europe: 'gs://europe-audonis-prod',
  middle_east_israel_tel_aviv: 'gs://asia-audonis-prod',
  northamerica: 'gs://northamerica-audonis-prod',
  south_america_east_brazil_sao_paulo: 'gs://southamerica-east-audonis-prod',
}

function getFirestoreLocation(location) {
  const locationMap = {
    default: '(default)',
    africa_south_johannesburg: 'africa-south-johannesburg',
    asia_east_taiwan: 'asia-east-taiwan',
    asia_south_delhi_india: 'asia-south-india-delhi',
    australia_sydney: 'australia-sydney',
    europe: 'europe',
    middle_east_israel_tel_aviv: 'middle-east-israel-tel-aviv',
    northamerica: 'northamerica',
    south_america_east_brazil_sao_paulo: 'south-america-east-brazil-sao-paulo'
  };
  const firestoreLocation = locationMap[location];
  var storageBucketMap;
  if (import.meta.env.VITE_WEB_MODE === 'PROD'){
    storageBucketMap = storageBucketMapProd;
  }
  else if (import.meta.env.VITE_WEB_MODE === 'TEST'){
    storageBucketMap = storageBucketMapTest;
  }
  const storageBucket = storageBucketMap[location] || storageBucketMap.default;
  const fs = getFirestore(app, firestoreLocation);

  return { firestore: fs, storageBucket: storageBucket };
}


export { auth, app, getFirestoreLocation };
