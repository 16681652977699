import { useState, useEffect } from 'react';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import { getFirestoreLocation, app } from '../firebaseConfig';

function useOrgFirestore(orgId) {
  const [firestore, setFirestore] = useState(null);
  const [location, setLocation] = useState('');
  const [storageBucket, setStorageBucket] = useState('');

  useEffect(() => {
    const fetchLocationAndSetFirestore = async () => {
      const orgRef = doc(getFirestore(app), 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);

      if (orgDoc.exists()) {
        const orgData = orgDoc.data();
        if (orgData.location) {
          setLocation(orgData.location);
          const { firestore, storageBucket } = getFirestoreLocation(orgData.location);
          setFirestore(firestore);
          setStorageBucket(storageBucket);
        } else {
          alert('Location not found for the selected organization. Please ensure the organization has a location set.');
        }
      } else {
        alert('Organization document not found. Please select a valid organization.');
      }
    };

    if (orgId) {
      fetchLocationAndSetFirestore();
    }
  }, [orgId]);

  return { firestore, location };
}

export default useOrgFirestore;
